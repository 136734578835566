import { WorldLayer } from "../world_layer"

export class MeasureTool extends WorldLayer {
  init() {
    // this.enableInitially = true
    this.mayRenderAsync = true

    this.dom_p1x = $(`[data-hudval="mp1x"]`)
    this.dom_p1y = $(`[data-hudval="mp1y"]`)
    this.dom_p2x = $(`[data-hudval="mp2x"]`)
    this.dom_p2y = $(`[data-hudval="mp2y"]`)
    this.dom_distance_u = $(`[data-hudval="mdu"]`)
    this.dom_distance_m = $(`[data-hudval="mdm"]`)
    this.dom_distance_km = $(`[data-hudval="mdkm"]`)
    this.dom_distance_nm = $(`[data-hudval="mdnm"]`)

    const hparams = this.world.url.getHashParams()
    if(hparams.measure) {
      const coords = hparams.measure.split(",").map(s => parseFloat(s))
      this.setP(1, ["game", coords[0], coords[1]], false, false)
      this.setP(2, ["game", coords[2], coords[3]], true, true, false)
      this.enableInitially = true
    }
  }

  onEnable() {
    this.world.cursor.force(this, "crosshair", 200)
  }

  onDisable() {
    this.world.cursor.force(this, null)
    this.setP(1, null, false, false)
    this.setP(2, null)
    delete this.pointerPosition
  }

  handleParentMouseMove(ev) {
    if(!this.enabled) return true
    if(!this.p1) return true
    if(!this.world.wheelJustOccurred) this.pointerPosition = this.translateClient([ev.clientX, ev.clientY])
    if(!this.p2) this.render()
  }

  handleParentClick(ev) {
    if(!this.enabled) return true
    const t = $(ev.target)
    if(!(t.is(this.world.world) || t.parents().is(this.world.world))) return true
    this.handleClick(ev)
  }

  handleClick(ev) {
    if(!this.p1) {
      this.setP(1, [ev.clientX, ev.clientY])
    } else {
      this.setP(2, [ev.clientX, ev.clientY])
    }
  }

  handleParentKeydown(ev) {
    if(!this.enabled) return true
    if(ev.key == "Escape") {
      ev.preventDefault()
      this.disable()
      return false
    } else if(ev.key == "Backspace" || ev.key == "Delete") {
      ev.preventDefault()
      this.handleBackspace()
      return false
    }
  }

  handleBackspace() {
    if(this.p2) {
      this.setP(2, null)
    } else if (this.p1) {
      this.setP(1, null)
    } else {
      this.disable()
    }
  }

  setP(p, v, updateRender = true, updateHud = true, updateUrl = true) {
    if(typeof p == "number") p = `p${p}`

    if (v === null) {
      delete this[p]
      if(p == "p1") delete this.pointerPosition
      if(p == "p2" && updateUrl) this.world.url.updateNow("measure", undefined)
    } else {
      let unit = "client"
      if (v.length > 2) {
        const dup = [...v]
        unit = dup.shift()
        v = dup
      }

      let pv = v
      if(unit == "client") {
        pv = this.translateClient(v)
      }
      this[p] = [pv[0], pv[1]]
      if(p == "p2" && updateUrl) this.world.url.updateNow("measure", [
        this.p1[0].toFixed(3),
        this.p1[1].toFixed(3),
        this.p2[0].toFixed(3),
        this.p2[1].toFixed(3)
      ].join(","))
    }

    if(updateHud) {
      if(this.p1) {
        this.dom_p1x.text(this.formatNumber(this.p1[0]))
        this.dom_p1y.text(this.formatNumber(this.p1[1]))
      } else {
        this.dom_p1x.text("?")
        this.dom_p1y.text("?")
      }

      if(this.p2) {
        this.dom_p2x.text(this.formatNumber(this.p2[0]))
        this.dom_p2y.text(this.formatNumber(this.p2[1]))
      } else {
        this.dom_p2x.text("?")
        this.dom_p2y.text("?")
      }

      if(this.p1 && this.p2) {
        const dist = this.distance2d(this.p1, this.p2)
        this.dom_distance_u.text(this.formatNumber(dist))
        this.dom_distance_m.text(this.formatNumber(dist / 100, 2))
        this.dom_distance_km.text(this.formatNumber(dist / 100000, 2))
        this.dom_distance_nm.text(this.formatNumber((dist / 100) * (1/1852), 2))
      } else {
        this.dom_distance_u.text("?")
        this.dom_distance_m.text("?")
        this.dom_distance_km.text("?")
        this.dom_distance_nm.text("?")
      }
    }

    if(updateRender) this.update()
  }

  render() {
    if(!this.enabled) return
    this.clear()
    const p1 = this.p1
    const p2 = this.p2 || this.pointerPosition
    let rotate = 0

    if(p1 && p2) {
      const angleBetween = this.getAngleBetweenPoints(p1, p2) % 90
      if(angleBetween < 22.5 || 90 - angleBetween < 22.5) {
        rotate = 45
      }
    }

    if(p1) { this.drawX(p1, null, true, { rotate }) }
    if(p1 && p2) {
      this.drawX(p2, null, true, { rotate })
      this.drawLine(p1, p2, { color: "#333" })
    }
  }
}
