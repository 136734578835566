import * as AppOS from "../appos"
import {SpotlightImage} from "./spotlight/image"

const Component = class extends AppOS.Component {
  static name = "Spotlight"

  init() {
    //console.log(eval("this"), this)
    this.opts.captureVoidSpace ??= true
    this.opts.arrowUpDownStep ??= 2
  }

  documentLoad() {
    // hide active on outside-click
    $(document).click(ev => {
      if($(ev.target).closest(".ao-fake, [data-ao-spotlight]").length == 0) {
        this.closeAllSpotlights()
      }
    })

    // autoclose spotlight if we scroll too far
    $(document).scroll(ev => {
      $(".ao-fake").each((i, el) => {
        const bounds = el.getBoundingClientRect()
        const outTop = bounds.top * -1
        const outBottom = (window.innerHeight - bounds.bottom) * -1
        const outLimit = bounds.height / 2
        if (outTop > outLimit || outBottom > outLimit) {
          $(el).data("aoOrigin")?.toggleZoom(false)
        }
      })
    })

    // track mouseover
    $(document).on("mouseenter", "[data-ao-spotlight]", ev => {
      $(ev.currentTarget).data("aoSpotlight")?.ctn?.addClass("ao-kb ao-hover")
    })
    $(document).on("mouseleave", "[data-ao-spotlight]", ev => {
      $(ev.currentTarget).data("aoSpotlight")?.ctn?.removeClass("ao-kb ao-hover")
    })
    $(document).on("mouseenter", ".ao-fake", ev => {
      $(ev.currentTarget).data("aoOrigin")?.ctn?.addClass("ao-kb ao-hover")
    })
    $(document).on("mouseleave", ".ao-fake", ev => {
      $(ev.currentTarget).data("aoOrigin")?.ctn?.removeClass("ao-kb ao-hover")
    })

    // track click
    $(document).on("click", "[data-ao-spotlight]", ev => {
      const spotl = $(ev.currentTarget).data("aoSpotlight")
      if(!ev.shiftKey && !ev.metaKey && !ev.ctrlKey && !ev.altKey && spotl) {
        spotl.toggleZoom()
        return false
      }
    })

    // keydown handler
    const debouncedArrowKeyHandler = AppOS.Util.throttle_leading(ev => this.handleArrowKeys(ev), 100)
    $(document).keydown(ev => {
      // console.log(ev.keyCode, ev.key)

      if (ev.target.tagName == "INPUT") return true
      if (ev.target.tagName == "TEXTAREA") return true

      if(ev.keyCode == 27) { // escape = close all
        this.closeAllSpotlights()
        return false
      }

      if(ev.keyCode == 32) { // space = toggle hover (ctn or fake)
        let spotl = $(".ao-hover[data-ao-spotlight]").last().data("aoSpotlight")
        if(spotl) {
          if(!spotl.zoomed && !this.opts.allowMulti) {
            this.closeAllSpotlights()
          }
          spotl.toggleZoom()
        }

        // capture event
        if(spotl || this.opts.captureVoidSpace) {
          ev.preventDefault()
          return false
        }
        return true
      }

      //arrow key events
      if (ev.keyCode >= 37 && ev.keyCode <= 40) {
        debouncedArrowKeyHandler(ev)
        if ($(".active[data-ao-spotlight]").length > 0) {
          ev.preventDefault()
          return false
        } else {
          return true
        }
      }
    })
  }

  pageLoad() {
    document.querySelectorAll(`[data-ao-spotlight]`).forEach(ctn => {
      const type = ctn.dataset.aoSpotlight
      switch (type) {
        case "image":
          new SpotlightImage(ctn, this)
          break;
        default:
          throw `unknown spotlight type ${type}`
          break;
      }
    })
  }

  handleArrowKeys(ev) {
    const activeAo = $(".lastActive[data-ao-spotlight]").first() // should be one anyway
    const activeIndex = $("[data-ao-spotlight]").index(activeAo)
    if(!activeAo.length) return

    if(ev.keyCode == 37) { // arrow left = one left
      this.openRelative(activeIndex, -1)
    }

    if(ev.keyCode == 39) { // arrow right = one right
      this.openRelative(activeIndex, 1)
    }

    if(ev.keyCode == 38) { // arrow up = upDownStep left
      this.openRelative(activeIndex, -1, this.opts.arrowUpDownStep)
    }

    if(ev.keyCode == 40) { // arrow down = upDownStep right
      this.openRelative(activeIndex, 1, this.opts.arrowUpDownStep)
    }
  }

  openRelative(index, direction = 1, step = 1) {
    let target
    if (direction > 0) {
      target = $("[data-ao-spotlight]").eq(index + step).data("aoSpotlight")
    } else {
      target = index < step ? false : $("[data-ao-spotlight]").eq(index - step).data("aoSpotlight")
    }

    if(target) {
      this.closeAllSpotlights()
      this.scrollIntoViewIfNeeded(target.ctn)
      target.toggleZoom(true)
      return true
    }
    return false
  }

  closeAllSpotlights(col) {
    col ??= $(".active[data-ao-spotlight]")
    col.each((i, el) => {
      $(el).data("aoSpotlight")?.toggleZoom(false)
    })
  }

  scrollIntoViewIfNeeded(target) {
    target = target.get(0)
    // down
    if (target.getBoundingClientRect().bottom > window.innerHeight) {
      // target.scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" })
      const t = $(target)
      window.x = t
      let r = t.offset().top - window.innerHeight // relative top
      r += t.outerHeight() // entry height
      // r += $("footer.page-footer").height() // footer margin
      window.scrollTo({ top: r, behavior: "instant" })
    }

    // up
    if (target.getBoundingClientRect().top < 0) {
      // target.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" })
      window.scrollTo({ top: $(target).offset().top, behavior: "instant" })
    }
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }



// Image
//   * on link (preview = data-ao-preview || > img[data-ao-preview])
