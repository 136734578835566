class NamespacedConsole {
  static applyOn(target, opts = {}) {
    const bconfig = opts.base?.__namespaced_console_opts
    if (bconfig) {
      opts.namespaces = bconfig.namespaces.slice(0)
      opts.nsFallback = bconfig.nsFallback
      opts.debug = bconfig.debug
      opts.debugConfig = bconfig.debugConfig
      opts.debugConfigKey = bconfig.debugConfigKey
    }

    opts.debug ??= false
    opts.debugConfigKey ??= "debug"
    opts.nsFallback ??= "?"
    opts.namespaces ??= []
    if (opts.namespace) opts.namespaces.push(opts.namespace)

    opts.doDebugProc = _ => {
      return opts.debugConfig ? opts.debugConfig[opts.debugConfigKey] : opts.debug
    }

    const nsStrProc = _ => {
      if(!opts.namespaces) return false
      let nsStr = ""
      opts.namespaces.forEach(el => {
        nsStr += `[${(Array.isArray(el) ? el[0][el[1]] : (typeof el == "function" ? el() : el)) ?? opts.nsFallback}]`
      })
      return nsStr
    }

    target.__namespaced_console_opts = opts

    target.debug = (function (...msg) {
      if(!opts.doDebugProc()) return
      msg.unshift(nsStrProc())
      if(!msg[0]) msg.shift()
      console.debug.apply(console, msg)
    }).bind(target)

    target.info = (function (...msg) {
      msg.unshift(nsStrProc())
      if(!msg[0]) msg.shift()
      console.info.apply(console, msg)
    }).bind(target)

    target.warning = (function (...msg) {
      msg.unshift(nsStrProc())
      if(!msg[0]) msg.shift()
      console.warn.apply(console, msg)
    }).bind(target)

    target.error = (function (...msg) {
      msg.unshift(nsStrProc())
      if(!msg[0]) msg.shift()
      console.error.apply(console, msg)
    }).bind(target)
  }
}

export { NamespacedConsole }
