import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "TurboBridge"

  init() {
    this.active = false
    this.detached = []
  }

  documentLoad() {
    if (window.Turbo) {
      this.active = true
      document.addEventListener('turbo:before-render', this.turboBeforeRender)
    } else if (window.Turbolinks) {
      this.active = true

    }
  }

  pageLoad() {
  }

  turboBeforeRender(ev) {
    ev.target.querySelectorAll("[data-ao-permanent]").forEach(el => {
      const pe = el.parentElement
      const nb = ev.detail.newBody
      const node = el.parentElement.removeChild(el)
      if (pe.tagName == "BODY") {
        nb.appendChild(node)
      } else if (pe.id) {
        nb.querySelector(`#${pe.id}`).appendChild(node)
      } else {
        nb.appendChild(node)
        this.warn("cannot determine parent, will reinsert to body", el)
      }
    })
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
