import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Bootstrap.Debug"

  init() {
    this.app.dev?._register("bs", "grid", Component.GridDebugger)
  }

  pageLoad() {
    this.app.dev?.bs.grid.set(this.app.opts.debug)
  }
}

Component.GridDebugger = class extends AppOS.Debugger.Value {
  init() {
    this.enabled = false
    this.opts = {
      showActive: false,
      showInactive: false,
      injectTo: "body",
      position: "bottom-right",
      hideTimeout: 5000,
    }
  }

  change(newV, oldV) {
    if (newV === true) newV = "all"
    this.debug("valueChange", oldV, "=>", newV)
    if(!newV && this.enabled) return this.disable()

    switch (newV) {
      case "active":
        this.opts.showActive = true
        this.opts.showInactive = false
        break
      case "all":
        this.opts.showActive = true
        this.opts.showInactive = true
        break
      default:
        this.error("unkown value", newV)
        break
    }

    this.enable()
  }

  enable() {
    this.enabled = true
    const sa = this.opts.showActive
    const si = this.opts.showInactive

    this.ctn = document.createElement('div')
    this.ctn.setAttribute("id", "appos_bs_debug_grid")

    if(this.app.components.TurboBridge?.active) {
      this.ctn.setAttribute("data-ao-permanent", "")
    }

    this.styleContainer(this.ctn)
    this.hookContainer(this.ctn)

    this.createSpan(sa,        { textContent: "xs",  classList: "bg-info           d-inline d-sm-none                          "})
    this.createSpan(si,        { textContent: "xs",  classList: "bg-secondary      d-none   d-sm-inline                        "})

    this.createSpan(sa && !si, { textContent: "sm",  classList: "bg-info           d-none   d-sm-inline d-md-none              "})
    this.createSpan(sa && si,  { textContent: "sm",  classList: "bg-info      ms-1 d-none   d-sm-inline d-md-none              "})
    this.createSpan(si,        { textContent: "sm",  classList: "bg-secondary ms-1          d-inline    d-sm-none  d-md-inline "})

    this.createSpan(sa && !si, { textContent: "md",  classList: "bg-info           d-none   d-md-inline d-lg-none              "})
    this.createSpan(sa && si,  { textContent: "md",  classList: "bg-info      ms-1 d-none   d-md-inline d-lg-none              "})
    this.createSpan(si,        { textContent: "md",  classList: "bg-secondary ms-1          d-inline    d-md-none  d-lg-inline "})

    this.createSpan(sa && !si, { textContent: "lg",  classList: "bg-info           d-none   d-lg-inline d-xl-none              "})
    this.createSpan(sa && si,  { textContent: "lg",  classList: "bg-info      ms-1 d-none   d-lg-inline d-xl-none              "})
    this.createSpan(si,        { textContent: "lg",  classList: "bg-secondary ms-1          d-inline    d-lg-none  d-xl-inline "})

    this.createSpan(sa && !si, { textContent: "xl",  classList: "bg-info           d-none   d-xl-inline d-xxl-none             "})
    this.createSpan(sa && si,  { textContent: "xl",  classList: "bg-info      ms-1 d-none   d-xl-inline d-xxl-none             "})
    this.createSpan(si,        { textContent: "xl",  classList: "bg-secondary ms-1          d-inline    d-xl-none  d-xxl-inline"})

    this.createSpan(sa && !si, { textContent: "xxl", classList: "bg-info           d-none   d-xxl-inline                       "})
    this.createSpan(sa && si,  { textContent: "xxl", classList: "bg-info      ms-1 d-none   d-xxl-inline                       "})
    this.createSpan(si,        { textContent: "xxl", classList: "bg-secondary ms-1          d-inline    d-xxl-none             "})

    document.querySelector(this.opts.injectTo).append(this.ctn)

    return this
  }

  disable() {
    this.enabled = false
    this.ctn.remove()
    return this
  }

  createSpan(append = true, attrs = {}) {
    const span = document.createElement('span')

    Object.entries(attrs).forEach(([k, v]) => span[k] = v)

    this.styleSpan(span)
    if(append) this.ctn?.append(span)

    return span
  }

  styleSpan(span) {
    span.classList.add("badge")
  }

  styleContainer(ctn) {
    ctn.classList.add("px-2", "py-2", "user-select-none")
    ctn.style.opacity = 0.8
    ctn.style.transition = "opacity 300ms ease-in-out"
    ctn.style.position = "fixed"
    this.opts.position.toString().split("-").forEach(pos => ctn.style[pos] = "0px")
  }

  hookContainer(ctn) {
    ctn.addEventListener('mouseenter', ev => ctn.style.opacity = 0.1)
    ctn.addEventListener('mouseleave', ev => ctn.style.opacity = 0.8)
    ctn.addEventListener('click', ev => {
      this.disable()
      if(ev.altKey) {
        this.opts.showInactive = !this.opts.showInactive
        this.enable()
      } else if(!ev.shiftKey) {
        setTimeout(_ => this.enable(), this.opts.hideTimeout)
      }
    })
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
