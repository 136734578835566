import * as AppOS from "../../../appos"
import { WorldLayer } from "../world_layer"
import { SotIngameTime, SotTimerElement } from "../../sot/ingame_time"

export class GridForeground extends WorldLayer {
  init() {
    this.enableInitially = true
    this.mayRenderAsync = true

    this.createTimeCtn()
    this.optreg.add("str",   "gridLabelColor", '#3338')
    this.optreg.add("bool",  "renderGridLabels", true)
    this.optreg.add("bool",  "renderCompass", true).onChange(v => this.setCompassOffsets())
    this.optreg.add("bool",  "renderSeanames", true)
    this.optreg.add("bool",  "renderClock", true).onChange(v => v ? this.startClock() : this.stopClock())
    this.optreg.add("bool",  "renderFullClock", false).onChange(v => v ? this.enableFullClock() : this.disableFullClock())
    this.optreg.add("float", "startFadingAtScale", 2)
    this.optreg.add("float", "endFadingAtScale", 3.75)
    this.optreg.add("float", "compassSizeRatio", 0.6)

    // this.thr_renderGridLabels = AppOS.Util.debounce(_ => this._renderGridLabels(), 350)
    this.thr_renderGridLabels = this._renderGridLabels

    // fade in when loaded
    this.dom.style.opacity = 0
    this.dom.style.transition = "opacity 250ms linear"
  }

  startup() {
    this.markers.textures.on("load", _ => this.texturesLoaded())
    this.compassAsset = this.markers.textures.image("compass", `compass.png`, { tex: { texture: "compass" } })
    if(this.markers.loaded) this.compassAsset.on("load", _ => this.texturesLoaded()).load()
  }

  ready() {
    super.ready()
    this.timeInstance ??= new SotTimerElement(this.timeCtnFull, {
      clockHandlesFilter: n => { return (n+1) % 3 != 0 }, // main
    })
    this.timeInstance.el = this.timeCtn
    if(this.o("renderClock")) this.startClock()
  }

  texturesLoaded() {
    this.dom.style.opacity = 1
    this.setCompassOffsets()
    this.update()
  }

  setCompassOffsets() {
    if(!this.compassAsset.loaded) return
    if(this.o("renderCompass")) {
      this.timeCtnCompact.style.width = `${this.compassAsset.opts.tex.image_size[0] * this.o("compassSizeRatio")}px`
      this.timeCtnCompact.style.bottom = `${this.compassAsset.opts.tex.image_size[1] * this.o("compassSizeRatio") - 10}px`
    } else {
      this.timeCtnCompact.style.width = `${this.compassAsset.opts.tex.image_size[0] * this.o("compassSizeRatio")}px`
      this.timeCtnCompact.style.bottom = `15px`
    }
  }

  get bgLayer() { return this.getLayer("GridBackground") }
  co(...args) { return this.bgLayer.o(...args) }
  coo(...args) { return this.bgLayer.oo(...args) }

  startClock() {
    this.timeInstance.start()
    this.timeCtn.classList.remove("invisible")
  }
  stopClock() {
    this.timeInstance.stop()
    this.timeCtn.classList.add("invisible")
  }

  enableFullClock() {
    if(this.timeCtn == this.timeCtnFull) return false
    const wasVisible = !this.timeCtn.classList.contains("invisible")
    if(wasVisible) this.timeCtn.classList.add("invisible")
    this.timeInstance.updateElement(this.timeCtn = this.timeCtnFull)
    if(wasVisible) this.timeCtn.classList.remove("invisible")
  }

  disableFullClock() {
    if(this.timeCtn == this.timeCtnCompact) return false
    const wasVisible = !this.timeCtn.classList.contains("invisible")
    if(wasVisible) this.timeCtn.classList.add("invisible")
    this.timeInstance.updateElement(this.timeCtn = this.timeCtnCompact)
    if(wasVisible) this.timeCtn.classList.remove("invisible")
  }

  get markers() { return this.bgLayer.markers }
  get gwidth() { return this.bgLayer.gwidth }
  get gheight() { return this.bgLayer.gheight }
  get renderedLinesX() { return this.bgLayer.renderedLinesX }
  get renderedLinesY() { return this.bgLayer.renderedLinesY }

  createTimeCtn() {
    this.timeCtnOuter = $(`<div class="position-absolute bottom-0 end-0"></div>`).appendTo(this.dom)

    this.timeCtnCompact = $(`
      <div data-appos-controller="SotIngameTime" style="
        pointer-events: all;
        color: #333c;
        font-size: 0.9rem;
        text-shadow: -1px -1px 0px #62A6EEcc, 1px 1px 0px #62A6EEcc, -1px 1px 0px #62A6EEcc, 1px -1px 0px #62A6EEcc;
      " class="invisible font-windlass-extended position-absolute end-0 d-flex">
        <div style="flex: 1" class="text-end"><span data-v="day"></span></div>
        <div class="px-1">•</div>
        <div style="flex: 1">
          <span data-v="hour"></span>:<span data-v="minute"></span>
        </div>
      </div>
    `).appendTo(this.timeCtnOuter).get(0)

    this.timeCtnFull = $(`
      <div data-appos-controller="SotIngameTime" class="p-2 overflow-hidden invisible" style="pointer-events: all">
        <div class="clock-face-container position-relative" data-v="clockFaceOuter" style="--size: 200px">
          <div class="clock-face position-absolute" data-v="clockFace">
            <div class="position-absolute top-50 start-50 clock-handles" data-v="clockHandles"></div>
            <div class="position-absolute top-50 start-50 clock-notches" data-v="clockNotches"></div>
          </div>
          <div class="clock-static position-absolute" data-v="clockStatic">
            <div class="position-absolute top-0 start-50 now-marker"></div>
            <div class="position-absolute top-50 start-50 center-display">
              <div class="text-center">
                <span data-v="month" class="d-none"></span>
                <span data-v="day"></span><br>
                <span data-v="hour"></span>:<span data-v="minute"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    `).appendTo(this.timeCtnOuter).get(0)

    this.timeCtn = this.timeCtnCompact
  }

  render() {
    if(!this.enabled) return
    this.clear()
    if(this.markers?.loaded && this.co("renderMarkers") && this.o("renderSeanames") && this.markers?.seanames) this._renderSeanames()
    if(this.co("renderMarkers") && this.o("renderCompass")) this._renderCompass()
    if(this.o("renderGridLabels") && this.renderedLinesX) this.thr_renderGridLabels()
  }

  _renderGridLabels() {
    this.trx(ctx => {
      ctx.strokeStyle = this.o("gridLabelColor")
      ctx.fillStyle = this.o("gridLabelColor")
      // const fsize = Math.max(22, 30 / Math.max(1, this.world.vscale))
      const fsize = 22
      ctx.font = `${fsize}px windlass-extended`

      const longestText = this.ctx.measureText("20").width * 1.1 * this.world.realScale

      for (var i = 0; i < 27; i++) {
        if(i < 26 && (this.renderedLinesX[i] || this.renderedLinesX[i + 1])) { // ltr
          const label = String.fromCharCode(65 + i)
          const m = ctx.measureText(label)
          const fha = m.fontBoundingBoxAscent * this.world.realScale
          const fhd = m.fontBoundingBoxDescent * this.world.realScale

          let tx = -this.world.xZero + (i * this.gwidth) + (this.gwidth / 2) - ((m.width / 2) * this.world.realScale)
          let ty = -this.world.yZero //- (m.fontBoundingBoxDescent * this.world.realScale) //+ ((m.fontBoundingBoxAscent + m.fontBoundingBoxDescent) * this.world.realScale)

          // move y into view if necessary
          if(ty - fha < this.viewportRect.topleft[1]) {
            ty = this.viewportRect.topleft[1] + fha
          } else {
            ty -= fhd
          }

          this.fillText(label, [tx, ty])
        }
        if(i < 26 && (this.renderedLinesY[i] || this.renderedLinesY[i + 1])) { // ttb
          const label = `${i + 1}`
          const m = this.ctx.measureText(label)
          const fw = m.width * this.world.realScale
          const fhd = m.fontBoundingBoxDescent * this.world.realScale

          let tx = -this.world.xZero
          let ty = -this.world.yZero + (i * this.gheight) + (this.gheight / 2) + (((m.fontBoundingBoxAscent - m.fontBoundingBoxDescent) / 2) * this.world.realScale)

          if(tx - longestText < this.viewportRect.topleft[0]) {
            tx = this.viewportRect.topleft[0] + (longestText / 2) - (fw / 2)
          } else {
            tx -= (longestText / 2) + (fw / 2) //+ fhd
          }

          this.fillText(label, [tx, ty])
        }
      }
    })
  }

  _renderSeanames() {
    let alpha = 1.0
    let clampedScale = Math.max(1, this.world.vscale)

    if(clampedScale > this.o("startFadingAtScale")) {
      alpha = 1 - ((clampedScale - this.o("startFadingAtScale")) / (this.o("endFadingAtScale") - this.o("startFadingAtScale")))
      if(alpha < 0) return
    }

    this.trx(ctx => {
      ctx.globalAlpha = alpha
      this.markers?.seanames.forEach(md => {
        if(!md.blob) return
        const ti = [md.image_size[0] * this.world.scale / clampedScale, md.image_size[1] * this.world.scale / clampedScale]
        const tloc = this.translateOnPlane(md.location, ti)
        this.drawImage(md.blob, tloc, ti[0], ti[1])
        // this.drawCircle(md.location, 10000, { color: "purple" })
      })
    })
  }

  _renderCompass() {
    if(!this.compassAsset.loaded) return false
    const md = this.compassAsset.opts.tex
    if(!md.blob) return
    const clampedScale = this.world.vscale
    const ti = [
      (md.image_size[0] * this.o("compassSizeRatio")) * this.world.scale / clampedScale,
      (md.image_size[1] * this.o("compassSizeRatio")) * this.world.scale / clampedScale
    ]
    const parentRect = this.worldParentRect
    const loc = this.translateViewport([parentRect.width, parentRect.height])
    const tloc = this.translateOnPlane(loc, ti, -1, -1)
    this.drawImage(md.blob, tloc, ti[0], ti[1])
    // this.drawCircle(loc, 10000, { color: "purple" })
  }
}
