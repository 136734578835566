class KeyboardControls {
  constructor(elect) {
    this.elect = elect
  }

  hook() {
    this.elect.i_query.addEventListener("keydown", ev => {
      if(ev.keyCode == 27) { // escape
        ev.preventDefault()
        return this.elect.hide()
      } else if(ev.keyCode == 13) { // enter
        ev.preventDefault()
        return this.elect.confirmSelection()
      } else if(ev.keyCode == 38) { // arrow up
        ev.preventDefault()
        return ev.shiftKey ? this.elect.selectFirstResult() : this.elect.selectPreviousResult()
      } else if(ev.keyCode == 40) { // arrow down
        ev.preventDefault()
        return ev.shiftKey ? this.elect.selectLastResult() : this.elect.selectNextResult()
      }
    })
  }
}

export { KeyboardControls }
