class Util {
  static visitPage(url, force_turbolinks = null) {
    if(window.Turbo && !(force_turbolinks != null && !force_turbolinks)) {
      Turbo.visit(url)
    } else if (window.Turbolinks && !(force_turbolinks != null && !force_turbolinks)) {
      Turbolinks.visit(url)
    } else {
      window.location.href = url
    }
  }

  static reloadPage() {
    AppOS.Util.visitPage(...args)
    this.visitPage(window.location)
  }

  static upperFirst (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  static getHashParams(urlDecode = true, source = window.location) {
    const result = {}
    if (!source.hash) return result

    let hsh = source.hash.substr(1)
    if(urlDecode) hsh = decodeURIComponent(hsh)
    const parts = hsh.split("&")
    parts.forEach(kv => {
      const kvp = kv.split("=")
      const key = kvp.shift()
      result[key] = kvp.join("=")
    })
    return result
  }

  static updateHashParams(toMerge = {}, opts) {
    this.setHashParams(Object.assign({}, this.getHashParams(), toMerge), opts)
  }

  static serializeHashParams(hparams = {}, opts = {}) {
    const hsh = []
    for (const [k, v] of Object.entries(hparams)) {
      if(v === undefined) continue
      if(v === "" && opts.clearBlanks) continue
      if(v === null)
        hsh.push(`${k}`)
      else
        hsh.push(`${k}=${v}`)
    }
    return hsh.join("&")
  }

  static setHashParams(hparams = {}, opts = {}) {
    // window.location.hash = `#${this.serializeHashParams(hparams)}`
    history.replaceState(undefined, undefined, `#${this.serializeHashParams(hparams)}`)
  }

  static observeVisibility(el, opts = {}, callback) {
    if(!IntersectionObserver) {
      console.warn("IntersectionObserver not available")
      return false
    }

    if (typeof opts == "function") {
      callback = opts
      opts = {}
    }

    el = $(el).get(0)
    opts.root ??= document.documentElement

    const observer = new IntersectionObserver(((entries, observer) => {
      entries.forEach(entry => callback?.(entry.intersectionRatio > 0, observer))
    }), opts)

    observer.observe(el)
    return observer
  }

  static debounce(func, timeout = 300) {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => { func.apply(this, args) }, timeout)
    }
  }

  static debounce_leading(func, timeout = 300) {
    let timer
    return (...args) => {
      if (!timer) {
        func.apply(this, args)
      }
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = undefined
      }, timeout)
    }
  }

  static throttle(func, timeout = 300) {
    let timer

    return (...args) => {
      if(timer) return

      timer = setTimeout(() => {
        func.apply(this, args)
        timer = undefined
      }, timeout)
    }
  }

  static throttle_leading(func, timeout = 300) {
    let timer

    return (...args) => {
      if(timer) return

      func.apply(this, args)
      timer = setTimeout(() => {
        timer = undefined
      }, timeout)
    }
  }

  static throttle_debounce(func, timeout = 300) {
    let timer
    let timer2

    return (...args) => {
      clearTimeout(timer2)
      timer2 = setTimeout(() => { func.apply(this, args) }, timeout)

      if(timer) return

      func.apply(this, args)
      timer = setTimeout(() => {
        timer = undefined
      }, timeout)
    }
  }

  static throttle_leading_debounce(func, timeout = 300) {
    let timer
    let timer2

    return (...args) => {
      if(!timer2) func.apply(this, args)
      clearTimeout(timer2)
      timer2 = setTimeout(() => { func.apply(this, args) }, timeout)

      if(timer) return

      func.apply(this, args)
      timer = setTimeout(() => {
        timer = undefined
      }, timeout)
    }
  }

  static scrollParent(el, includeHidden) {
    const position = $(el).css( "position" )
    const excludeStaticParent = position === "absolute"
    const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/
    const scrollParent = $(el).parents().filter(function() {
      const parent = $( this )
      if (excludeStaticParent && parent.css( "position" ) === "static") {
        return false
      }
      return overflowRegex.test(parent.css( "overflow" ) + parent.css( "overflow-y" ) + parent.css( "overflow-x" ))
    }).eq(0)

    return position === "fixed" || !scrollParent.length ?
      $( $(el)[0].ownerDocument || document ) :
      scrollParent;
  }

  static scrollIntoViewIfNeeded(target, opts = {}) {
    target = $(target)
    opts.centerX ??= true
    opts.parent ??= target.parent()
    opts.sparent ??= this.scrollParent(target)
    opts.behavior ??= "auto"

    const toff = target.offset()
    const soff = opts.sparent.offset()
    const stop = opts.sparent.scrollTop()

    let x = 0, y = 0
    let usableHeight = opts.sparent.innerHeight() - target.outerHeight()
    x = toff.top - soff.top //+ stop

    if (opts.excludeHeight && !Array.isArray(opts.excludeHeight)) {
      opts.excludeHeight = [opts.excludeHeight]
    }
    if (opts.excludeHeight) {
      opts.excludeHeight.forEach(el => {
        const oh = $(el).outerHeight()
        x -= oh
        usableHeight -= oh
      })
    }

    if(x < 0) {
      let starget = x + stop
      if (opts.centerX) {
        starget -= Math.floor(usableHeight / 2) //- Math.floor(target.outerHeight() / 2)
      }
      opts.sparent.get(0).scrollTo({ top: starget, left: y, behavior: opts.behavior })
    } else if (x > 0) {
      let starget = stop
      if(x - usableHeight > 0) {
        starget += (x - usableHeight)
        if (opts.centerX) {
          starget += Math.floor(usableHeight / 2) //- Math.floor(target.outerHeight() / 2)
        }
        opts.sparent.get(0).scrollTo({ top: starget, left: y, behavior: opts.behavior })
      }
    }
  }
}

export { Util }
