import * as AppOS from "./appos"

class Debugger {
  constructor(app) {
    this.app = app
    this.namespaces = {}
    AppOS.NamespacedConsole.applyOn(this, { base: this.app, namespace: "Debugger" })
  }

  getProxy() {
    this.proxy = new Proxy(this, {
      get(target, name, receiver) {
        if(typeof target[name] == "function") return target[name].bind(target)
        if(name == "__parent") return target
        let rv = Reflect.get(target.namespaces, name, receiver)
        return rv ? rv.getProxy() : rv
      }
    })

    return this.proxy
  }

  _register(ns, attr, vklass) {
    if(!this.namespaces[ns]) {
      this.namespaces[ns] = new Debugger.Namespace(ns, this)
    }
    this.namespaces[ns]._registerValue(attr, vklass)
  }

  enable() {
    Object.entries(this.namespaces).forEach(([name, ns]) => {
      Object.entries(ns.values).forEach(([name, v]) => v.set(true))
    })
  }

  disable() {
    Object.entries(this.namespaces).forEach(([name, ns]) => {
      Object.entries(ns.values).forEach(([name, v]) => v.set(false))
    })
  }
}

Debugger.Namespace = class {
  constructor(name, deb) {
    this.app = deb.app
    this.name = name
    this.deb = deb
    this.values = {}
  }

  getProxy() {
    return this.proxy = new Proxy(this, {
      get(target, name, receiver) {
        return Reflect.get(target.values, name, receiver)
      },
      set(target, name, val) {
        let rv = Reflect.get(target.values, name)
        rv ? rv.set(val) : console.error("invalid value name", name, "for NS", target)
      }
    })
  }

  _registerValue(attr, vklass) {
    const inst = new vklass(this, attr)
    this.values[attr] = inst
    inst.init?.()
  }
}

Debugger.Value = class {
  constructor(ns, name) {
    this.app = ns.app
    this.ns = ns
    this.name = name
    this.value = false
    AppOS.SimpleEvents.applyOn(this, ["change"])
    AppOS.NamespacedConsole.applyOn(this, { base: this.ns.deb, namespace: _ => [this.ns.name, this.name].join(".") })
  }

  set(v) {
    if (this.value != v) {
      this.events.fire("change", this, v, this.value)
      this.change?.(v, this.value)
      this.value = v
    }
  }

  true() { this.set(true) }
  yes() { this.set(true) }
  false() { this.set(false) }
  no() { this.set(false) }
}

export { Debugger }
