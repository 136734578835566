import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "HlCountdown"

  static DEFAULT_OPTIONS = {
  }

  init() {
  }

  documentLoad() {
  }

  pageLoad() {
    this.countdown?.destroy()
    if($("#hl_countdown").length) {
      this.countdown = new HlCountdown($("#hl_countdown")).initUI()
    }
  }
}

class HlCountdown {
  constructor(ctn) {
    this.ctn = $(ctn)
    this.ui = this.ctn.find(".hlui")
    this.s_duration = this.ui.find(`[data-setting="duration"]`)
    this.a_start = this.ui.find(`[data-action="start"]`)
    this.a_pause = this.ui.find(`[data-action="pause"]`)
    this.a_resume = this.ui.find(`[data-action="resume"]`)
    this.a_stop = this.ui.find(`[data-action="stop"]`)
    this.a_timeleft = this.ui.find(`[data-action="timeleft"]`)
    this.d_countdown = this.ui.find(`[data-display="countdown"]`)

    this.running = false
    this.duration = 0
    this.synth = new HlSynth(this.ctn.data("files"))

    window.hl = this
  }

  destroy() {
    if(!this.running) return
  }

  get settingDuration() {
    return HlCountdown.parseHumanSeconds(this.s_duration.val())
  }

  get currentDuration() {
    if(this.running) {
      const now = Date.now()
      let val = this.endAt - now + this.pausedFor
      if(this.pausedAt) {
        val += now - this.pausedAt
      }
      if (val < 0) val = 0
      return val / 1000
    } else {
      return this.duration
    }
  }

  set displayCountdown(seconds) {
    let v = HlCountdown.humanizeSeconds(seconds)
    if(v == "") v = "ended"
    this.d_countdown.html(v)
  }

  initUI() {
    this.s_duration.keyup(ev => {
      if(this.running) return
      let new_duration
      try {
        this.s_duration.removeClass("is-invalid")
        new_duration = this.settingDuration
        if (isNaN(new_duration)) throw("input resulted in NaN")
        this.displayCountdown = this.duration = new_duration
      } catch(err) {
        this.s_duration.addClass("is-invalid")
        console.error(err)
      }
    }).keyup()

    this.a_start.show()
    this.a_pause.hide()
    this.a_resume.hide()
    this.a_stop.hide()

    this.a_start.click(ev => this.start())
    this.a_pause.click(ev => this.pause())
    this.a_resume.click(ev => this.resume())
    this.a_stop.click(ev => this.stop())
    this.a_timeleft.click(ev => this.timeleft())

    return this
  }

  start() {
    if(this.running) return false
    this.startedAt = Date.now()
    this.endAt = this.startedAt + (this.duration * 1000)
    this.intermissions = this.calculateIntermissions(this.duration)
    this.pausedFor = 0
    this.pausedAt = null
    this.running = true
    this.s_duration.attr("disabled", "disabled")
    this.a_start.hide()
    this.a_pause.show()
    this.a_stop.show()
    this.uiUpdateLoop()
  }

  stop() {
    if(!this.running) return false
    this.running = false
    this.pausedAt = null
    this.startedAt = null
    this.endAt = null
    this.s_duration.removeAttr("disabled")
    this.a_start.show()
    this.a_pause.hide()
    this.a_resume.hide()
    this.a_stop.hide()
    this.displayCountdown = this.duration
  }

  pause() {
    if(!this.running) return false
    if(this.pausedAt) return false
    this.pausedAt = Date.now()
    this.a_pause.hide()
    this.a_resume.show()
  }

  resume() {
    if(!this.running) return false
    if(!this.pausedAt) return false

    this.pausedFor += Date.now() - this.pausedAt
    this.pausedAt = null

    this.a_resume.hide()
    this.a_pause.show()
    // this.uiUpdateLoop()
  }

  uiUpdateLoop() {
    const dur = this.currentDuration
    this.displayCountdown = dur
    // if(dur == 0) this.stop()
    if(!this.running) return
    if(this.pausedAt) {
      setTimeout(_ => this.uiUpdateLoop(), 999)
    } else {
      setTimeout(_ => this.uiUpdateLoop(), 250)
    }
  }

  timeleft() {
    const words = this.timeToWords(this.currentDuration)
    if(words.length) words.push("time.remaining")
    return this.synth.say(words)
  }

  timeToWords(dur) {
    const hum = HlCountdown.humanizeSeconds(dur, false).split(" ")
    const toSay = []

    while(hum.length > 1 && parseInt(hum[hum.length - 1]) == 0) {
      hum.pop()
    }

    hum.forEach(tcp => {
      const tm = tcp.match(/([0-9]+)(.)/)
      if(!tm) {
        console.warn(`unable to interpret time part`, tcp)
        return
      }
      toSay.push(this.synth.number(tm[1]))
      toSay.push(this.synth.unitFile(tm[2]))
    })

    return toSay
  }

  calculateIntermissions(duration) {
    let p = 0
    const i = []
    console.log("duration", duration)


    // if(duration <= 3600)
    let intervals = [3600, 1800, 600, 300, 60, 30, 10, 5, 4, 3, 2, 1]

    // let remainingTime = countdownDuration;

    // for (const interval of intervals) {
    //   while (remainingTime >= interval) {
    //     intermissions.push(interval);
    //     remainingTime -= interval;
    //   }
    // }

    // return intermissions;

    //if (duration >= 30)

    console.log("intermissions", i)
    return i
  }

  static parseHumanSeconds(str) {
    str += ""
    str = str.trim()
    let seconds = 0

    if(str.includes(":")) {
      const chunks = str.split(":").reverse()

      chunks.forEach((c, i) => {
        switch (i) {
          case 0: // seconds
            seconds += parseFloat(c)
            break;
          case 1: // minutes
            seconds += parseInt(c) * 60
            break;
          case 2: // hours
            seconds += parseInt(c) * 3600
            break;
          case 3: // days
            seconds += parseInt(c) * 86400
            break;
          case 4: // years?
            seconds += parseInt(c) * 86400 * 365
            break;
          default:
            console.log("what?", i, c)
            break;
        }
      })
    } else if (str.match(/^[0-9\sydhms]+$/i)) {
      const chunks = str.split(" ")

      chunks.forEach(c => {
        if (c.endsWith("y")) {
          seconds += parseInt(c) * 86400 * 365
        } else if (c.endsWith("d")) {
          seconds += parseInt(c) * 86400
        } else if (c.endsWith("h")) {
          seconds += parseInt(c) * 3600
        } else if (c.endsWith("m")) {
          seconds += parseInt(c) * 60
        } else if (c.endsWith("s")) {
          seconds += parseInt(c)
        } else {
          seconds += parseInt(c)
        }
      })
    } else {
      seconds += parseInt(str)
    }
    return seconds
  }

  static humanizeSeconds(seconds, colonized = true, allowSecondFallback = true) {
    if(typeof seconds == "string") seconds = HlCountdown.parseHumanSeconds(seconds)
    let res = []

    res.push(Math.floor(seconds / (86400 * 365)))
    seconds -= res[res.length - 1] * (86400 * 365)

    res.push(Math.floor(seconds / 86400))
    seconds -= res[res.length - 1] * 86400

    res.push(Math.floor(seconds / 3600))
    seconds -= res[res.length - 1] * 3600

    res.push(Math.floor(seconds / 60))
    seconds -= res[res.length - 1] * 60

    res.push(Math.floor(seconds))
    while(res[0] == 0) { res.shift() }

    if(res.length == 0 && seconds > 0) {
      res.push(0)
    }

    if(colonized) {
      if (res.length == 1 && allowSecondFallback) {
        return `${res[0]}s`
      }
      const stra = []
      res.reverse().forEach((el, i) => {
        let s = el + ""
        if (i <= 2) {
          while(s.length < 2) { s = `0${s}` }
        } else if (i == 3 && res[4]) {
          while(s.length < 3) { s = `0${s}` }
        }
        stra.push(s)
      })
      return stra.reverse().join(":")
    } else {
      const stra = []
      res.reverse().forEach((el, i) => {
        switch (i) {
          case 0: // seconds
            stra.push(`${el}s`)
            break;
          case 1: // minutes
            stra.push(`${el}m`)
            break;
          case 2: // hours
            stra.push(`${el}h`)
            break;
          case 3: // days
            stra.push(`${el}d`)
            break;
          case 4: // years?
            stra.push(`${el}y`)
            break;
          default:
            console.log("what?", i, c)
            break;
        }
      })
      return stra.reverse().join(" ")
    }
  }
}

class HlSynth {
  constructor(files) {
    this.files = files
    this.buffers = {}
    this.ctx = new (window.AudioContext || window.webkitAudioContext)()
    console.log(files)
  }

  resolveFile(which) {
    let parts = which.split(".")
    let pointer = this.files
    let part = null
    while (part = parts.shift()) {
      pointer = pointer[part]
      if(!pointer) return null
    }
    return pointer
  }

  unitFile(num) {
    const map = {}
    map["h"] = "hours"
    map["m"] = "minutes"
    map["s"] = "seconds"
    return map[num] ? `time.${map[num]}` : null
  }

  numberFile(num) {
    const map = {}
    map[1] = "01_one"
    map[2] = "02_two"
    map[3] = "03_three"
    map[4] = "04_four"
    map[5] = "05_five"
    map[6] = "06_six"
    map[7] = "07_seven"
    map[8] = "08_eight"
    map[9] = "09_nine"
    map[10] = "10_ten"
    map[11] = "11_eleven"
    map[12] = "12_twelve"
    map[13] = "13_thirteen"
    map[14] = "14_fourteen"
    map[15] = "15_fifteen"
    map[16] = "16_sixteen"
    map[17] = "17_seventeen"
    map[18] = "18_eighteen"
    map[19] = "19_nineteen"
    map[20] = "20_twenty"
    map[25] = "25_twentyfive"
    map[30] = "30_thirty"
    map[40] = "40_fourty"
    map[50] = "50_fifty"
    map[60] = "60_sixty"
    map[70] = "70_seventy"
    map[80] = "80_eighty"
    map[90] = "90_ninety"
    map[100] = "100_onehundred"
    return map[num] ? `numbers.${map[num]}` : null
  }

  number(inum) {
    const simple = this.numberFile(inum)
    if(simple) return [simple]
    if(inum > 999) throw("cannot say numbers higher than 999")

    const res = []
    let tens = Math.floor(parseInt(inum) / 10) * 10
    let rest = parseInt(inum) % 10

    console.log(tens, rest)

    // implement maybe
    if (tens >= 100) throw("not implemented to say numbers higher than 100")

    const tsnd = this.numberFile(tens)
    const rsnd = this.numberFile(rest)

    if(!tsnd) throw("did not find ten-sound", tens)
    if(!rsnd) throw("did not find rest-sound", rest)

    res.push(tsnd)
    res.push(rsnd)

    return res
  }

  getBuffer(which) {
    return new Promise((resolve, reject) => {
      if(this.buffers[which]) return resolve(this.buffers[which])

      const fn = this.resolveFile(which)
      if(!fn) return reject()

      fetch(fn)
        .then(response => response.arrayBuffer())
        .then(buffer => this.ctx.decodeAudioData(buffer))
        .then(audioBuffer => {
          this.buffers[which] = audioBuffer
          resolve(this.buffers[which])
        })
    })
  }

  play(which, at = 0, whenEnded = true) {
    return new Promise((resolve, reject) => {
      this.getBuffer(which).then(buffer => {
        const source = this.ctx.createBufferSource()
        source.buffer = buffer

        // Set looping to true
        // source.loop = true;

        source.connect(this.ctx.destination)
        if (at >= 0) source.start(at)
        if(whenEnded) {
          source.addEventListener("ended", _ => { resolve(source) })
        } else {
          resolve(source)
        }
      })
    })
  }

  async say(words) {
    const toSay = words.flat()
    if(!toSay.length) return

    while(toSay.length) {
      const part = toSay.shift()
      console.log(part)
      await this.play(part)
    }

    return true

    // this.play("numbers.10_ten")
    // .then(_ => this.play("time.minutes"))
    // .then(_ => this.play("time.remaining"))
    //console.log(await )

    // this.play("time.remaining")
  }

  async say2() {
    this.play("numbers.05_five")
    setTimeout(_ => this.play("numbers.04_four"), 1000)
    setTimeout(_ => this.play("numbers.03_three"), 2000)
    setTimeout(_ => this.play("numbers.02_two"), 3000)
    setTimeout(_ => this.play("numbers.01_one"), 4000)
    //console.log(await )

    // this.play("time.remaining")
  }



  async sayTimeRemaining() {

  }

  // // Create an AudioContext
  // const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  // // Load the audio file
  // fetch('path/to/your/audiofile.mp3')
  //   .then(response => response.arrayBuffer())
  //   .then(buffer => audioContext.decodeAudioData(buffer))
  //   .then(audioBuffer => {
  //   })
  //   .catch(error => console.error('Error loading audio:', error));

}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
