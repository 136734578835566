import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Sot__ItemFinder"

  init() {
  }

  documentLoad() {
  }

  pageLoad() {
    $(`div[data-appos-controller="SotItemFinder"]`).each((i, el) => {
      const ff = new ItemFinder(el)
    })
  }
}

class LazyRemoteEntitlements {
  constructor(base, opts = {}) {
    this.base = base
    this.opts = Object.assign({}, {
      entitlement_endpoint: "entitlements",
      wiki_names: false,
    }, opts)
  }

  get populate() {
    if(this.promise) return this.promise
    this.promise = new Promise((resolve, reject) => {
      let url = this.base + "/" + this.opts.entitlement_endpoint

      const args = []
      if(this.opts.wiki_names) args.push(["with_wiki_name_map"])

      if(args.length) {
        url += "?"
        url += args.map(arg => {
          if(arg.length == 1) {
            return arg[0]
          } else {
            return `${arg[0]}=${arg[1]}`
          }
        }).join("&")
      }

      fetch(url).then(r => r.json()).then(json => {
        this.data = json
        this.loaded = true
        resolve(this)
      })
    })
    return this.promise
  }

  async do(func) {
    return await this.populate.then(func)
  }

  wikiIndex(name) {
    const ni = this.data.items.indexOf(name)
    if(ni > -1) return ni
    const wi = this.data.wiki_names.indexOf(name)
    if(wi > -1) return wi
    return false
  }

  chestUrlForWiki(name, fallbackType, fallbackTab) {
    const wi = this.wikiIndex(name)

    if(wi == false) {
      if(!fallbackType || !fallbackTab) return false
      const anchor = `#jump=${encodeURIComponent(name).replace(/'/g, "%27")}&forceJumpOnce=true`
      return `${this.base}/${fallbackType}?tab=${fallbackTab}&showMissing=1${anchor}`
    } else {
      const tm = this.data.type_map[wi]
      const type = this.data.types[tm[0]]
      const tab = this.data.types[tm[1]]
      const anchor = `#jump=${encodeURIComponent(this.data.items[wi]).replace(/'/g, "%27")}&forceJumpOnce=true`
      return `${this.base}/${type}?tab=${tab}${anchor}`
    }
    return false
  }
}

class ItemFinder {
  constructor(ctn) {
    this.ctn = $(ctn)

    if($("#pv-main").data("pvBase")) {
      this.entitlements = new LazyRemoteEntitlements($("#pv-main").data("pvBase"), { wiki_names: true })
    }

    this.questionList = this.ctn.data(`questions`)
    this.chestFallback = this.ctn.data(`chestFallback`)
    this.questions = this.ctn.find(`[data-qa]`)
    this.answers = this.ctn.find(`[data-a]`)
    this.collection = this.ctn.find(`[data-role="collection"]`)
    this.items = this.collection.find(`[data-role="item"]`)
    this.dropdownTpl = this.ctn.find(`template[data-role="dropdown-tpl"]`).get(0)
    this.dataModalTpl = this.ctn.find(`template[data-role="dataModal-tpl"]`).get(0)

    this.answers.find(`button`).click(ev => this.answerClicked(ev))
    this.items.on("click", `[data-action="showMoreActions"]`, ev => this.moreClicked(ev))
    this.items.on("click", `[data-action="edit"]`, ev => this.editClicked(ev))
    this.items.on("click", `[data-action="dataModal"]`, ev => this.dataModalClicked(ev))
    this.items.on("click", `[data-action="revealRandom"]`, ev => this.revealRandomClicked(ev))

    // enable more buttons
    this.enableMoreButtons()

    // hide dependent questions
    this.questions.filter(`[data-da]`).hide()

    const results = this.ctn.find(`[data-role="filter_results"]`)
    this.results = {
      shown: results.find(`[data-displays="shown"]`).text(this.items.length).get(0),
      shown_perc: results.find(`[data-displays="shown_perc"]`).text(`100.00%`).get(0),
      hidden: results.find(`[data-displays="hidden"]`).text(0).get(0),
      total: results.find(`[data-displays="total"]`).text(this.items.length).get(0),
    }

    this.filterCollection()
  }

  async enableMoreButtons() {
    this.ctn.find(`.disabled[data-action="showMoreActions"]`).removeClass("disabled")
  }

  answerClicked(ev) {
    const btn = $(ev.currentTarget).blur()
    this.updateAnswerButtons(btn.closest(`[data-a]`), btn.data("role"))
    this.filterCollection()
    return false
  }

  revealRandomClicked(ev) {
    const btn = $(ev.currentTarget)
    const out = btn.closest(`[data-random-hint]`)
    out.prev().removeClass("d-none")
    out.remove()

    return false
  }

  moreClicked(ev) {
    const btn = $(ev.currentTarget)
    const dropdownContent = this.dropdownTpl.content.cloneNode(true)
    const dropdownCtn = dropdownContent.children[0]

    dropdownCtn.addEventListener('hidden.bs.dropdown', ev => {
      dropdownCtn.remove()
      btn.show()
    })

    // check chest link
    if (this.entitlements) {
      $(dropdownCtn).find(`[data-when-claimed]`).show()
      const item = btn.closest(`[data-role="item"]`)
      const item_name = item.data("name")
      const clnk = $(dropdownCtn.querySelector(`[data-action="openInChest"]`))
      const clnks = clnk.find(`span`)

      clnk.addClass("disabled")
      clnks.html(`loading chest<span class="spinner-border spinner-border-sm text-warning ms-2"></span>`)
      this.entitlements.do(mapper => {
        if(!document.body.contains(clnks.get(0))) return
        const wi = mapper.wikiIndex(item_name)
        const cf = this.chestFallback || []
        const url = mapper.chestUrlForWiki(item_name, ...cf)
        if(!url) {
          clnks.text("failed, sorry")
          clnk.addClass("text-danger")
        } else if(wi == false) {
          clnk.attr("href", url).attr("target", "_blank")
          clnks.text("open in chest")
          clnk.removeClass("disabled").addClass("text-warning")
        } else {
          clnk.attr("href", url).attr("target", "_blank")
          clnks.text("open in chest")
          clnk.removeClass("disabled")
        }
      })
    }

    btn.hide().before(dropdownContent)
    const dropdown = new bootstrap.Dropdown(dropdownCtn.querySelector("button"))
    document.querySelectorAll(".dropdown-menu.show").forEach(el => {
      const p = el.closest(".dropup,.dropdown")?.querySelector(`[data-bs-toggle="dropdown"]`)
      p && bootstrap.Dropdown.getInstance(p)?.hide?.()
    })
    dropdown.show()

    return false
  }

  editClicked(ev) {
    const lnk = $(ev.currentTarget).blur()
    const item = lnk.closest(`[data-role="item"]`)
    let url = item.find(`[data-role="target-url"]`).attr("href")
    url += "/finder_props"
    if(item.data("pid")) url += "/" + item.data("pid") + "/edit"

    window.open(url)
    ev.preventDefault()
    return true
  }

  dataModalClicked(ev) {
    const lnk = $(ev.currentTarget)
    const item = lnk.closest(`[data-role="item"]`)
    const modalContent = this.dataModalTpl.content.cloneNode(true)
    const modalCtn = modalContent.children[0]
    const tbody = $(modalCtn.querySelector("tbody"))

    modalCtn.addEventListener('hidden.bs.modal', ev => { modalCtn.remove() })

    if(!this.questionList) {
      const table = $(modalCtn.querySelector("table"))
      table.before(`<div class="py-3 text-danger fw-bold">No questions defined, cannot render data table!</div>`)
      table.remove()
    } else {
      this.questionList.forEach(q => {
        const tr = $("<tr>")
        const v = item.data(q)
        let fv = ""

        if(v == 0) {
          fv = `<i class="bi bi-x-circle-fill text-danger" style="white-space: nowrap"></i>`
        } else if(v == 1) {
          fv = `<i class="bi bi-check-circle-fill text-success" style="white-space: nowrap"></i>`
        } else {
          fv = `<i class="bi bi-slash-circle-fill text-warning" style="white-space: nowrap"></i> <strong class="text-warning">NO VALUE</strong>`
        }

        $("<th>").addClass("text-end").text(q).appendTo(tr)
        $("<th>").html(fv).appendTo(tr)
        tbody.append(tr)
      })
    }

    item.append(modalContent)
    const modal = bootstrap.Modal.getOrCreateInstance(modalCtn)
    modal.show()

    ev.preventDefault()
    return true
  }

  getAnswers(withNulls = true) {
    const res = {}
    this.questions.each((i, q) => {
      if(q.dataset.virtual) return
      const agrp = $(q).find(`[data-a="${q.dataset.qa}"]:visible`)
      if(!agrp.length) return
      const invert = agrp.data("invertA")
      const a = agrp.find(`[data-selected]`).data("role")
      if(a == "yes") {
        res[q.dataset.qa] = invert ? false : true
      } else if (a == "no") {
        res[q.dataset.qa] = invert ? true : false
      } else if (a == "nil" && withNulls) {
        res[q.dataset.qa] = null
      }
    })
    return res
  }

  getAnswer(qa, visible = true) {
    const agrp = this.ctn.find(`[data-a="${qa}"]${visible ? ":visible" : ""}`)
    if(!agrp.length) return
    const invert = agrp.data("invertA")
    const a = agrp.find(`[data-selected]`).data("role")
    if(a == "yes") {
      return invert ? false : true
    } else if (a == "no") {
      return invert ? true : false
    } else if (a == "nil") {
      return null
    }
  }

  async filterCollection() {
    let ts = 0, th = 0
    const started = Date.now()
    const answers = this.getAnswers(false)
    this.items.each((i, el) => {
      let hidden = false
      if(!el.classList.contains("no-filter")) {
        Object.entries(answers).forEach(([q, a]) => {
          if(a && el.dataset[q] != "1") hidden = true
          if(!a && el.dataset[q] != "0") hidden = true
          if(hidden) return
        })
      }
      if(hidden) {
        th += 1
        el.classList.add("d-none")
      } else {
        ts += 1
        el.classList.remove("d-none")
      }
    })

    this.results.shown.textContent = ts
    this.results.hidden.textContent = th
    this.results.shown_perc.textContent = (parseFloat(ts) / parseFloat(ts + th) * 100).toFixed(2) + "%"
    this.results.total.textContent = ts + th
    this.updateFilterIndicators()

    console.log(ts, "shown", th, "hidden", "in", Date.now() - started)
  }

  async updateFilterIndicators() {
    this.ctn.find(`[data-fi]`).each((i, el) => {
      const a = this.items.filter(`[data-${el.dataset.fi}=1]:not(.d-none)`).length
      const b = this.items.filter(`[data-${el.dataset.fi}=0]:not(.d-none)`).length
      const r = parseFloat(Math.min(a, b)) / parseFloat(Math.max(a, b)) * 100
      el.querySelector(".text-danger").textContent = a
      el.querySelector(".text-success").textContent = b
      const fuckyou = el.querySelector(".progress-bar")
      if(fuckyou) fuckyou.style.width = `${r.toFixed(2)}%`
    })
  }

  updateAnswerButtons(agrp, value) {
    const q = agrp.closest(`[data-qa]`)
    const yes = agrp.find(`[data-role="yes"]`).removeClass("btn-success").addClass("btn-outline-success").removeAttr("data-selected")
    const no = agrp.find(`[data-role="no"]`).removeClass("btn-danger").addClass("btn-outline-danger").removeAttr("data-selected")
    const nil = agrp.find(`[data-role="nil"]`).removeClass("btn-warning").addClass("btn-outline-warning").removeAttr("data-selected")
    const zero_is = agrp.data("invertA") ? 1 : 0
    const one_is = agrp.data("invertA") ? 0 : 1

    if(value == "yes") {
      yes.toggleClass("btn-outline-success btn-success").attr("data-selected", true)
      this.disableDeps(q.data("qa"), zero_is)
      this.enableDeps(q.data("qa"), one_is)
    } else if (value == "no") {
      no.toggleClass("btn-outline-danger btn-danger").attr("data-selected", true)
      this.disableDeps(q.data("qa"), one_is)
      this.enableDeps(q.data("qa"), zero_is)
    } else {
      nil.toggleClass("btn-outline-warning btn-warning").attr("data-selected", true)
      this.disableDeps(q.data("qa"))
    }

    return false
  }

  disableDeps(qa, value) {
    let col = this.questions.filter(`[data-da="${qa}"]`)
    if(value != null) { col = col.filter(`[data-ea=${value ? 1 : 0}]`) }
    col.each((i, el) => {
      if(el.dataset.qa) { this.disableDeps(el.dataset.qa) }
      $(el).hide()
    })
  }

  enableDeps(qa, value) {
    let col = this.questions.filter(`[data-da="${qa}"]`)
    if(value !== null) { col = col.filter(`[data-ea=${value ? 1 : 0}]`) }
    col.each((i, el) => {
      if(el.dataset.qa) {
        const ga = this.getAnswer(el.dataset.qa, false)
        if(ga != null) this.enableDeps(el.dataset.qa, ga)
      }
      $(el).show()
    })
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
