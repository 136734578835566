import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Bootstrap.ResponsiveDetails"

  init() {
    this.injectorId = "__appos_bootstrap5_responsive_detector"
  }

  documentLoad() {
    $(document).on("appos:bs5:responsiveDetails:openDefault", (ev) => {
      this.openDefault("xs")
      this.openDefault("sm")
      this.openDefault("md")
      this.openDefault("lg")
      this.openDefault("xl")
      this.openDefault("xxl")
    })
  }

  pageLoad() {
    this.injectDetector()
    $(document).trigger("appos:bs5:responsiveDetails:openDefault")
  }

  openDefault(which) {
    if(!this.is(which)) return
    $(`details[open-${which}]`).prop("open", true)
  }

  is(...anyOf) {
    for (var i = 0; i < anyOf.length; i++) {
      if($(`#${this.injectorId} .is-${anyOf[i]}`).is(":visible")) return true
    }
    return false
  }

  injectDetector() {
    let el = document.getElementById(this.injectorId)
    if(el) return $(el)

    el = $("<span>").attr("id", this.injectorId)
    $("<span>").addClass("is-xs d-inline d-sm-none").appendTo(el)
    $("<span>").addClass("is-sm d-none d-sm-inline d-md-none").appendTo(el)
    $("<span>").addClass("is-md d-none d-md-inline d-lg-none").appendTo(el)
    $("<span>").addClass("is-lg d-none d-lg-inline d-xl-none").appendTo(el)
    $("<span>").addClass("is-xl d-none d-xl-inline d-xxl-none").appendTo(el)
    $("<span>").addClass("is-xxl d-none d-xxl-inline").appendTo(el)
    el.appendTo($("body"))
    return el
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
