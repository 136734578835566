import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "FakeDetails"

  static DEFAULT_OPTIONS = {
    debug: false,
    as: "input",
  }

  init() {
    if(this.opts.as) this.app[this.opts.as] = this
  }

  documentLoad() {
  }

  pageLoad() {
    this.resetObserver()

    $(`[data-visibility-marker]`).each((i, el) => {
      this.observer.observe(el, { attributes: true })
      this.toggle(el.dataset.visibilityMarker)
    })
  }

  resetObserver() {
    this.observer?.disconnect()

    let options = {
      // rootMargin: '0px',
      // threshold: 0,
      // root: document.documentElement,
    }

    this.observer = new MutationObserver((e, o) => this.handleChange(e, o), options)
  }

  async handleChange(entries, observer) {
    entries.forEach(m => {
      if(m.type != "attributes") return
      if(m.attributeName != "open") return
      this.toggle(m.target.dataset.visibilityMarker, m.target.attributes.getNamedItem("open") !== null)
    })
  }

  toggle(id, toggle = null) {
    if(toggle === null) {
      toggle = $(`[data-visibility-marker="${id}"]`).get(0).attributes.getNamedItem("open") !== null
    }
    if(toggle) {
      // only show those that have an open marker
      $(`[data-visible-by~="${id}"]`).filter((i, el) => {
        const ids = el.dataset.visibleBy.split(" ").filter(i => i)

        // it's the origin
        if(ids.length == 1) return true

        // return false if any marker is closed
        for (var i = 0; i < ids.length; i++) {
          if ($(`[data-visibility-marker="${ids[i]}"]`).get(0)?.attributes?.getNamedItem("open") === null)
            return false
        }

        // seems goood
        return true
      }).toggle(toggle)
    } else {
      $(`[data-visible-by~="${id}"]`).toggle(toggle)
    }
    return this
  }

  hide(id) {
    this.toggle(id, false)
    return this
  }

  show(id) {
    this.toggle(id, true)
    return this
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
