const initArray = function(length, defval) {
  const arr = new Array(length)
  if(typeof defval == "function") {
    for (var i = 0; i < length; i++) { arr[i] = defval(i) }
  } else {
    for (var i = 0; i < length; i++) { arr[i] = defval }
  }
  return arr
}

class Logo {
  constructor(pos, opts = {}) {
    this.pos = pos
    this.opts = Object.assign({}, {
      scale: 0.2,
      padding: 20,
      spacing: 20,
      // stroke: "black",
      stroke: null,
    }, opts)

    this.glyphs = []
    this.glyphs.push(new LogoGlyph)
    this.glyphs.push(new LetterG)
    this.glyphs.push(new LetterA)
    this.glyphs.push(new LetterM)
    this.glyphs.push(new LetterE)
    this.glyphs.push(new LetterS)
    this.glyphs.push(new LetterP)
    this.glyphs.push(new LetterL)
    this.glyphs.push(new LetterA)
    this.glyphs.push(new LetterN)
    this.glyphs.push(new LetterE)
    this.glyphs.push(new LetterT)

    this.gxo = initArray(this.glyphs.length, 0)
    this.gyo = initArray(this.glyphs.length, 0)
    this.gso = initArray(this.glyphs.length, (_ => { return Math.floor(Math.random() * 5) + 1 }))
    this.gho = initArray(this.glyphs.length, 0)

  }

  get width() {
    let x = 0
    this.glyphs.forEach((g, i) => {
      x += g.width + this.opts.spacing
    })
    return x
  }

  get x() { return this.pos.x }
  get y() { return this.pos.y }

  update(tick, time) {
    this.glyphs.forEach((g, i) => {
      this.gho[i] = this.gho[i] + Math.random() * (parseFloat(this.gso[i]) / 10) % 360
      g.fill = `hsl(${this.gho[i]}, 100%, 50%)`
      g.scale = this.opts.scale
      g.stroke = this.opts.stroke
    })
  }

  render(ctx) {
    ctx.save()

    let x = 0
    let y = 0

    this.glyphs.forEach((g, i) => {
      g.render(ctx, this.x + x + this.gxo[i], this.y + y + this.gyo[i])
      x += g.width + this.opts.spacing
    })
    ctx.restore()
    // this.drawLogo(this.ctx, 0.00 + xmod,  0.00+ ymod, this.incrementedFillStyle( 0, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawG(this.ctx,  355.83 + (xmod), 83.25 + (ymod), this.incrementedFillStyle( 1, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawA(this.ctx,  513.12 + (xmod), 83.25 + (ymod), this.incrementedFillStyle( 2, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawM(this.ctx,  684.69 + (xmod), 83.25 + (ymod), this.incrementedFillStyle( 3, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawE(this.ctx,  876.73 + (xmod), 83.25 + (ymod), this.incrementedFillStyle( 4, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawS(this.ctx, 1002.56 + (xmod), 83.25 + (ymod), this.incrementedFillStyle( 5, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawP(this.ctx, 1151.05 + (xmod), 83.25 + (ymod), this.incrementedFillStyle( 6, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawL(this.ctx, 1291.62 + (xmod), 83.25 + (ymod), this.incrementedFillStyle( 7, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawA(this.ctx, 1397.91 + (xmod), 83.25 + (ymod), this.incrementedFillStyle( 8, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawN(this.ctx, 1569.45 + (xmod), 83.25 + (ymod), this.incrementedFillStyle( 9, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawE(this.ctx, 1736.19 + (xmod), 83.25 + (ymod), this.incrementedFillStyle(10, Math.floor(Math.random() * 20) + 1), this.opts.stroke)
    // this.drawT(this.ctx, 1862.01 + (xmod), 83.25 + (ymod), this.incrementedFillStyle(11, Math.floor(Math.random() * 20) + 1), this.opts.stroke)

    // let x = 0
    // let y = 0
    // this.ctx.beginPath()
    // this.ctx.moveTo(x = 208, y = 319.49)

    // this.ctx.lineTo(x, y = 282.66)
    // this.ctx.stroke()

    // ctx.miterLimit=4
    // ctx.translate(1.611650485436897,0)

    // if (this.tick % 2000 >= 1000) {
    //   ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    // }

    // if(this.tick % 120 == 0) console.log(this.tick)

    // let xmod = 0
    // let ymod = 0
    // xmod = (this.tick * 0.3) % 200
    // if(xmod <= 50) {
    //   xmod = (xmod / 2)
    //   // console.log("a", xmod)
    // } else if(xmod <= 100) {
    //   xmod = 25 - ((xmod - 50) / 2)
    //   // console.log("b", xmod)
    // } else if(xmod <= 150) {
    //   xmod = 0 - ((xmod - 100) / 2)
    //   // console.log("c", xmod)
    // } else {
    //   xmod = -25 + ((xmod - 150) / 2)
    //   // console.log("d", xmod)
    // }
    // ymod = (this.tick * 0.3) % 200
    // if(ymod <= 100) {
    //   ymod = -25 + ymod / 2
    // } else {
    //   ymod = 100 - (25 + ymod / 2)
    // }
    // // console.log(ymod)

    // xmod += this.opts.padding
    // ymod += this.opts.padding
  }
}

class Glyph {
  static GLYPH_DIM = [123, 147]
  static GLYPH_OFF = [0, 83.25]
  static GLYPH_BOFF = [0, 0]

  constructor() {
    this.fill = "magenta"
    this.stroke = "red"
    this.scale = 1
  }

  render(ctx, xo, yo) {
    ctx.save()
    ctx.scale(this.scaleX ?? this.scale, this.scaleY ?? this.scale)

    ctx.beginPath()

    // ctx.globalAlpha = 0.4;
    // ctx.fillRect(
    //   xo + this.constructor.GLYPH_BOFF[0] + this.constructor.GLYPH_OFF[0],
    //   yo + this.constructor.GLYPH_BOFF[1] + this.constructor.GLYPH_OFF[1],
    //   this.constructor.GLYPH_DIM[0] + (window.x ?? 0),
    //   this.constructor.GLYPH_DIM[1] + (window.y ?? 0)
    // )
    // ctx.globalAlpha = 1.0;

    this.draw(ctx, xo + this.constructor.GLYPH_OFF[0], yo + this.constructor.GLYPH_OFF[1])
    ctx.closePath()

    if(this.fill != undefined && this.fill != null) {
      ctx.fillStyle = this.fill
      ctx.fill("evenodd")
    }

    if(this.stroke != undefined && this.stroke != null) {
      ctx.strokeStyle = this.stroke
      ctx.stroke()
    }

    ctx.restore()
  }

  get width() { return this.constructor.GLYPH_DIM[0] }
  get height() { return this.constructor.GLYPH_DIM[1] }
}

class LogoGlyph extends Glyph {
  static GLYPH_DIM = [306, 308]
  static GLYPH_OFF = [0, 0]

  draw(ctx, xo = 0, yo = 0, fill, stroke) {
    ctx.moveTo(198.94+xo,301.1+yo)
    ctx.lineTo(198.94+xo,264.27000000000004+yo)
    ctx.translate(153.02009902661587+xo,154.57346817426452+yo)
    ctx.arc(0,0,118.92,1.174351766050975,-0.0012905164552894366,1)
    ctx.translate(-162.08009902661587,-172.96346817426453)
    ctx.bezierCurveTo(281,111.05000000000004,234.14,60.290000000000035,174.20999999999998,54.47000000000003)
    ctx.lineTo(174.20999999999998,93.36)
    ctx.translate(162.16735990240605,172.85298597662452)
    ctx.arc(0,0,80.4,-1.4204464404519745,0.9642202294644777,0)
    ctx.translate(-162.16735990240605,-172.85298597662452)
    ctx.lineTo(208,165.09)
    ctx.lineTo(174.2,165.09)
    ctx.lineTo(174.2,326)
    ctx.bezierCurveTo(170.26999999999998,326.3,166.29,326.46,162.29,326.46)
    ctx.bezierCurveTo(77.64999999999999,326.46,9.019999999999982,257.46,9.019999999999982,172.45999999999998)
    ctx.bezierCurveTo(9.019999999999982,87.45999999999998,77.64999999999998,18.45999999999998,162.29,18.45999999999998)
    ctx.bezierCurveTo(246.93,18.45999999999998,315.54999999999995,87.45999999999998,315.54999999999995,172.45999999999998)
    ctx.translate(161.5501327118547,172.6621762440317)
    ctx.arc(0,0,154,-0.0013128331305577777,1.2644023591017275,0)
    ctx.translate(-161.5501327118547,-172.6621762440317)
    ctx.closePath()

    ctx.moveTo(151.2,264.68)
    ctx.lineTo(151.2,165.09)
    ctx.lineTo(117.4,165.09)
    ctx.lineTo(117.4,238.91)
    ctx.translate(163.20626668332682,172.84686328567236)
    ctx.arc(0,0,80.39,2.1770594647091945,4.562352053457889,0)
    ctx.translate(-163.20626668332682,-172.84686328567236)
    ctx.lineTo(151.19,54.47)
    ctx.bezierCurveTo(91.26,60.29,44.41,111.05,44.41,172.81)
    ctx.bezierCurveTo(44.41,234.57,91.26,285.33,151.2,291.14)
  }
}

class LetterG extends Glyph {
  static GLYPH_DIM = [137, 153]
  static GLYPH_BOFF = [0, -3]

  draw(ctx, xo = 0, yo = 0) {
    ctx.moveTo(0 + xo, 73.48 + yo)
    ctx.bezierCurveTo(0 + xo, 27.280000000000015 + yo, 34.31999999999999 + xo, -2.4200000000000017 + yo,77 + xo, -2.4200000000000017 + yo)
    ctx.bezierCurveTo(105.60000000000002 + xo, -2.4200000000000017 + yo, 124.30000000000001 + xo, 11.230000000000004 + yo, 136 + xo, 27.929999999999993 + yo)
    ctx.lineTo(115.11000000000001 + xo, 40.02999999999999 + yo)
    ctx.translate(432.59512707454576, 151.05936680939226)
    ctx.arc(-355.83 + xo, -83.25 + yo, 47.35, -0.6269548351561715, -1.5656247530465155, 1)
    ctx.translate(-441.65512707454576, -169.44936680939227)
    ctx.bezierCurveTo(57.06999999999999 + xo, 38.849999999999994 + yo, 35.46999999999997 + xo, 60.849999999999994 + yo, 35.46999999999997 + xo, 91.85 + yo)
    ctx.bezierCurveTo(35.46999999999997 + xo, 122.41999999999999 + yo, 57.01999999999998 + xo, 144.85 + yo, 86.07 + xo, 144.85 + yo)
    ctx.bezierCurveTo(101.07 + xo,144.85 + yo,114.21999999999997 + xo,138.03 + yo,120.82 + xo,131.65 + yo)
    ctx.lineTo(120.82 + xo,109.02000000000001 + yo)
    ctx.lineTo(76.82 + xo,109.02000000000001 + yo)
    ctx.lineTo(76.82 + xo,86.37 + yo)
    ctx.lineTo(146.33999999999997 + xo,86.37 + yo)
    ctx.lineTo(146.33999999999997 + xo,141.14 + yo)
    ctx.translate(442.6940191394278,171.9239747963232)
    ctx.arc(-355.83 + xo,-83.25 + yo,79.31,0.7228585110602335,1.5808080832489335,0)
    ctx.translate(-442.6940191394278,-171.9239747963232)
    ctx.bezierCurveTo(43.370000000000005 + xo,167.98 + yo,9.060000000000002 + xo,137.84 + yo,9.060000000000002 + xo,91.87 + yo)
  }
}

class LetterA extends Glyph {
  static GLYPH_DIM = [147, 147]

  draw(ctx, xo = 0, yo = 0, fill, stroke) {
    ctx.moveTo(118.12 + xo, 146.74 + yo)
    ctx.lineTo(107.34 + xo, 118.36 + yo)
    ctx.lineTo( 40.03 + xo, 118.36 + yo)
    ctx.lineTo( 29.25 + xo, 146.74 + yo)
    ctx.lineTo(  0.00 + xo, 146.74 + yo)
    ctx.lineTo( 57.64 + xo,   0.00 + yo)
    ctx.lineTo( 89.76 + xo,   0.00 + yo)
    ctx.lineTo(147.40 + xo, 146.74 + yo)
    ctx.closePath()

    // cutout
    ctx.moveTo(73.69  + xo, 25.74  + yo)
    ctx.lineTo(47.29  + xo, 95.74  + yo)
    ctx.lineTo(100.09 + xo, 95.74  + yo)
  }
}

class LetterM extends Glyph {
  static GLYPH_DIM = [153, 147]

  draw(ctx, xo = 0, yo = 0, fill, stroke) {
    ctx.moveTo(126.71 + xo, 146.74 + yo)
    ctx.lineTo(126.71 + xo,  33.45 + yo)
    ctx.lineTo( 81.84 + xo, 146.74 + yo)
    ctx.lineTo( 70.84 + xo, 146.74 + yo)
    ctx.lineTo( 25.74 + xo,  33.42 + yo)
    ctx.lineTo( 25.74 + xo, 146.74 + yo)
    ctx.lineTo(  0.00 + xo, 146.74 + yo)
    ctx.lineTo(  0.00 + xo,   0.00 + yo)
    ctx.lineTo( 36.30 + xo,   0.00 + yo)
    ctx.lineTo( 76.30 + xo, 100.76 + yo)
    ctx.lineTo(116.12 + xo,   0.00 + yo)
    ctx.lineTo(152.42 + xo,   0.00 + yo)
    ctx.lineTo(152.42 + xo, 146.74 + yo)
  }
}

class LetterE extends Glyph {
  static GLYPH_DIM = [100, 147]

  draw(ctx, xo = 0, yo = 0, fill, stroke) {
    ctx.moveTo(  0.00 + xo, 146.74 + yo)
    ctx.lineTo(  0.00 + xo,   0.00 + yo)
    ctx.lineTo(100.54 + xo,   0.00 + yo)
    ctx.lineTo(100.54 + xo,  22.66 + yo)
    ctx.lineTo( 25.74 + xo,  22.66 + yo)
    ctx.lineTo( 25.74 + xo,  60.50 + yo)
    ctx.lineTo( 98.99 + xo,  60.50 + yo)
    ctx.lineTo( 98.99 + xo,  83.15 + yo)
    ctx.lineTo( 25.74 + xo,  83.15 + yo)
    ctx.lineTo( 25.74 + xo, 124.07 + yo)
    ctx.lineTo(100.54 + xo, 124.07 + yo)
    ctx.lineTo(100.54 + xo, 146.72 + yo)
    ctx.lineTo(  0.00 + xo, 146.72 + yo)
  }
}

class LetterS extends Glyph {
  static GLYPH_DIM = [117, 153]
  static GLYPH_BOFF = [0, -3]

  draw(ctx, xo = 0, yo = 0, fill, stroke) {
    ctx.moveTo(0+xo,126.05000000000001+yo)
    ctx.lineTo(14.520000000000095+xo,106.05000000000001+yo)
    ctx.translate(61.01047059215216+xo,63.43003936979471+yo)
    ctx.arc(0,0,63.07,2.3996021881323024,1.57191366636704,1)
    ctx.translate(-1072.630470592152,-165.07003936979473)
    ctx.bezierCurveTo(1093.68,228.14,1101.8200000000002,217.79999999999998,1101.8200000000002,207.89999999999998)
    ctx.bezierCurveTo(1101.8200000000002,177.09999999999997,1016.4600000000002,196.23999999999998,1016.4600000000002,142.34999999999997)
    ctx.bezierCurveTo(1016.4600000000002,117.92999999999996,1037.5800000000002,99.22999999999996,1069.91,99.22999999999996)
    ctx.bezierCurveTo(1092.5600000000002,99.22999999999996,1111.26,106.70999999999997,1124.68,119.90999999999997)
    ctx.lineTo(1110.18,139)
    ctx.bezierCurveTo(1098.53,127.35,1082.91,122.06,1067.51,122.06)
    ctx.bezierCurveTo(1052.51,122.06,1042.87,129.53,1042.87,140.31)
    ctx.bezierCurveTo(1042.87,167.81,1128.2299999999998,150.87,1128.2299999999998,205.43)
    ctx.bezierCurveTo(1128.2299999999998,229.85000000000002,1110.8499999999997,250.97,1071.4799999999998,250.97)
    ctx.bezierCurveTo(1044.4,251,1024.82,241.33,1011.62,227.69)
  }
}

class LetterP extends Glyph {
  static GLYPH_DIM = [113, 147]

  draw(ctx, xo = 0, yo = 0, fill, stroke) {
    ctx.moveTo(0      + xo, 146.74 + yo)
    ctx.lineTo(0      + xo, 0      + yo)
    ctx.lineTo(64.67  + xo, 0      + yo)
    ctx.bezierCurveTo(95.47  + xo, 0     + yo, 112.41 + xo, 20.90 + yo, 112.41 + xo, 46    + yo)
    ctx.bezierCurveTo(112.41 + xo, 70.85 + yo, 95.26  + xo, 91.75 + yo, 64.67  + xo, 91.75 + yo)
    ctx.lineTo(25.73 + xo,  91.75 + yo)
    ctx.lineTo(25.73 + xo, 146.75 + yo)
    ctx.lineTo(0     + xo, 146.75 + yo)
    ctx.closePath()
    ctx.moveTo(86 + xo, 45.98 + yo)
    ctx.bezierCurveTo(86 + xo, 31.90 + yo,75.65 + xo, 22.66 + yo,61.1400000000001 + xo, 22.66 + yo)
    ctx.lineTo(25.720000000000027 + xo, 22.66 + yo)
    ctx.lineTo(25.720000000000027 + xo, 69.08000000000004 + yo)
    ctx.lineTo(61.1400000000001 + xo, 69.08000000000004 + yo)
    ctx.bezierCurveTo(75.67000000000007 + xo, 69.07999999999998 + yo,86.02000000000021 + xo, 59.829999999999984 + yo,86.02000000000021 + xo, 45.98000000000002 + yo)
  }
}

class LetterL extends Glyph {
  static GLYPH_DIM = [91, 147]

  draw(ctx, xo = 0, yo = 0, fill, stroke) {
    ctx.moveTo(  0.00 + xo, 146.74 + yo)
    ctx.lineTo(  0.00 + xo,   0.00 + yo)
    ctx.lineTo( 25.74 + xo,   0.00 + yo)
    ctx.lineTo( 25.74 + xo, 124.07 + yo)
    ctx.lineTo( 90.41 + xo, 124.07 + yo)
    ctx.lineTo( 90.41 + xo, 146.72 + yo)
    ctx.lineTo(  0.00 + xo, 146.72 + yo)
  }
}

class LetterN extends Glyph {
  static GLYPH_DIM = [128, 147]

  draw(ctx, xo = 0, yo = 0, fill, stroke) {
    ctx.moveTo(102.29 + xo, 146.74 + yo)
    ctx.lineTo( 25.74 + xo,  41.80 + yo)
    ctx.lineTo( 25.74 + xo, 146.74 + yo)
    ctx.lineTo(  0.00 + xo, 146.74 + yo)
    ctx.lineTo(  0.00 + xo,   0.00 + yo)
    ctx.lineTo( 26.40 + xo,   0.00 + yo)
    ctx.lineTo(101.40 + xo, 101.64 + yo)
    ctx.lineTo(101.40 + xo,   0.00 + yo)
    ctx.lineTo(127.14 + xo,   0.00 + yo)
    ctx.lineTo(127.14 + xo, 146.74 + yo)
  }
}

class LetterT extends Glyph {
  static GLYPH_DIM = [115, 147]

  draw(ctx, xo = 0, yo = 0, fill, stroke) {
    ctx.moveTo( 44.44 + xo, 146.74 + yo)
    ctx.lineTo( 44.44 + xo,  22.66 + yo)
    ctx.lineTo(  0.00 + xo,  22.66 + yo)
    ctx.lineTo(  0.00 + xo,   0.00 + yo)
    ctx.lineTo(114.61 + xo,   0.00 + yo)
    ctx.lineTo(114.61 + xo,  22.66 + yo)
    ctx.lineTo( 70.17 + xo,  22.66 + yo)
    ctx.lineTo( 70.17 + xo, 146.72 + yo)
    ctx.lineTo( 44.44 + xo, 146.72 + yo)
  }
}

export { Logo, Glyph, LogoGlyph, LetterG, LetterA, LetterM, LetterE, LetterS, LetterP, LetterL, LetterN, LetterT }
