import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Bootstrap.Turbospy"

  init() {
  }

  pageLoad() {
    document.querySelectorAll('[data-ao-bs-spy="scroll"]').forEach(el => {
      new bootstrap.ScrollSpy(el)
    })
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
