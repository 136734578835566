import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["checkbox", "statusSaving", "statusSaved", "statusError", "statusErrorDetail"]
  static values = {
    url: String,
  }

  connect() {
  }

  disconnect() {
    clearTimeout(this.hideStatusTimer)
  }

  setStatus(status, detail) {
    clearTimeout(this.hideStatusTimer)
    this.statusSavingTarget.classList.toggle("d-none", status != "saving")
    this.statusSavedTarget.classList.toggle("d-none", status != "saved")
    this.statusErrorTarget.classList.toggle("d-none", status != "error")
    if(detail) {
      this.statusErrorDetailTarget.textContent = detail ?? "error"
    }
    if(status == "saved") {
      this.hideStatusTimer = setTimeout(_ => this.setStatus(), 2000)
    }
  }

  toggleSetting(event) {
    const name = this.checkboxTarget.name
    const value = this.checkboxTarget.checked

    this.setStatus("saving")
    this.checkboxTarget.disabled = true
    this.toggleRemote(name, value).then(j => {
      this.checkboxTarget.disabled = false
      this.checkboxTarget.checked = j.value
      this.setStatus("saved")
      this.removeUpdateButtons()
    }).catch(err => {
      this.checkboxTarget.disabled = false
      this.checkboxTarget.checked = !this.checkboxTarget.checked
      this.setStatus("error", err?.message ?? err)
      console.error("Failed to save setting", name, "with value", value, "due to", err)
    })
  }

  removeUpdateButtons() {
    const parent = document.getElementById("gSotProfileUpdateButtons")
    if(!parent || parent.classList.contains("buttons-cleared")) return

    const parentWidth = parent.getBoundingClientRect().width
    parent.style.minWidth = parentWidth + "px"
    parent.innerHTML = ""
    parent.classList.add("buttons-cleared")
    const replacer = document.createElement("button")
    replacer.type = "button"
    replacer.classList.add("btn", "btn-secondary", "text-nowrap", "text-muted")
    replacer.textContent = "(reload page to update buttons)"
    replacer.onclick = _ => { Turbo.visit(window.location) }
    parent.append(replacer)
  }

  async toggleRemote(sopt, sval) {
    const request = new FetchRequest("post", this.urlValue, {
      body: JSON.stringify({ sopt, sval })
    })
    const response = await request.perform()
    if (response.ok) {
      return await response.json
    } else {
      throw `${response.statusCode}: ${response.response.statusText}`
    }
  }
}
