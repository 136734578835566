export class TransientMap {
  static has(moj, key, isMap) {
    if(isMap ?? moj.constructor.name == "Map") {
      return moj.has(key)
    } else {
      return moj.hasOwnProperty(key)
    }
  }

  static get(moj, key, isMap) {
    if(isMap ?? moj.constructor.name == "Map") {
      return moj.get(key)
    } else {
      return moj[key]
    }
  }

  static set(moj, key, value, isMap) {
    if(isMap ?? moj.constructor.name == "Map") {
      return moj.set(key, value)
    } else {
      return moj[key] = value
    }
  }

  constructor(moj) {
    this.moj = moj
    this.isMap = moj.constructor.name == "Map"
  }

  has(key) { this.constructor.has(this.moj, key, this.isMap) }
  get(key) { this.constructor.get(this.moj, key, this.isMap) }
  set(key, value) { this.constructor.set(this.moj, key, value, this.isMap) }
}
