class DataProvider {
  static SUBTRACTIVE = true
  get subtractive() { return this.constructor.SUBTRACTIVE }

  constructor(elect) {
    this.elect = elect
    this.init?.()
  }

  async search(query, filters, callback) {
    let results = await this.getData(query)
    filters ??= []
    filters = Array.isArray(filters) ? filters : Array(filters)
    filters.forEach(f => {
      results = f.filter(results, query)
    })

    callback?.(results, this.elect, this)
    return results
  }

  async all(callback) {
    if(!this.subtractive) {
      throw "data provider must be substractive to return all"
    }

    const data = await this.getData()
    callback?.(data, this.elect, this)
    return data
  }

  async allEach(callback) {
    const all = await this.all()
    all.forEach(i => callback(i, this.elect, this))
  }

  dataSingleton() {
    if(this._data) return this._data
    const data = []
    const pfdata = this.prefabData(data)
    return this._data = pfdata ?? data
  }

  async getData(q) {
    return this.dataSingleton()
  }

  // =================
  // = IMPLEMENT API =
  // =================
  prefabData(into) {
    throw(`must implement either #prefabData(data) that pushes into data (or returns array) or (async)#getData(<"">query) that returns promise`)
  }
}


// No data (free tagging)
class NoDataProvider extends DataProvider {
  static SUBTRACTIVE = false
}


// Create dataset from <select> element
class SelectElementProvider extends DataProvider {
  prefabData(data) {
    this.elect.i_value.querySelectorAll("option").forEach(opt => {
      if(this.elect.opts.excludeEmptyValues && !opt.value) return
      const od = { name: opt.textContent, value: opt.value }
      for (const attr of opt.attributes) { od[attr.name] ??= attr.value }
      data.push(od)
    })
  }
}


// Create dataset from data-options (on data-elect container)
class DataAttributeProvider extends DataProvider {
  prefabData(data) {
    return JSON.parse(this.elect.ctn.dataset.options)
  }
}


// Create dataset from remote data-url (on data-elect container)
class FetchProvider extends DataProvider {
  static SUBTRACTIVE = false
}

// Create dataset once from remote data-url (on data-elect container)
class FetchStaticProvider extends DataProvider {
}



export {
  DataProvider,
  NoDataProvider,
  SelectElementProvider,
  DataAttributeProvider,
  FetchProvider,
  FetchStaticProvider,
}
