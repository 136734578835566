import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "ImageComparisonSlider"

  pageLoad() {
    document.querySelectorAll(`.image-comparison-slider`).forEach(el => {
      el.imageComparisonSlider ??= new ImageComparisonSlider(el)
    })
  }
}

class ImageComparisonSlider {
  constructor(ctn) {
    this.ctn = ctn
    this.slider = this.ctn.querySelector(`.slider`)
    this.beforeImage = this.ctn.querySelector(`.before-image`)
    this.sliderLine = this.ctn.querySelector(`.slider-line`)
    this.sliderIcon = this.ctn.querySelector(`.slider-icon`)
    this.btnCompareHorizontal = this.ctn.querySelector(`[data-action="compareHorizontal"]`)
    this.btnCompareVertical = this.ctn.querySelector(`[data-action="compareVertical"]`)

    this.hook()

    if(this.classes.contains("compare-horizontal")) {
      this.btnCompareHorizontal.style.display = "none"
      this.btnCompareVertical.style.display = null
    } else {
      this.btnCompareHorizontal.style.display = null
      this.btnCompareVertical.style.display = "none"
    }
  }

  get classes() {
    return this.ctn.classList
  }

  get sliderValue() {
    return this.slider.value
  }

  get mode() {
    return this.classes.contains("compare-horizontal") ? "horizontal" : "vertical"
  }

  sliderStepLeft(steps = 1) {
    this.slider.value = parseInt(this.slider.value) - steps
    this.slider.dispatchEvent(new Event("input", {"view": window, "bubbles": true }))
  }

  sliderStepRight(steps = 1) {
    this.slider.value = parseInt(this.slider.value) + steps
    this.slider.dispatchEvent(new Event("input", {"view": window, "bubbles": true }))
  }

  sliderReset() {
    this.slider.value = 50
    this.slider.dispatchEvent(new Event("input", {"view": window, "bubbles": true }))
  }

  hook() {
    this.slider.addEventListener("input", (ev) => this.setValue(ev.target.value))
    this.btnCompareVertical.addEventListener("click", ev => {
      ev.preventDefault()
      this.compareVertical()
    })
    this.btnCompareHorizontal.addEventListener("click", ev => {
      ev.preventDefault()
      this.compareHorizontal()
    })
  }

  setValue(value) {
    let sliderValue = (value ?? this.sliderValue) + "%"

    if(this.classes.contains("compare-horizontal")) {
      this.beforeImage.style.height = sliderValue
      this.sliderLine.style.top = sliderValue
      this.sliderIcon.style.top = sliderValue
    } else {
      this.beforeImage.style.width = sliderValue
      this.sliderLine.style.left = sliderValue
      this.sliderIcon.style.left = sliderValue
    }
  }

  compareVertical() {
    if(!this.classes.contains("compare-horizontal")) return
    this.classes.remove("compare-horizontal")
    this.btnCompareHorizontal.style.display = null
    this.btnCompareVertical.style.display = "none"

    this.beforeImage.style.height = null
    this.sliderLine.style.top = null
    this.sliderIcon.style.top = null
    this.setValue()
  }

  compareHorizontal() {
    if(this.classes.contains("compare-horizontal")) return
    this.classes.add("compare-horizontal")
    this.btnCompareHorizontal.style.display = "none"
    this.btnCompareVertical.style.display = null

    this.beforeImage.style.width = null
    this.sliderLine.style.left = null
    this.sliderIcon.style.left = null
    this.setValue()
  }

  swapMode(el) {
    if(this.mode == "horizontal") {
      this.compareVertical()
    } else {
      this.compareHorizontal()
    }
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component, ImageComparisonSlider }
