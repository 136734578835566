import { SimpleEvents } from "../../../appos/simple_events"
import { Util } from "../../../appos/util"
import { Debounce } from "../../../appos/lib/debounce"

export class UrlParamManager {
  constructor(world, opts = {}) {
    // SimpleEvents.applyOn(this, ["assetBeforeLoad", "assetLoad", "assetLoadFailed", "assetComplete"])
    this.world = world
    // this.opts = Object.assign({}, {

    // }, opts)

    this.throttledUpdate = new Debounce({ name: "update", debounce: 500, throttle: 1000 }, _ => this._updateLive())
    this.throttledPatch = new Debounce({ name: "patch", debounce: 500, throttle: 1000 }, _ => this.applyDelayedChanges())
    this.delayedUpdates = new Map()
  }

  getHashParams(...args) { return Util.getHashParams(...args) }
  updateHashParams(...args) { return Util.updateHashParams(...args) }
  serializeHashParams(...args) { return Util.serializeHashParams(...args) }
  setHashParams(...args) { return Util.setHashParams(...args) }

  buildCanonical(...args) { return this.serializeHashParams(this.buildCanonicalParams(...args)) }
  // setCanonicalHash(...args) { return this.setHashParams(this.buildCanonicalParams(...args)) }
  updateCanonical(sync, ...args) { this.throttledPatch.cancel(true); this.updateHashParams(this.buildCanonicalParams(sync, this.getHashParams(), ...args)) }
  removeCanonical() { this.updateCanonical(2, { removeUnsynced: true }) }
  _updateLive() { this.updateCanonical(2) }
  updateLive() { this.throttledUpdate.cancel(true) }
  updateLiveLater() { this.throttledUpdate.call() }

  updateNow(k, v) { return this.updateLater(k, v, true) }
  updateLater(k, v, now = false) {
    this.delayedUpdates.set(k, v)
    now ? this.throttledPatch.cancel(true) : this.throttledPatch.call()
    return this
  }

  applyDelayedChanges() {
    if(!this.delayedUpdates.size) return false
    // console.log("commit patch", this.delayedUpdates.size)
    const upd = {}
    this.delayedUpdates.forEach((v, k) => {
      // console.log("apply", k, v)
      upd[k] = v
      this.delayedUpdates.delete(k)
    })
    this.updateHashParams(upd)
  }

  buildCanonicalParams(sync = 1, source, opts = {}) {
    const dat = { x: undefined, y: undefined, r: undefined, l: undefined }

    // world / layer options
    const lopts = []
    this.world.optreg.serializeUrlParams(lopts, { sync, source, removeUnsynced: opts.removeUnsynced })
    this.world.layers.forEach(l => {
      l.optreg.serializeUrlParams(lopts, { sync, source, removeUnsynced: opts.removeUnsynced })
    })
    if(lopts.length) lopts.forEach(([key, value]) => dat[key] = value)

    return dat
  }

  buildLayerConfig() {
    const lconfig = []
    this.world.layers.forEach(l => {
      if(l.enabled && !l.enableInitially) lconfig.push(l.constructor.name)
      if(!l.enabled && l.enableInitially) lconfig.push("!" + l.constructor.name)
    })
    return lconfig
  }

  restoreFromCanonicalUrl() {
    setTimeout(_ => {
      this.restoreFromCanonicalParams(this.getHashParams())
    }, 0)
  }

  restoreParamWithSuppression(opt, value, optobj, l) {
    try {
      if(optobj.type == "cmd") {
        optobj.fakeSync({ url: 0 }, _ => { optobj.toggleValue(value) })
      } else {
        optobj.fakeSync({ url: 0 }, _ => { optobj.value = value })
      }
    } catch(e) {
      this.world.toastNotification(`Failed to set option '${opt}' for ${l ? `layer ${l}` : "world"}: ${e}`, { type: "danger", autohide: false })
      console.error(e)
    }
  }

  restoreFromCanonicalParams(uopt) {
    this.world.oo("l").fakeSync({ url: 0 }, _ => {
      if(uopt.x && uopt.y) this.world.panzoom.pan(parseFloat(uopt.x), parseFloat(uopt.y), { animate: false })
      if(uopt.r) this.world.panzoom.zoom(parseFloat(uopt.r), { animate: false })

      Object.entries(uopt).forEach(([key, value]) => {
        if(["x", "y", "r"].includes(key)) return;
        if (key == "l") {
          uopt.l.split(",").forEach(l => {
            const neg = l.startsWith("!")
            const lkey = neg ? l.substring(1) : l
            const layer = this.world.layer(lkey)
            if(layer) {
              neg ? layer.disable() : layer.enable()
            } else {
              this.world.toastNotification(`Failed to ${neg ? "disable" : "enable"} unknown layer: ${l}`, { type: "danger" })
            }
          })
        } else if (key.startsWith("lo.")) {
          const chunks = key.split(".")
          chunks.shift()
          const l = chunks.shift()
          const opt = chunks.join(".")
          const layer = this.world.layer(l)
          const optobj = layer?.optreg?.get?.(opt)
          if(!layer) {
            this.world.toastNotification(`Failed to set option '${opt}' for unknown layer: ${l}`, { type: "danger", autohide: false })
          } else if(!optobj) {
            this.world.toastNotification(`Failed to set unknown option '${opt}' for layer: ${l}`, { type: "danger", autohide: false })
          } else {
            this.restoreParamWithSuppression(opt, value, optobj, l)
          }
        } else if (this.world.oo(key)) {
          this.restoreParamWithSuppression(key, value, this.world.oo(key))
        }
      })
    })
    this.world.updateLayerConfig()

    return this
  }
}
