import * as AppOS from "../../appos"

class SpotlightImage {
  constructor(ctn, component) {
    this.ctn = $(ctn).data("aoSpotlight", this)
    this.component = component
    AppOS.NamespacedConsole.applyOn(this, { base: this.component, namespace: "Image" })

    if(this.ctn.data("aoPreview"))
      throw "not implemented, use > img[data-ao-preview] element"

    this.preview = this.ctn.find("img[data-ao-preview]")

    this.zoomed = false
    this.zooming = false
    this.afterZoom = []
    this._fake = null

    if(this.ctn.data("aoLazyTrigger") && IntersectionObserver) {
      this.lazifyTrigger()
    }

    // this.isTouch ? this.initTouch() : this.initDesktop()
  }

  get isTouch() {
    return "ontouchstart" in document.documentElement
  }

  get fake() {
    if(this._fake) return this._fake

    this._fake = $("<div>").addClass("ao-fake").data("aoOrigin", this).appendTo($("body"))
    const fakeloading = $("<div>").addClass("ao-loading animated").append($("<div>").addClass("spinner-border")).appendTo(this._fake)
    this._fakeimg = $("<img>").attr("src", this.preview.attr("src")).addClass("ao-img").appendTo(this._fake)

    let img = new Image()
    img.onload = _ => {
      fakeloading.remove()
      this._fakeimg?.attr("src", this.ctn.attr("href"))
    }
    img.src = this.ctn.attr("href")

    this._fake.on('click', ev => {
      this.toggleZoom()
      return false
    })

    this._fake.css({
      height: `${this.preview.outerHeight()}px`,
      width: `${this.preview.outerWidth()}px`,
      top: `${window.scrollY + this.preview.get(0).getBoundingClientRect().top}px`,
      left: `${window.scrollX + this.preview.get(0).getBoundingClientRect().left}px`,
    })

    return this._fake
  }

  lazifyTrigger() {
    // console.log("what")
    // this.preview.attr("data-original-source", this.preview.attr("src"))
    // this.preview.attr("data-original-alt", this.preview.attr("alt"))
    // this.preview.removeAttr("src")
    // this.preview.removeAttr("alt")
    // this.preview.removeAttr("loading")

    // const fakeloading = $("<span>").addClass("ao-loading animated").append($("<div>").addClass("spinner-border")).appendTo(this.ctn)

    // this.preview.on("load", ev => {
    //   console.log("loaded")
    //   if (this.preview.data("observer")) {
    //     this.preview.data("observer").disconnect()
    //     this.preview.removeData("observer")
    //   }
    //   this.preview.addClass("loaded").removeClass("loading")
    //   fakeloading.remove()
    // })

    // const obs = AppOS.Util.observeVisibility(this.preview, (visible, observer) => {
    //   if(this.preview.hasClass("loaded") || this.preview.hasClass("loading")) {
    //     console.log("visibility change, already loaded", visible, this.preview.get(0))
    //     return
    //   } else if (visible) {
    //     console.log("visibility change, loading initiated", visible, this.preview.get(0))
    //     this.preview.addClass("loading")
    //     this.preview.attr("src", this.preview.attr("data-original-source"))
    //     this.preview.attr("alt", this.preview.attr("data-original-alt"))
    //   }
    // })
    // this.preview.data("observer", obs)
  }

  toggleZoom(zto) {
    if(typeof zto == "undefined") zto = !this.zoomed
    if(!zto) {
      this.zoomed = false
      this.ctn.removeClass("active lastActive")
      this.zooming = true
      this.fake.css({
        height: `${this.preview.outerHeight()}px`,
        width: `${this.preview.outerWidth()}px`,
        top: `${window.scrollY + this.preview.get(0).getBoundingClientRect().top}px`,
        left: `${window.scrollX + this.preview.get(0).getBoundingClientRect().left}px`,
      })

      const endHandler = _ => {
        clearTimeout(this.transitionFallback)
        this._fake?.remove()
        this._fake = this._fakeimg = null
        this.zooming = false
        while(this.afterZoom.length > 0) {
          this.afterZoom.shift()(false)
        }
      }

      // wait for css transition
      this.fake.on("transitionend", endHandler)

      // fallback, sometimes transitionend doesn't fire
      clearTimeout(this.transitionFallback)
      this.transitionFallback = setTimeout(endHandler, 1000)
    } else {
      // delay rezoom (wait for transitionend)
      if(this.zooming) {
        this.afterZoom.push(_ => {
          this.toggleZoom(zto)
        })
        return
      }

      this.zoomed = true
      this.ctn.addClass("active")

      // last active for keyboard navigation
      $("[data-ao-spotlight]").removeClass("lastActive")
      this.ctn.addClass("lastActive")

      // // close all other
      // $(".ao-fake").each((i, el) => {
      //   if(el == this.ctn.get(0)) return
      //   $(el).data("aoOrigin")?.toggleZoom(false)
      // })

      this.fake // init fake in different tick or it won't transition
      setTimeout(_ => {
        const ww = window.innerWidth * 0.92
        const wh = window.innerHeight * 0.92
        const wr = ww/wh

        const pfw = this.preview.outerWidth()
        const pfh = this.preview.outerHeight()
        const pi = this.preview.get(0)
        const fw = pi.naturalWidth || pi.width
        const fh = pi.naturalHeight || pi.height

        const ir = fw / fh
        const ft = this.preview.get(0).getBoundingClientRect().top
        const fl = this.preview.get(0).getBoundingClientRect().left
        const fb = window.scrollY + ft + pfh

        let fth = 0
        let ftw = 0

        if (wr > ir) {
          // height limits
          fth = wh
          ftw = wh * ir
        } else {
          // width limits
          ftw = ww
          fth = ww / ir
        }

        // max upscale
        const up = this.ctn.data("aoUpscale") ?? true
        if(up) {
          let musr = this.ctn.data("aoMaxUpscale") ?? false
          if(musr) musr = parseFloat(musr)
          if(musr && !isNaN(musr)) {
            const us_wr = ftw / fw
            const us_hr = fth / fh
            if(us_wr > musr) ftw = (ftw / us_wr) * musr
            if(us_hr > musr) fth = (fth / us_hr) * musr
          }
        } else {
          ftw = Math.min(ftw, fw)
          fth = Math.min(fth, fh)
        }

        // position
        let at = window.scrollY + Math.min(ft, ((window.innerHeight - fth) / 2))
        let al = window.scrollX + Math.min(fl, ((window.innerWidth - ftw) / 2))

        // lower bind
        let lowB = Math.max(0, fb - (at + fth))
        at += lowB
        at -= Math.max(0, (at - window.scrollY + fth) - (window.innerHeight * 1))

        this.fake.css({
          height: `${fth}px`,
          width: `${ftw}px`,
          top: `${at}px`,
          left: `${al}px`,
        })
      }, 1)
    }
  }
}

export { SpotlightImage }
