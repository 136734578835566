export class Debounce {
  constructor(...args) {
    let opts = {}
    args.forEach(arg => {
      if(typeof arg == "number") opts.debounce = arg
      else if(typeof arg == "function") this.callback = arg
      else if(arg instanceof Object) this.opts = arg
    })
    this.opts = Object.assign({}, {
      lead: false,
      debounce: 0,
      throttle: 0,
    }, this.opts ?? {}, opts)
    this.debounceTimer = null
    this.throttleTimer = null
  }

  debounce(n) { return n === undefined ? this.opts.debounce : this.opts.debounce = n }
  throttle(n) { return n === undefined ? this.opts.throttle : this.opts.throttle = n }

  actuallyCall(mod) { this.callback(this, mod) }

  cancel(call = false, alwaysCall = call) {
    let canceled = 0
    if(this.debounceTimer) {
      canceled++
      clearTimeout(this.debounceTimer)
      this.debounceTimer = null
    }
    if(this.throttleTimer) {
      canceled++
      clearTimeout(this.throttleTimer)
      this.throttleTimer = null
    }

    if(alwaysCall || (call && canceled > 0)) this.actuallyCall("cancel")
  }

  call() {
    // debounce
    if(this.opts.debounce) {
      // lead
      if(!this.debounceTimer && this.opts.lead) this.actuallyCall("lead")

      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(_ => { this.actuallyCall("debounce") }, this.opts.debounce)
    }

    // throttle
    if(!this.opts.throttle || this.throttleTimer) return false
    this.actuallyCall("throttle")
    this.throttleTimer = setTimeout(_ => { this.throttleTimer = null }, this.opts.throttle)
  }
}
