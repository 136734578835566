export class CanvasTextElement {
  constructor(ctx, text, opts = {}, cb) {
    this.ctx = ctx
    this.inContext = false
    this.alwaysFill = true
    this.alwaysStroke = false
    this.strokeAfterFill = false
    this.anchorActual = false
    this.offsetX = 0
    this.offsetY = 0
    this.anchorX = 0
    this.anchorY = 0
    this.opts = Object.assign({}, {}, opts)

    const textOpts = ["alwaysFill", "alwaysStroke", "strokeAfterFill", "offsetX", "offsetY", "offset", "anchorX", "anchorY", "anchor", "anchorActual"]
    const callOpts = ["offset", "anchor"]
    Object.entries(this.opts).forEach(([key, value]) => {
      if (!textOpts.includes(key)) return false
      if (callOpts.includes(key)) {
        if(value instanceof Array) {
          return this[key](...value)
        } else {
          return this[key](value)
        }
      }
      this[key] = value
      delete this.opts[key]
    })

    this.updateText(text)
    cb?.(this)
  }

  offset(x, y = x) {
    if(x !== undefined && x !== null) this.offsetX = x
    if(y !== undefined && y !== null) this.offsetY = y
    return this
  }

  anchor(x, y = x) {
    if(x !== undefined && x !== null) this.anchorX = x
    if(y !== undefined && y !== null) this.anchorY = y
    return this
  }

  setCoordTranslateFunction(fn) {
    this.coordTranslateFunction = fn
    return this
  }

  updateText(newText) {
    this.text = newText
    return this.update()
  }

  updateOpts(opts = {}, doUpdate = "update") {
    Object.assign(this.opts, opts)
    return doUpdate ? this[doUpdate]() : this
  }

  update() {
    const m = this.liveMeasurement()
    m.spaceAbove = m.fontBoundingBoxAscent - m.actualBoundingBoxAscent
    m.spaceBelow = m.fontBoundingBoxDescent - m.actualBoundingBoxDescent
    m.height = m.fontBoundingBoxAscent + m.fontBoundingBoxDescent
    m.actualHeight = m.actualBoundingBoxAscent + m.actualBoundingBoxDescent

    const axa = this.anchorX
    const aya = 1 + this.anchorY
    if(axa) this.anchorOffsetX = m.width * axa
    if(aya) this.anchorOffsetY = (m.height - (this.anchorActual ? m.spaceAbove : 0)) * aya
    // if(this.anchorActual) console.log(m.actualHeight, m.height, m.actualHeight * aya, m.height * aya)

    this.measurement = m
    return this
  }
  updatePos(x, y) {
    let [tx, ty] = this.translateCoord(x, y)

    const m = this.measurement
    if(this.anchorOffsetX) tx += this.anchorOffsetX
    if(this.anchorOffsetY) ty += this.anchorOffsetY
    if(this.offsetX) tx += this.offsetX
    if(this.offsetY) ty += this.offsetY

    this.boundingBox = [tx, ty - this.measurement.actualBoundingBoxAscent, this.width, this.actualHeight]
    this.position = [tx, ty]
    return this
  }
  get width() { return this.measurement?.width }
  get spaceAbove() { return this.measurement?.spaceAbove }
  get spaceBelow() { return this.measurement?.spaceBelow }
  get height() { return this.measurement?.height }
  get actualHeight() { return this.measurement?.actualHeight }

  translated(what) { return this.translateCoord(this[what]) }

  translateCoord(x, y) {
    if(this.coordTranslateFunction) {
      return this.coordTranslateFunction(x, y)
    } else {
      return y === undefined ? x : [x, y]
    }
  }

  liveMeasurement() {
    return this.withContext(ctx => ctx.measureText(this.text))
  }

  withContext(cb) {
    if(this.inContext) return cb(this.ctx, this)

    try {
      this.ctx.save()
      this.inContext = true
      this.applyCtxOpts(this.opts)
      return cb(this.ctx, this)
    } finally {
      this.ctx.restore()
      this.inContext = false
    }
  }

  applyCtxOpts(opts = {}) {
    Object.entries(opts).forEach(([key, value]) => this.applyCtxOpt(key, value))
  }

  applyCtxOpt(key, value) {
    if (key == "lw") key = "lineWidth"
    if (key == "color") key = "fillStyle"
    if (key == "stroke") key = "strokeStyle"
    this.ctx[key] = value
  }

  draw(x, y, skipUpdate = false) {
    this.withContext(ctx => {
      if(!skipUpdate) this.update()
      if(x !== undefined && x !== null) this.updatePos(x, y)

      const doFill = this.alwaysFill || ![undefined, null, false, "transparent", "none"].includes(this.opts.color)
      const doStroke = this.alwaysStroke || ![undefined, null, false, "transparent", "none"].includes(this.opts.stroke)

      if (doStroke && !this.strokeAfterFill) this.ctx.strokeText(this.text, ...this.position)
      if (doFill) this.ctx.fillText(this.text, ...this.position)
      if (doStroke && this.strokeAfterFill) this.ctx.strokeText(this.text, ...this.position)
    })
  }

  redraw() {
    this.withContext(ctx => {
      ctx.clearRect(...this.boundingBox)
      this.draw(null, null, true)
    })
  }
}
