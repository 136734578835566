import { SimpleEvents } from "../../../appos/simple_events"

export class AssetLoader {
  constructor(parent, opts = {}) {
    SimpleEvents.applyOn(this, ["assetBeforeLoad", "assetLoad", "assetLoadFailed", "assetComplete"])
    this.parent = parent
    this.opts = Object.assign({}, {

    }, opts)
    this.registry = new Map()
  }

  get(...args) { return this.registry.get(...args) }

  add(klass, key, url, opts = {}) {
    let eventTarget = this
    if(opts.__eventTarget) {
      eventTarget = opts.__eventTarget
      delete opts.__eventTarget
    }

    const asset = new (klass ?? Asset)(this, key, url, opts)
    asset.on("beforeLoad", (...args) => eventTarget.fire("assetBeforeLoad", ...args))
    asset.on("load", (...args) => eventTarget.fire("assetLoad", ...args))
    asset.on("loadFailed", (...args) => eventTarget.fire("assetLoadFailed", ...args))
    asset.on("complete", (...args) => eventTarget.fire("assetComplete", ...args))
    this.registry.set(key, asset)
    return asset
  }
  json(...args) { return this.add(JsonDataAsset, ...args) }
  image(...args) { return this.add(ImageDataAsset, ...args) }

  group(key, ...args) {
    let url, opts, setter;
    while (args.length) {
      if(typeof args[0] == "function") setter = args.shift()
      else if(args[0] instanceof Object) opts = args.shift()
      else url = args.shift()
    }
    const asset = this.add(AssetGroup, key, url, opts)
    setter?.(asset, this)
    return asset
  }

  // l (key, asset, opts = {}) {}
}

export class GenericAsset {
  constructor(registry, key, url, opts) {
    SimpleEvents.applyOn(this, ["beforeLoad", "load", "loadFailed", "complete"])
    this.registry = registry
    this.key = key
    this.url = url
    this.firstLoadPending = true
    this.loading = false
    this.loaded = false
    this.failed = false
    this.promise = null
    this.dataProcessors = []
    this.opts = Object.assign({}, {
      processData: true,
    }, opts)
    this.init?.()
  }

  get available() { return !!this.url }
  addDataProcessor(f) { this.dataProcessors.push(f); return this }
  then(...args) { return this.promise.then(...args) }
  catch(...args) { return this.promise.catch(...args) }


  load(force = false) {
    if((this.loaded || this.loading) && !force && !this.firstLoadPending) return this.promise
    return this.loadData()
  }

  loadData() {
    this.loading = true
    this.fire("beforeLoad", this)
    this.firstLoadPending = false

    return this.promise = new Promise((resolve, reject) => {
      this.buildRequestPromise().then(r => this.processRequest(r)).then(blob => {
        this.loading = false
        this.failed = false
        this.loaded = true

        if(this.opts.processData) {
          let data = this.processData(this.parseData(blob))
          if (this.opts.mapValues) this.opts.mapValues.forEach(vn => this[vn] = data[vn])
          this.dataProcessors.forEach(f => data = f(data))
          this.data = data
        }

        this.fire("load", this.data, this)
        resolve(this.data)
      }).catch(e => {
        this.failed = e.status !== 404
        this.fire("loadFailed", this, e)
        console.error("failed to load asset", this, e)
        reject(e)
      }).finally(_ => {
        this.loading = false
        this.fire("complete", this.data, this)
      })
    })
  }

  buildRequestPromise() { return fetch(this.url) }
  processRequest(r) { return r.ok ? r.blob() : Promise.reject(r) }
  processData(d) { return d }
  parseData(d) { return d }
}

export class AssetGroup extends GenericAsset {
  init() {
    SimpleEvents.applyOn(this, ["assetBeforeLoad", "assetLoad", "assetLoadFailed", "assetComplete"])
    this.opts.processData = false
    this.children = new Map()
    this.data = {}
  }

  sub(k) { return this.children.get(k) }
  processRequest(r) { return r }
  buildRequestPromise() { return Promise.all(this.children.values().map(c => c.load())) }

  // loader API
  add(klass, key, url, opts = {}) {
    const pkey = this.key ? this.key + "/" + key : key
    const purl = this.url ? this.url + "/" + url : url
    opts.__eventTarget = this
    const asset = this.registry.add(klass, pkey, purl, opts)
    asset.on("load", data => this.data[key] = data)
    this.children.set(key, asset)
    return asset
  }
  json(...args) { return this.add(JsonDataAsset, ...args) }
  image(...args) { return this.add(ImageDataAsset, ...args) }
}



export class JsonDataAsset extends GenericAsset {
  processRequest(r) { return r.ok ? r.json() : Promise.reject(r) }
  get(k) { return this.data[k] }
}

export class ImageDataAsset extends GenericAsset {
  processRequest(r) { return r }

  buildRequestPromise() {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = this.url
      img.onload = () => resolve(img)
      img.onerror = (err) => reject(err)
    })
  }
}
