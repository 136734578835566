import * as AppOS from "../../appos"
import { createSilentAudio } from "../../lib/silent_audio"

const Component = class extends AppOS.Component {
  static name = "Sot__FlameheartForever"

  init() {
  }

  documentLoad() {
  }

  pageLoad() {
    $(`div[data-appos-controller="FlameheartForever"]`).each((i, el) => {
      const ff = new FlameheartForever(el)
      this.app.input?.onKeydownThisPage(ev => {
        if (ev.keyCode == 32) {
          ff.raudio.paused ? ff.start() : ff.pause()
          ev.preventDefault()
        }
      })
    })
  }
}

class FlameheartForever {
  static sounds = {
    battle_start: [789648821, 453832299, 53945778, 620853246, 495408201],
    taunt_approach: [69994167],
    join: [502960344, 487236382, 1068262944, 949598869, 697134635],
    join_legend: [375530161, 393386839, 309698898, 49136787, 224448339],
    flee: [223785708, 272652608, 770331185, 995938089, 403472392],
    return: [239711080, 443111659, 83927923, 74227184, 679384429],

    flag_ship: [545493782, 378425413, 304587746, 235928112, 1066736374],
    burning_blade_defeat: [617860196, 927480075, 610013394, 541554114, 1040472744],
    defeated: [428641329, 462245933, 907990759, 149066013, 390326316],
    sunk: [1029652949, 875178114, 268834787, 67664613, 896928348],

    summon_ship: [1003927507, 124465426, 148805173, 1922240, 473494167, 772050289, 313982279, 815295368, 796619799, 815920373, 1022230468, 341160490, 1071221754, 187418903, 649992216],
    summon_formation: [47617708, 355760965, 72332361, 356259190, 270765264],
    early_defeat: [655056383, 606412230, 429022147, 724097051, 968392292, 404678412, 564487069, 434945159, 455481263, 193821850],
    midway_defeat: [402270715, 241274250, 719567718, 260148405, 73317296, 40204957, 532332908, 68334976, 198790252, 38808464],
    end_defeat: [600990751, 900923320, 273224768, 507269405, 500301530, 147004358, 430460513, 1049791572, 340208919, 707294379],

    ghost_ship_hit: [144239159, 249919946, 441570727, 720536000, 72090972, 739624328, 437377872, 300202433, 37176739, 311136639, 923921637, 584491748, 932547121, 1025162349, 87890387],

    collide: [369837314, 469154041, 934938, 201522070, 307210196, 392682876, 415779784, 331672151, 357520732, 584231481],
    hit_burning: [527900449, 905646648, 257500603, 1030111514, 342238379, 133705608, 793644769, 968048519, 882427961, 421823912],
    hit_ghostly: [195257642, 218881629, 279981332, 491971699, 906729852, 947605204, 506992820, 300596063, 384955650, 709094322, 719665926, 163075661, 845455217, 1047906003, 454929286],
    hit_volley: [767819863, 549886489, 675817912, 252199160, 568059163],

    talk_angry: [78273379],
    talk_frustrated: [456853550],

    taunt_angry: [402383315, 999473380, 759917629, 385386454, 778498320, 796961619, 560732441, 1008743249, 505578574, 1053992707],
    taunt_confident: [222167605, 479470872, 642135771, 657483954, 409861489, 627523521, 992912173, 533475673, 730695265, 445880804],
    taunt_frustrated: [328626743, 895539766, 530782808, 836824296, 437263524, 876035533, 373965780, 775271230, 1064650223, 648875643],
  }

  static presets = {
    all: [],
    phase_1: [],
    phase_2: [],
    phase_3: [],
  }

  constructor(ctn) {
    this.ctn = $(ctn)
    this.fillQueue = 10
    this._enqueueNextImmediate = 0

    this.queue = this.ctn.find(`[data-role="queue"]`)
    this.player = this.ctn.find(`[data-role="player"]`)
    this.isPlaying = this.ctn.find(`[data-display="itemPlaying"]`)
    this.audio = this.ctn.find(`[data-role="player"] audio`)
    this.raudio = this.audio.get(0)
    this.s_delayBetweenSounds = this.ctn.find(`[data-setting="delayBetweenSounds"]`)
    this.s_autoEnqueueAll = this.ctn.find(`[data-setting="autoEnqueueAll"]`)
    this.s_autoEnqueue = this.ctn.find(`[data-setting="autoEnqueue"]`)

    this.queue.on("click", `[data-role="deleteItem"]`, ev => {
      const el = $(ev.currentTarget).closest("div[data-id]")
      if(el.hasClass("active")) {
        this.raudio.src = ""
        el.remove()
        this.playNextInQueue()
      } else {
        el.remove()
      }
      return false
    })

    this.ctn.on("click", `[data-role="clearPlaylist"]`, ev => {
      this.clearPlaylist()
      return false
    })

    this.ctn.on("click", `[data-role="endFight"]`, ev => {
      this.clearPlaylist()
      this.s_autoEnqueueAll.prop("checked", false)
      this.enqueueNextImmediate(2)
      this.enqueueRandom("burning_blade_defeat")
      this.enqueueRandom("defeated")
      return false
    })

    this.ctn.on("click", `[data-enqueue-random]`, ev => {
      const el = $(ev.currentTarget)
      this.enqueueRandom(el.data("enqueueRandom"))
      return false
    })

    this.audio.on("canplay", ev => {
      if(this.raudio.paused && this.hasEverPlayed) this.raudio.play()
    })

    this.audio.on("ended", ev => {
      this.queue.find("div.active").remove()
      const next = this.queue.find("div").eq(0)
      if(next.length && next.data("id") > 0 && !next.data("default")) {
        next.attr("data-default", true)
        let _delay = parseInt(this.s_delayBetweenSounds.val())
        if(isNaN(_delay)) _delay = 15
        this.prequeueDelay(_delay)
      }
      this.playNextInQueue()
    })

    this.audio.on("play", ev => {
      this.hasEverPlayed = true
    })

    this.audio.on("pause", ev => {
    })

    this.clearPlaylist(true)
    this.queueFillInterval = setTimeout(_ => { this.fillupQueue() }, 250)

    $(`[data-enqueue-random]`).each((i, el) => {
      const t = this.constructor.sounds[$(el).data("enqueueRandom")]
      if(!t.length) return
      if (t.length > 1) {
        $(el).attr("title", `enqueue random out of ${t.length}`)
      } else {
        $(el).attr("title", `enqueue`)
      }
      if($(el).next("label").length) {
        $(el).next("label").attr("title", `${t.length} sound${t.length == 1 ? "" : "s"} in ${$(el).data("enqueueRandom")}`)
      }
    })
  }

  start(ev) {
    if(this.audio.attr("src") == "") {
      this.playNextInQueue()
    } else {
      this.raudio.play()
    }

    return false
  }

  pause(ev) {
    this.raudio.pause()
    return false
  }

  enqueue(id, name, index = null, prependInstead = false) {
    let fname = name
    if(index != null) fname += `/${index}`
    const el = $("<div>").addClass("d-flex mb-1 align-items-center").html(fname).attr("data-id", id).attr("data-name", fname)
    if(this._enqueueNextImmediate > 0) {
      el.attr("data-default", true)
      this._enqueueNextImmediate -= 1
    }
    $("<a>").attr("href", "").attr("data-role", "deleteItem").html("x").addClass("btn btn-sm btn-danger me-3").prependTo(el)
    prependInstead ? el.prependTo(this.queue) : el.appendTo(this.queue)
    if(this.audio.attr("src") == "") { this.playNextInQueue() }
  }

  enqueueRandom(...args) {
    const all = []
    args.forEach(arg => {
      this.constructor.sounds[arg].forEach((snd, i) => { all.push([snd, arg, i]) })
    })
    if(!all.length) return
    const sel = all[Math.floor(Math.random() * all.length)]
    this.enqueue(sel[0], sel[1], sel[2])
  }

  enqueueDelay(s) {
    this.enqueue(s * -1, `silence (${s}s)`)
  }

  prequeueDelay(s) {
    this.enqueue(s * -1, `silence (${s}s)`, null, true)
  }

  fillupQueue() {
    if(!this.fillQueue) return
    if(this.hasEverPlayed && this.s_autoEnqueueAll.prop("checked")) {
      const items = this.queue.find("div")
      const delta = this.fillQueue - items.length
      if(delta > 1) {
        const enabled = []
        this.s_autoEnqueue.map((i, el) => { if($(el).prop("checked")) enabled.push($(el).attr("name")) })
        this.enqueueRandom.apply(this, enabled)//("summon_ship", "summon_formation", "early_defeat", "midway_defeat", "end_defeat", "ghost_ship_hit", "collide", "hit_burning", "hit_ghostly", "hit_volley", "talk_angry", "talk_frustrated", "taunt_angry", "taunt_confident", "taunt_frustrated")
      }
    }
    this.queueFillInterval = setTimeout(_ => { this.fillupQueue() }, 250)
  }

  clearPlaylist(withDefaults = false) {
    this.queue.find("div").remove()
    this.raudio.src = ""
    if(withDefaults) this.defaultPlaylist()
  }

  enqueueNextImmediate(howMany) {
    this._enqueueNextImmediate += howMany
    return this
  }

  defaultPlaylist() {
    this.enqueueNextImmediate(3)
    this.enqueueRandom("taunt_approach", "join", "join_legend")
    this.enqueueDelay(5)
    this.enqueueRandom("battle_start")
    this.playNextInQueue()
  }

  playNextInQueue() {
    const el = this.queue.find("div").eq(0)
    if(el.length) {
      el.addClass("active")
      const id = $(el).data("id")
      this.isPlaying.html($(el).data("name"))
      if(id < 0) {
        this.raudio.src = createSilentAudio(id * -1)
      } else {
        this.raudio.src = `https://soundtag.geekya.com/blob/${id}.m4a`
      }
    } else {
      this.isPlaying.html("<em>empty</em>")
      this.pause()
    }
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
