import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Sot__CannonMetrics"

  init() {
  }

  documentLoad() {
    this.keyboardControls()
  }

  pageLoad() {
    $(`div[data-appos-controller="SotCannonMetrics"]`).each((i, el) => {
      this.metrics = new CannonMetrics(this, el)
    })
  }

  keyboardControls() {
    document.addEventListener("keydown", ev => {
      if(!$(`div[data-appos-controller="SotCannonMetrics"]`).length) return
      if(ev.target.tagName == "INPUT" && ev.target.type == "text") return

      if(ev.keyCode == 32) { // space = toggle horizon
        ev.preventDefault()
        this.metrics?.c_comp?.imageComparisonSlider?.swapMode()
      } else if(!ev.ctrlKey && !ev.metaKey && (ev.keyCode == 37 || ev.keyCode == 38)) { // arrow left / up = slider left
        ev.preventDefault()
        this.metrics?.c_comp?.imageComparisonSlider?.sliderStepLeft(ev.shiftKey ? 10 : 1)
      } else if(!ev.ctrlKey && !ev.metaKey && (ev.keyCode == 39 || ev.keyCode == 40)) { // arrow up / right = slider left
        ev.preventDefault()
        this.metrics?.c_comp?.imageComparisonSlider?.sliderStepRight(ev.shiftKey ? 10 : 1)
      } else if((ev.keyCode == 49 && ev.shiftKey) || ev.keyCode == 97) { // shift+1 || numpad1
        ev.preventDefault()
        this.metrics?.e_left?.toggle?.()
      } else if((ev.keyCode == 50 && ev.shiftKey) || ev.keyCode == 98) { // shift+2 || numpad2
        ev.preventDefault()
        this.metrics?.e_right?.toggle?.()
      } else if(ev.keyCode == 36 || ev.keyCode == 35 || ev.keyCode == 33 || ev.keyCode == 34) {
        // home = left-oneUp / end = left-oneDown / page-up = right-oneUp / page-down = right-oneDown
        ev.preventDefault()
        const dir = ev.keyCode == 36 || ev.keyCode == 35 ? "left" : "right"
        const sib = ev.keyCode == 36 || ev.keyCode == 33 ? "previous" : "next"
        const select = this.metrics && this.metrics[`e_${dir}`]?.i_value
        if(select) {
          const sopt = select.selectedOptions[0]
          const sibling = sopt[`${sib}ElementSibling`]
          if(sibling?.value) {
            select.value = sibling.value
            select.dispatchEvent(new Event("change", {"view": window, "bubbles": true }))
          } else {
            const outer = this.metrics[`d_${dir}`]
            outer.classList.remove("flash-danger")
            outer.offsetWidth
            outer.classList.add("flash-danger")
          }
        }
      }
    })
  }
}

class CannonMetrics {
  constructor(component, container) {
    this.component = component
    this.container = container
    const directions = ["left", "right"]
    let lastCannon = null

    this.c_comp = container.querySelector(`.image-comparison-slider`)
    directions.forEach(dir => {
      this[`i_${dir}`] = this.c_comp.querySelector(`.${dir}-image`)
      this[`c_${dir}`] = container.querySelector(`[data-cannon-selector="${dir}"]`)
      this[`d_${dir}`] = this[`c_${dir}`].querySelector(`[role="cannon-metrics-display"]`)
      this[`e_${dir}`] = this[`c_${dir}`].bootstrapElect

      // update when elect updates select
      this[`e_${dir}`].i_value.addEventListener("change", ev => {
        const opt = ev.target.selectedOptions[0]
        if(opt.dataset.image) {
          this[`i_${dir}`].src = opt.dataset.image
          this.updatePageUrl()
          this.updateDisplayElement(this[`d_${dir}`], opt)
        }
      })

      // toggle elect
      this[`d_${dir}`].addEventListener("click", ev => {
        if(!ev.target.closest(`a`)) {
          ev.preventDefault()
          this[`e_${dir}`].toggle()
        }
      })

      // select selected or random (and prevent chosing same for left & right)
      let selectedOption = this[`e_${dir}`].i_value.selectedOptions[0]
      let randomlySelected = false
      if(!selectedOption.value) {
        randomlySelected = true
        const opts = Array.from(this[`e_${dir}`].i_value.options).filter(el => el.value)
        let pick = lastCannon
        while((pick == null || pick == lastCannon) && opts.length > 1) {
          pick = opts[Math.floor(Math.random() * opts.length)]
        }
        selectedOption = pick
      }
      lastCannon ??= selectedOption

      // initially set display
      if(selectedOption.dataset.image) {
        this[`i_${dir}`].src = selectedOption.dataset.image
      }
      this.updateDisplayElement(this[`d_${dir}`], selectedOption, randomlySelected)
    })
  }

  updateDisplayElement(del, opt, randomlySelected = false) {
    const img = del.querySelector(`[data-v="image"]`)
    const name = del.querySelector(`[data-v="name"]`)
    const item_db = del.querySelector(`[data-l="item-db"]`)
    const sot_wiki = del.querySelector(`[data-l="sot-wiki"]`)
    img.src = `${opt.dataset.image}`
    name.textContent = name.title = img.title = opt.textContent
    if(randomlySelected) {
      const span = document.createElement("span")
      span.textContent = " (randomly selected)"
      span.classList.add("text-muted", "text-smol")
      name.append(span)
    }
    item_db.href = `/games/sot/items/${opt.value}`
    sot_wiki.href = item_db.href + `/wiki`
  }

  updatePageUrl(left, right) {
    left ??= this.e_left.i_value.value
    right ??= this.e_right.i_value.value
    let url = window.location.pathname
    if(left || right) url += "?"
    if(left) url += `left=${left}`
    if(left && right) url += "&"
    if(right) url += `right=${right}`
    window.history.pushState({ left, right }, "", url)
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
