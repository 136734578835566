import { WorldLayer } from "../world_layer"

export class IslandCircles extends WorldLayer {
  init() {
    this.dependsOnIslands()
    this.mayRenderAsync = true

    this.optreg.add("int", "thickness", 1)

    const rings = [
      ["bounds", true, "magenta"],
      ["safe", true, "green"],
      ["trigger", true, "orange"],
      ["diving_exclusion", true, "darkblue"],
    ]

    rings.forEach(([name, doRender, color]) => {
      this.optreg.add("bool", `doRender.${name}`, doRender)
      this.optreg.add("str",  `colors.${name}`, color).desync()
    })
  }

  onEnable() {
    this.prepareRandomDashLayout(20, 11, 18, 7, 13)
  }

  render() {
    if(!this.enabled) return
    this.clear()

    this.trx(ctx => {
      ctx.setLineDash(this.lineDashLayout.seq)
      const clampedScale = Math.min(3, Math.max(1, this.world.vscale))
      ctx.lineWidth = Math.max(1, this.o("thickness") * clampedScale)
      const doRender = this.o("doRender")
      const colors = this.o("colors")

      this.world.islands.inView.forEach(island => {
        ctx.lineDashOffset = 0
        if(doRender.get("bounds")) this.drawCircle(island.position, parseFloat(island.boundsRadius), { color: colors.get("bounds") })
        ctx.lineDashOffset = this.lineDashLayoutLength * 0.25
        if(doRender.get("safe")) this.drawCircle(island.position, parseFloat(island.safeRadius), { color: colors.get("safe") })
        ctx.lineDashOffset = this.lineDashLayoutLength * 0.5
        if(doRender.get("trigger")) this.drawCircle(island.position, parseFloat(island.triggerRadius), { color: colors.get("trigger") })
        ctx.lineDashOffset = this.lineDashLayoutLength * 0.75
        if(doRender.get("diving_exclusion")) this.drawCircle(island.position, parseFloat(island.diveExclusionRadius), { color: colors.get("diving_exclusion") })
      })
    })
  }
}
