import { WorldLayer } from "../world_layer"

export class Searocks extends WorldLayer {
  init() {
    this.enableInitially = false
    this.mayRenderAsync = true

    this.optreg.add("int",  "thickness", 1)
    this.optreg.add("bool", "doRender.detail", false)
    this.optreg.add("bool", "doRender.oversea", true)
    this.optreg.add("bool", "doRender.undersea", false)
    this.optreg.add("str", "colors.oversea", "rgba(255, 0, 0, 0.7)").desync()
    this.optreg.add("str", "colors.undersea", "rgba(0, 0, 139, 0.7)").desync()

    this.searocks = this.assets.json("searocks.json", this.world.world.data("fetchSearocks")).on("load", _ => this.searocksLoaded())
  }

  onEnable() {
    this.load()
  }

  load() {
    return this.searocks.load()
  }

  searocksLoaded() {
    ["oversea", "undersea"].forEach(key => {
      let min = null
      let max = null
      this.searocks.get(key).forEach(el => {
        if(min === null || el.r < min) min = el.r
        if(max === null || el.r > max) max = el.r
      })
      this.searocks.data[`${key}MinMax`] = [min, max]
      this.searocks.get(key).forEach(el => el.radrat = el.r / max)
    })
    this.update()
  }

  update() {
    this.czoomAlpha = 1 - this.zoomAlpha(2, 15, "easeOutQuart")
    super.update()
  }

  render() {
    if(!this.enabled) return
    this.clear()

    if(this.searocks?.loaded && this.o("doRender.undersea")) this._renderUndersea()
    if(this.searocks?.loaded && this.o("doRender.oversea")) this._renderOversea()
  }

  _renderOversea() {
    this.trx(ctx => {
      const clampedScale = Math.min(3, Math.max(1, this.world.vscale))
      ctx.lineWidth = Math.max(1, this.o("thickness") * clampedScale)
      const color = this.o("colors.oversea")

      this.searocks.get("oversea").forEach((el, i) => {
        this.drawCircle([el.x, el.y], el.r, { color, fill: el.r > 45000 ? "rgba(255, 0, 0, 0.3)" : "none" })

        if(this.o("doRender.detail") && el.radrat > this.czoomAlpha) {
          this.drawX([el.x, el.y], el.r * 0.5, false, { color })
          el.detailText ??= this.createText(`i:${i} r:${el.r.toFixed(2)}`, { offsetY: -6, anchor: [-0.5, -1] })
          if(el.r * 4 < el.detailText.translated("width")) return false
          // el.detailText.opts.font = `${this.zoomVec(8, 1.5, 5)}px windlass-extended`
          el.detailText.opts.font = `${this.zoomVec(8, 3.5, 8)}px sans-serif`
          el.detailText.draw(el.x, el.y)
        }
      })
    })
  }

  _renderUndersea() {
    this.trx(ctx => {
      const clampedScale = Math.min(3, Math.max(1, this.world.vscale))
      ctx.lineWidth = Math.max(1, this.o("thickness") * clampedScale)
      const color = this.o("colors.undersea")

      this.searocks.get("undersea").forEach((el, i) => {
        this.drawCircle([el.x, el.y], el.r, { color, fill: el.r > 45000 ? "rgba(0, 0, 255, 0.3)" : "none" })

        if(this.o("doRender.detail") && el.radrat > this.czoomAlpha) {
          this.drawX([el.x, el.y], el.r * 0.5, false, { color })
          el.detailText ??= this.createText(`i:${i} r:${el.r.toFixed(2)}`, { offsetY: -6, anchor: [-0.5, -1], color })
          if(el.r * 4 < el.detailText.translated("width")) return false
          // el.detailText.opts.font = `${this.zoomVec(8, 1.5, 5)}px windlass-extended`
          el.detailText.opts.font = `${this.zoomVec(8, 3.5, 8)}px sans-serif`
          el.detailText.draw(el.x, el.y)
        }
      })
    })
  }
}
