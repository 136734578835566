import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "Copy2Clipboard"

  documentLoad() {
    $(document).on("click", `[data-action="copy2clipboard"]`, ev => {
      const t = $(ev.currentTarget)
      const ti = t.find("i")
      const tv = t.find("span")
      const ct = t.data("copyTarget")
      const cv = t.data("copyValue")
      if(t.data("originalTv") === undefined) {
        t.data("originalTv", tv.text())
      }
      const vctn = ct ? t.closest(ct) : t.parent()
      const vel = vctn.find(cv ?? "input").eq(0)

      navigator.clipboard.writeText(vel.val())
      .then(_ => {
        tv.text(" copied!")
        t.addClass("btn-outline-success")
      })
      .catch(err => {
        tv.text(" failed!")
        t.addClass("btn-outline-danger")
      })

      setTimeout(_ => {
        tv.text(t.data("originalTv"))
        t.removeClass("btn-outline-danger btn-outline-success")
      }, 2500)
      setTimeout(_ => { t.blur() }, 150)
      return false
    })
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
