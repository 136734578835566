import * as AppOS from "./appos"

class Application {
  static availableComponents = []

  static boot(opts) {
    return AppOS.boot(new this(opts))
  }

  static DEFAULT_EVENTS = [
    "init",
    "boot",
    "documentLoad",
    "pageLoad",
  ]

  static DEFAULT_OPTIONS = {
    instance: null,
    init: null,
    debug: false,
    data: {},
    turbolinks: false,
    turbo: false,
    defaultBoot: app => {
      if (window.Turbo || window.Turbolinks) {
        document.addEventListener('DOMContentLoaded', _ => app.fire("documentLoad", app), { once: true })
        if(window.Turbo) {
          app.debug("detected Turbo")
          document.addEventListener('turbo:load', _ => app.fire("pageLoad", app), false)
        }
        if(window.Turbolinks) {
          app.debug("detected Turbolinks")
          document.addEventListener('turbolinks:load', _ => app.fire("pageLoad", app), false)
        }
      } else {
        document.addEventListener('DOMContentLoaded', _ => {
          app.fire("documentLoad", app)
          app.fire("pageLoad", app)
        }, false)
      }
    }
  }

  constructor(opts = {}) {
    this.app = this
    this.loadedComponents = []
    this.opts = Object.assign({}, this.constructor.DEFAULT_OPTIONS, opts)

    // namespaced console
    AppOS.NamespacedConsole.applyOn(this, {
      debugConfig: this.opts,
      namespaces: ["AppOS", [this, "instance"]],
    })

    // debugger
    this.dev = new AppOS.Debugger(this).getProxy()

    // register events
    AppOS.SimpleEvents.applyOn(this, this.constructor.DEFAULT_EVENTS, this.opts)

    this.constructor.DEFAULT_EVENTS.forEach(efn => {
      if(typeof this[efn] == "function") this.on(efn, this[efn])
    })
  }

  use(imp, opts) {
    const ins = new imp(this, opts ?? this.opts[imp.name])
    this.debug("using component ", ins.name)
    this.loadedComponents.push(ins)
    ins.init?.()
  }

  get components() {
    const res = {}
    this.loadedComponents.forEach(cins => {
      res[cins.name] = cins
    })
    return res
  }

  // ===============
  // = API methods =
  // ===============
  // init() {}
  // boot() {}
  // documentLoad() {}
  // pageLoad() {}
}

export { Application }
