import * as AppOS from "../appos"

import { World } from "./sot_world/world"

const Component = class extends AppOS.Component {
  static name = "SotWorld"

  documentLoad() {
    $(document).keydown(ev => {
      if(!this.world) return
      return this.world.handleKeydown?.(ev)
    })
    $(document).keyup(ev => {
      if(!this.world) return
      return this.world.handleKeyup?.(ev)
    })
  }

  pageLoad() {
    const world = $("#sot-world")
    if(!world.length) return
    window.sotworld = this.world = new World(world).restoreFromCanonicalUrl()
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
