import { WorldLayer } from "../world_layer"

export class IslandNames extends WorldLayer {
  init() {
    this.enableInitially = true
    this.mayRenderAsync = true
    this.islandsReady = false
    this.world.islands.oneIslandEverInView.then(_ => {
      this.islandsReady = true
      this.update()
    })

    this.optreg.add("str", "nameColor", "#222d").desync()
    this.optreg.add("str", "nameColorHover", "#111f").desync()

    this.world.cursor.addFilter(this, "findIslandFromNameUnderPointer", { hoverCursor: "pointer", onHover: (island, ev, c) => {
      island.nameText.updateOpts({ color: this.o("nameColorHover") }, "redraw")
    }, onBlur: (island, ev, c) => {
      island.nameText.updateOpts({ color: this.o("nameColor") }, "redraw")
    }, onClick: (island, ev, c) => {
      island.centerInViewport("auto", { animate: false })
      return false
    }})
  }

  findIslandFromNameUnderPointer(ev) {
    if(!this.enabled) return
    if(this.world.panzooming && this.world.mouseDeltaThresholdAny(1)) return
    const point = [ev.clientX - this.worldParentRect.left, ev.clientY - this.worldParentRect.top]

    for(const island of this.world.islands.inView) {
      if(!island.nameText?.clickBox) continue
      if(this.pointIntersectBox(point, island.nameText.clickBox)) {
        return island
      }
    }
  }

  render() {
    if(!this.enabled) return
    if(!this.islandsReady) return
    this.clear()

    this.trx(ctx => {
      this.world.islands.inView.forEach(island => {
        island.nameText ??= this.createText(island.name, { anchor: [-0.5, -2], color: this.o("nameColor") })
        island.nameText.opts.font = `${this.zoomVec(8, 1.5, 5)}px windlass-extended`
        island.nameText.update()

        const w = island.activeResolution?.w ?? island.boundsRadius
        island.nameText.draw(island.position[0], island.position[1] - (w/2), true)
        island.nameText.clickBox = this.padBox(island.nameText.boundingBox, island.nameText.actualHeight / 2)
      })
    })
  }
}
