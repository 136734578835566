import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "NeighbourPlanner"

  init() {
  }

  documentLoad() {
  }

  pageLoad() {
    const ctn = $("#neighbour-planner")
    if(!ctn.length) return

    this.planner = new NeighbourMatrix(ctn, ctn.data("sx"), ctn.data("sy"))
    .addOption("Basilikum", "Oregano Petersilie Kresse", "Tomate Kartoffel Radieschen")
    .addOption("Blüten", "Petersilie Dill Basilikum", "Tomate Gurke Rettich")
    .addOption("Bohnenkraut", "Thymian Salbei Oregano", "Pfefferminze Basilikum")
    .addOption("Dill", "Schnittlauch Majoran Kresse", "Petersilie Kümmel Fenchel")
    .addOption("Gartenkresse", "Tomate Gurke Karotte", "Salat")
    .addOption("Liebstöckel", "Thymian Fenchel Kerbel", "Borretsch Rucola Senf")
    .addOption("Majoran", "Petersilie Kresse Dill", "Thymian Oregano Fenchel")
    .addOption("Oregano", "Basilikum Bohnenkraut Salbei", "Majoran Thymian")
    .addOption("Petersilie", "Schnittlauch Tomate Oregano", "Karotte Dill Fenchel")
    .addOption("Rosmarin", "Basilikum Thymian Salbei", "Schnittlauch Senf")
    .addOption("Salbei", "Thymian Zitronenmelisse YSOP", "Basilikum Gurke Pfefferminze")
    .addOption("Schnittlauch", "Zitronenmelisse Petersilie Dill", "Bohne Knoblauch Kresse")
    .addOption("Thymian", "Salbei Bohnenkraut Zitronenmelisse", "Pfefferminze Majoran Ringelblume")
    .addOption("Zitronenmelisse", "Thymian Schnittlauch Salbei", "Basilikum Melisse")
    // .addOption("", "", "")
    .updateSlotBorders()

    if(ctn.data("fill") == "random") {
      this.planner.ovp.find(`a[data-opt="random"]`).click()
    }
  }
}

class NeighbourMatrix {
  constructor(ctn, sx, sy) {
    this.ctn = ctn
    this.vp = ctn.find(".viewport")
    this.ovp = ctn.find(".options")
    this.matrix = []
    this.options = {}
    this.initMatrix(sx, sy)

    this.ctn.find(`a[data-role="bf_start"]`).click(ev => {
      if (this.forcing) {
        clearInterval(this.forcing)
        this.forcing = null
        for (var y = 0; y < this.bf_best_m.length; y++) {
          for (var x = 0; x < this.bf_best_m[y].length; x++) {
            this.setSlot({x, y}, this.bf_best_m[y][x])
          }
        }
        this.updateSlotBorders()
      } else {
        this.bf_iter = 0
        this.bf_best_g = -1
        this.bf_best_r = -1
        this.bf_best_m = this.cloneMatrix()
        this.forcing = setInterval(_ => {
          this.ctn.find(`[data-val="bf_iter"]`).html(this.bf_iter++)
          const p1 = {
            x: this.randomIntFromInterval(0, this.matrix[0].length - 1),
            y: this.randomIntFromInterval(0, this.matrix.length - 1),
          }
          let p2 = { x: p1.x, y: p1.y }
          while (p1.x == p2.x && p1.y == p2.y) {
            p2 = {
              x: this.randomIntFromInterval(0, this.matrix[0].length - 1),
              y: this.randomIntFromInterval(0, this.matrix.length - 1),
            }
          }
          this.swap(p1, p2)
          const gc = this.vp.find(".isGood[data-border]").length
          const rc = this.vp.find(".isBad[data-border]").length
          if ((gc > this.bf_best_g && (rc <= this.bf_best_r || this.bf_best_r < 0)) || (rc < this.bf_best_r || this.bf_best_r < 0)) {
            console.log(gc, rc)
            this.bf_best_m = this.cloneMatrix()
            this.ctn.find(`[data-val="bf_best_d"]`).html(`${new Date().toISOString()} // ${this.bf_iter}`)
            this.bf_best_g = gc
            this.ctn.find(`[data-val="bf_best_g"]`).html(gc)
            this.bf_best_r = rc
            this.ctn.find(`[data-val="bf_best_r"]`).html(rc)
          }
        }, 10)
      }
      return false
    })

    this.ctn.on("click", "[data-x]", (ev) => {
      const t = $(ev.currentTarget)
      const s = this.ctn.find(".selected[data-x]")
      if(s.length && t.length) {
        if (s.get(0) == t.get(0)) {
          s.toggleClass("selected")
        } else {
          s.removeClass("selected")
          this.swap(
            new NeighbourPoint(t.data("x"), t.data("y")),
            new NeighbourPoint(s.data("x"), s.data("y"))
          )
        }
      } else {
        t.toggleClass("selected")
      }
    })

    this.ctn.on("dblclick", "[data-x]", (ev) => {
      const t = $(ev.currentTarget)
      this.setSlot(new NeighbourPoint(t.data("x"), t.data("y")))
      this.updateSlotBorders()
      return false
    })

    this.ovp.on("click", "a", (ev) => {
      const t = $(ev.currentTarget)
      if(t.data("opt") == "all") {
        this.ovp.find(`a:not([data-opt="all"]):not([data-opt="random"]):not([data-opt="clear"])`).click()
        return false
      }
      if(t.data("opt") == "clear") {
        this.clearMatrix()
        return false
      }
      if(t.data("opt") == "random") {
        const avail = Object.values(this.options)
        let slot = false
        while (slot = this.firstEmptySlot()) {
          this.setSlot(slot, avail[this.randomIntFromInterval(0, avail.length - 1)])
        }
        this.updateSlotBorders()
        return false
      }
      const pos = this.firstEmptySlot()
      if(pos) {
        this.setSlot(pos, t.data("opt"))
        this.updateSlotBorders()
      } else {
        console.error("cannot add option to grid, grid full!")
      }
      return false
    })
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  clearMatrix() {
    for (var y = 0; y < this.matrix.length; y++) {
      for (var x = 0; x < this.matrix[y].length; x++) {
        this.setSlot({x, y})
      }
    }
    return this.updateSlotBorders()
  }

  cloneMatrix() {
    const res = []
    for (var y = 0; y < this.matrix.length; y++) {
      res.push([])
      for (var x = 0; x < this.matrix[y].length; x++) {
        res[y][x] = this.matrix[y][x]
      }
    }
    return res
  }

  initMatrix(sx, sy) {
    this.vp.find(".np-col").remove()
    this.vp.find(".np-row").remove()

    for (var y = 0; y < sy; y++) {
      this.matrix.push([])
      const rowEl = $("<div>").addClass("np-row").attr("data-y", y).appendTo(this.vp)
      for (var x = 0; x < sx; x++) {
        this.matrix[y].push(undefined)
        const fakeEl = $("<div>").addClass("np-col").attr("data-y", y).attr("data-x", x).appendTo(rowEl)

        $("<div>").attr("data-border", "top").appendTo(fakeEl)
        $("<div>").attr("data-border", "right").appendTo(fakeEl)
        $("<div>").attr("data-border", "bottom").appendTo(fakeEl)
        $("<div>").attr("data-border", "left").appendTo(fakeEl)

        const colEl = $("<div>").addClass("fake").appendTo(fakeEl)
        $("<div>").attr("data-val", "name").appendTo(colEl)
        const colRow = $("<div>").addClass("np-colrow d-flex flex-column").appendTo(colEl)
        $("<ul>").addClass("text-success").attr("data-val", "positive-list").appendTo(colRow)
        $("<ul>").addClass("text-danger").attr("data-val", "negative-list").appendTo(colRow)
      }
    }
  }

  setSlot(p, opt) {
    const col = this.vp.find(`[data-x=${p.x}][data-y=${p.y}]`)
    if(col.length) {
      this.matrix[p.y][p.x] = opt
      opt ? col.attr("data-name", opt.name) : col.removeAttr("data-name")
      opt ? col.attr("data-positive", opt.positive) : col.removeAttr("data-positive")
      opt ? col.attr("data-negative", opt.negative) : col.removeAttr("data-negative")
      col.find(`[data-val="name"]`).text(opt ? opt.name : "")

      const pl = col.find(`[data-val="positive-list"]`)
      const nl = col.find(`[data-val="negative-list"]`)
      pl.find("li").remove()
      nl.find("li").remove()
      if (opt) {
        opt.positive?.forEach(item => {
          $("<li>").text(item).appendTo(pl)
        })
        opt.negative?.forEach(item => {
          $("<li>").text(item).appendTo(nl)
        })
      }
    } else {
      throw "could not find data point in matrix", p
    }
  }

  updateSlotBorders() {
    this.matrix.forEach((row, y) => {
      row.forEach((col, x) => {
        this.slotBorders({x, y})
      })
    })
    return this
  }

  getSibling(p, edge) {
    switch (edge) {
      case "left":
        return p.x > 0 ? this.matrix[p.y][p.x - 1] : undefined
      case "right":
        return p.x < this.matrix[0].length - 1 ? this.matrix[p.y][p.x + 1] : undefined
      case "top":
        return p.y > 0 ? this.matrix[p.y - 1][p.x] : undefined
      case "bottom":
        return p.y < this.matrix.length - 1 ? this.matrix[p.y + 1][p.x] : undefined
      default:
        throw("unknown edge", edge)
    }
  }

  slotBorders(p) {
    const col = this.vp.find(`[data-x=${p.x}][data-y=${p.y}]`)
    if(!col.length) {
      throw "could not find data point in matrix", p
    }
    const me = this.matrix[p.y][p.x]
    if(me) {
      col.find(`[data-border]`).removeClass("isGood isBad")

      this.setPositionedBorder(col, p, "left")
      this.setPositionedBorder(col, p, "right")
      this.setPositionedBorder(col, p, "top")
      this.setPositionedBorder(col, p, "bottom")
    } else {
      col.find(`[data-border]`).removeClass("isGood isBad")
    }
  }

  setPositionedBorder(col, p, edge) {
    const me = this.matrix[p.y][p.x]
    const sibling = this.getSibling(p, edge)
    if(!sibling) return

    if (me.negative.includes(sibling.name))
      col.find(`[data-border="${edge}"]`).addClass("isBad")
    else if (me.positive.includes(sibling.name))
      col.find(`[data-border="${edge}"]`).addClass("isGood")
  }

  addOption(opt, positive = [], negative = []) {
    if(this.options[opt]) {
      console.warn("Option already defined, just adding to grid")
    } else {
      this.options[opt] = new NeighbourOption(opt, positive, negative)
      $("<a>").attr("href", "#").data("opt", this.options[opt]).text(opt).appendTo(this.ovp)
    }
    return this
  }

  firstEmptySlot() {
    for (var y = 0; y < this.matrix.length; y++) {
      for (var x = 0; x < this.matrix[y].length; x++) {
        const col = this.matrix[y][x]
        if (typeof col == "undefined" || col == null) {
          return new NeighbourPoint(x, y)
        }
      }
    }
    return null
  }

  swap(p1, p2) {
    const temp = this.matrix[p1.y][p1.x]
    this.setSlot(p1, this.matrix[p2.y][p2.x])
    this.setSlot(p2, temp)
    return this.updateSlotBorders()
  }

  select(p) {

  }
}

class NeighbourPoint {
  constructor(x, y) {
    this.x = x
    this.y = y
  }
}

class NeighbourOption {
  constructor(name, positive, negative) {
    this.name = name
    this.positive = Array.isArray(positive) ? positive : positive.split(" ")
    this.negative = Array.isArray(negative) ? negative : negative.split(" ")
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
