import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "Keyboard"

  static DEFAULT_OPTIONS = {
    debug: false,
    as: "input",
    monitorModKeys: true,
    classModKeys: false,
  }

  init() {
    this.app[this.opts.as] = this
    this.html = document.querySelector("html")
    this.modKeys = {
      held: 0,
      shift: false,
      ctrl: false,
      alt: false,
      meta: false,
    }
  }

  documentLoad() {
    this.pageEvents = { down: [], up: [] }
    document.addEventListener('keydown', ev => {
      this._updateModKeys(ev)
      this.pageEvents.down.forEach(h => h(ev))
    })

    document.addEventListener('keyup', ev => {
      this._updateModKeys(ev)
      this.pageEvents.up.forEach(h => h(ev))
    })
  }

  pageLoad() {
    this.pageEvents = { down: [], up: [] }
    this.html = document.querySelector("html")
  }

  onKeydownThisPage(cb) {
    this.pageEvents.down.push(cb)
    return this
  }

  _updateModKeys(ev) {
    if(!this.opts.monitorModKeys) return
    this._updateModKey(ev, "shift")
    this._updateModKey(ev, "ctrl")
    this._updateModKey(ev, "alt")
    this._updateModKey(ev, "meta")
  }

  _updateModKey(ev, key) {
    if(ev[`${key}Key`] && !this.modKeys[key]) {
      this.modKeys.held += 1
      this.modKeys[key] = true
      if (this.opts.classModKeys) {
        this.html.classList.toggle(`kb-mod`, true)
        this.html.classList.toggle(`kb-mod-${key}`, true)
      }
    } else if(!ev[`${key}Key`] && this.modKeys[key]) {
      this.modKeys.held -= 1
      this.modKeys[key] = false
      if(this.opts.classModKeys) {
        this.html.classList.toggle(`kb-mod`, this.modKeys.held > 0)
        this.html.classList.toggle(`kb-mod-${key}`, false)
      }
    }
  }

  applyModKeys(ev) {
    if(ev.shiftKey) {
      this.modKeys.shift = ev.shiftKey
      this.modKeys.held += ev.shiftKey ? 1 : -1
    }

    if(ev.ctrlKey) {
      this.modKeys.ctrl = ev.ctrlKey
      this.modKeys.held += ev.ctrlKey ? 1 : -1
    }

    if(ev.metaKey) {
      this.modKeys.meta = ev.metaKey
      this.modKeys.held += ev.metaKey ? 1 : -1
    }

    if(ev.altKey) {
      this.modKeys.alt = ev.altKey
      this.modKeys.held += ev.altKey ? 1 : -1
    }

    return res
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }


// <template appos-page-load><script>
//   env.app.input?.onKeydownThisPage(ev => {
//     if(ev.keyCode == 70 && (ev.metaKey || ev.ctrlKey) && ev.altKey) {
//       $("#q_n").focus().select()
//       ev.preventDefault()
//     }
//   })
// </script></template>
