import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Bootstrap.Tooltips"

  init() {
  }

  pageLoad() {
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
      new bootstrap.Tooltip(el)
    })
    document.querySelectorAll('.help[title]').forEach(el => {
      new bootstrap.Tooltip(el)
    })
    document.querySelectorAll('.delayed-help[title]').forEach(el => {
      new bootstrap.Tooltip(el, { delay: { show: 500, hide: 75 } })
    })
    document.querySelectorAll('abbr[title]').forEach(el => {
      new bootstrap.Tooltip(el, { delay: { show: 1250, hide: 400 } })
    })
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
