export class ExternalPromise {
  constructor(moj) {
    this.raw = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  then(...args) { return this.raw.then(...args) }
  catch(...args) { return this.raw.catch(...args) }
  finally(...args) { return this.raw.finally(...args) }
}
