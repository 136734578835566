import * as AppOS from "../appos"
import {mosaicVisualHash} from "./mosaic_visual_hash"

const Component = class extends AppOS.Component {
  static name = "IpVis"

  init() {
    this.inst = []
  }

  documentLoad() {
  }

  pageLoad() {
    if(document.querySelectorAll(`.ip-vis`).length == 0)
      return
    window.rc = []
    window.rr = []
    window.ri = []
    console.log(Math.floor(Math.random() * 10))
    this.loadAsyncAll()
    // this.loadAsyncEach()

    setTimeout(_ => {
      app.components.IpVis.avgt(window.rr)
    }, 4000)
  }

  loadAsyncAll() {
    setTimeout(_ => {
      const t = Date.now()
      document.querySelectorAll(`.ip-vis`).forEach(ctn => {
        this.inst.push(new IpVis(ctn, this.opts).clickToggle().toggle(true))
      })
      console.log("async-all", Date.now() - t)
    }, 0)
  }

  loadAsyncEach() {
    const t = Date.now()
    document.querySelectorAll(`.ip-vis`).forEach(ctn => {
      setTimeout(_ => {
        const t2 = Date.now()
        this.inst.push(new IpVis(ctn, this.opts).clickToggle().toggle(true))
        window.ri?.push(Date.now() - t2)
      }, 0)
    })
    console.log("async-each-enq", Date.now() - t)
  }

  median(numbers) {
    const sorted = numbers.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
  }

  avgt(arr) {
    const sorted = arr.slice().sort((a, b) => a - b);
    const sum = arr.reduce((a, b) => a + b, 0)
    const avg = (sum / arr.length) || 0

    console.log("min", Math.min(...arr))
    console.log("max", Math.max(...arr))
    console.log("sum", sum)
    console.log("med", this.median(arr))
    console.log("avg", avg)
    console.log("sor", sorted)
  }
}

class IpVis {
  constructor(ctn, opts = {}) {
    this.ctn = ctn
    this.opts = Object.assign({}, {
      size: 32,
      renderBase: 64,
      expand: true,
    }, opts)
    this.canvasDisplayed = false
  }

  clickToggle() {
    this.ctn.addEventListener("click", ev => {
      if(ev.shiftKey) {
        document.querySelectorAll(".ip-vis").forEach(el => {
          el.classList.toggle("active", el.dataset.sha == ev.currentTarget.dataset.sha)
        })
        ev.stopPropagation()
        ev.preventDefault()
        document.getSelection().removeAllRanges();
        return false
      } else {
        this.toggle()
      }
    })
    return this
  }

  toggle(toggleTo) {
    toggleTo ??= !!!this.canvasDisplayed
    toggleTo ? this.show() : this.hide()
    return this
  }

  show() {
    if(this.canvasDisplayed) return this
    this.canvasDisplayed = true
    if(!this.canvasElement) {
      this.initDom()
      this.draw()
    }

    this.textElement.style.display = "none"
    this.canvasElement.style.display = "block"
    return this
  }

  hide() {
    if(!this.canvasDisplayed) return
    this.canvasDisplayed = false
    this.initDom()
    this.textElement.style.display = "inline"
    this.canvasElement.style.display = "none"
  }

  initDom() {
    if(this.canvasElement) return
    const textEl = document.createElement("span")
    const ip = this.ctn.textContent
    this.ctn.textContent = ""
    this.ctn.dataset.ipExpanded = this.ctn.classList.contains("ip-v6") ? this.expandIPv6Address(ip) : ip
    if(this.opts.expand) {
      textEl.textContent = this.ctn.dataset.ipExpanded
      this.ctn.setAttribute("title", this.ctn.dataset.ipExpanded)
    } else {
      textEl.textContent = ip
      this.ctn.setAttribute("title", ip)
    }
    this.ctn.append(textEl)
    this.textElement = textEl

    const canvas = document.createElement("canvas")
    canvas.style.background = "magenta"
    canvas.style.display = "none"
    canvas.setAttribute("width", this.opts.renderBase)
    canvas.setAttribute("height", this.opts.renderBase)
    canvas.style.width = this.opts.size + "px"
    canvas.style.height = this.opts.size + "px"
    this.ctn.append(canvas)
    this.canvasElement = canvas
  }

  pc(c) {
    return (c / this.opts.renderBase) * this.opts.size
  }

  ppc(p) {
    return this.pc(this.pp(p))
  }

  pp(p) {
    return this.opts.renderBase * p / 100
  }

  drawSquare(ctx, rgb, x1, y1, x2, y2) {
    ctx.beginPath()
    ctx.rect(x1, y1, x2, y2)
    ctx.fillStyle = `rgb(${rgb.join(", ")})`
    ctx.lineWidth = 2
    ctx.strokeStyle = `rgb(${255 - rgb[0]}, ${255 - rgb[1]}, ${255 - rgb[2]})`
    ctx.fill()
    ctx.stroke()
  }

  drawTriangleUp(ctx, rgb, x1, y1, x2, y2) {
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x1 + x2, y1)
    ctx.lineTo((x1 + x2) / 2, y1 + y2)
    ctx.lineTo(x1, y1)
    ctx.lineWidth = 2
    ctx.fillStyle = `rgb(${rgb.join(", ")})`
    ctx.strokeStyle = `rgb(${255 - rgb[0]}, ${255 - rgb[1]}, ${255 - rgb[2]})`
    ctx.strokeStyle = `rgb(0, 255, 0)`
    ctx.fill()
    ctx.stroke()
  }

  drawQuarter(fill, px1, py1, px2, py2, ...byteGroups) {
    const ctx = this.canvasElement.getContext("2d")
    ctx.fillStyle = fill
    // ctx.fillRect(this.ppc(px1), this.ppc(py1), this.ppc(px2), this.ppc(py2))
    ctx.strokeStyle = "#ffcc00"
    ctx.stroke()
    // 0xffffff ^ color

    let sins = 5.0
    const inss = 10.0

    byteGroups.forEach(b => {
      const form = Number(`0x${b[0]}`)
      const rgb = [
        Number(`0x${b[1]}0`),
        Number(`0x${b[2]}0`),
        Number(`0x${b[3]}0`),
      ]
      switch (form) {
        case 0: // triangle_up
          this.drawTriangleUp(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 1: // triangle_down
          this.drawTriangleUp(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 2: // trapezoid
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 3: // parallelogram
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 4: // rhombus
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 5: // pentagon
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 6: // hexagon
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 7: // octagon
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 8: // bevel
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 9: // cross
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 10: // rabbet
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 11: // star
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 12: // circle
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 13: // circle
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 14: // ellipse
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
        case 15: // square
          this.drawSquare(ctx, rgb, this.ppc(px1 + sins), this.ppc(py1 + sins), this.ppc(px2 - sins * 2), this.ppc(py2 - sins * 2))
          break
      }
      sins += inss
    })
  }

  drawColorSquares(ctx) {
    let m = 0
    const ipb = this.ctn.dataset.ipExpanded.replaceAll(":", "")

    for (var i = 0; i < 6; i++) {
      for (var j = 0; j < 6; j++) {
        const k = i * 6 + j
        const r = parseInt(ipb[k] + "0", 16)
        const g = 255 - r
        const b = Math.min(255, (255 - r) + 128)
        switch (m) {
          case 0:
            ctx.fillStyle = `rgb(${r}, ${g}, ${b})`
            break
          case 1:
            ctx.fillStyle = `rgb(${g}, ${b}, ${r})`
            break
          case 2:
            ctx.fillStyle = `rgb(${b}, ${r}, ${g})`
            break
          case 3:
            m = 0
            break
        }
        // console.log(k, m, ctx.fillStyle)
        m += 1

        // console.log(this.pc(j * 250), this.pc(i * 250), this.pc(250), this.pc(250))
        const size = this.opts.renderBase / 6
        // console.log(size)
        ctx.fillRect(this.pc(j * size), this.pc(i * size), this.pc(size), this.pc(size))
      }
    }
  }

  drawLines(ctx) {
    const ipb = this.ctn.dataset.ipExpanded.replaceAll(":", "")
    // console.log(ipb)
    for (var i = 0; i < ipb.length; i+=2) {
      // console.log(ipb[i], ipb[i+1])
    }
  }

  async redraw() {
    const ctx = this.canvasElement.getContext("2d")
    ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
    this.draw()
  }

  async draw() {
    if(!this.canvasElement) return
    const ctx = this.canvasElement.getContext("2d")
    const ip = this.ctn.dataset.ipExpanded
    let ipb = ip.replaceAll(":", "")
    // if(ipb.length < 30) ipb = ipb + ipb + "daoiwjddddaoa"
    // console.log(ipb)
    const chunks = ip.split(":")


    // this.drawLines(ctx)
    // this.drawColorSquares(ctx)
    // this.drawQuarter("#FFFFFF", 0, 0, 50, 50, chunks.shift(), chunks.shift())
    // this.drawQuarter("#000000", 50, 0, 50, 50, chunks.shift(), chunks.shift())
    // this.drawQuarter("#000000", 0, 50, 50, 50, chunks.shift(), chunks.shift())
    // this.drawQuarter("#FFFFFF", 50, 50, 50, 50, chunks.shift(), chunks.shift())

    const encoder = new TextEncoder();
    const data = encoder.encode(ip);

    // setTimeout(_ => {
      crypto.subtle.digest('SHA-256', data).then(d => {
        const tr = Date.now()
        const uarr = new Uint8Array(d)
        const arr = Array.from(uarr)
        this.ctn.setAttribute("data-sha", arr.map(b => b.toString(16).padStart(2, '0')).join(''))
        mosaicVisualHash(arr, this.canvasElement, {
          jitter: 0,
          numberOfCurves: 6,
          numberOfColors: 3,
        });
        window.rr?.push(Date.now() - tr)
      })
    // }, 0)



    // 1111 1111
    // .map(ll => {

    //   da2d:a221
    //   cc82:61b1
    //   9cc6:4ed1
    //   cf65:0913

    //   console.log(ll)
    //   console.log(ll.substr(0,2), Number(`0x${ll.substr(0,2)}`))
    //   console.log(ll.substr(2,2), Number(`0x${ll.substr(2,2)}`))
    // })

    // top-right corner
    // bottom-left corner
    // bottom-right corner

    // top-left corner
    //c1 =
    // 2001:0470


    // ctx.moveTo(0, 0)
    // ctx.lineTo(50, 30)
    // ctx.stroke()
  }

  // [JS] Expand Abbreviated IPv6 Addresses
  // by Christopher Miller
  // http://forrst.com/posts/JS_Expand_Abbreviated_IPv6_Addresses-1OR
  // Modified to work with embedded IPv4 addresses
  // https://gist.github.com/Mottie/7018157
  expandIPv6Address(address) {
    let fullAddress = ""
    let expandedAddress = ""
    let validGroupCount = 8
    let validGroupSize = 4

    let ipv4 = ""
    const extractIpv4 = /([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})/
    const validateIpv4 = /((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})/

    // look for embedded ipv4
    if(validateIpv4.test(address))
    {
      groups = address.match(extractIpv4);
      for(var i=1; i<groups.length; i++)
      {
        ipv4 += ("00" + (parseInt(groups[i], 10).toString(16)) ).slice(-2) + ( i==2 ? ":" : "" );
      }
      address = address.replace(extractIpv4, ipv4);
    }

    if(address.indexOf("::") == -1) // All eight groups are present.
      fullAddress = address;
    else // Consecutive groups of zeroes have been collapsed with "::".
    {
      var sides = address.split("::");
      var groupsPresent = 0;
      for(var i=0; i<sides.length; i++)
      {
        groupsPresent += sides[i].split(":").length;
      }
      fullAddress += sides[0] + ":";
      for(var i=0; i<validGroupCount-groupsPresent; i++)
      {
        fullAddress += "0000:";
      }
      fullAddress += sides[1];
    }
    var groups = fullAddress.split(":");
    for(var i=0; i<validGroupCount; i++)
    {
      while(groups[i].length < validGroupSize)
      {
        groups[i] = "0" + groups[i];
      }
      expandedAddress += (i!=validGroupCount-1) ? groups[i] + ":" : groups[i];
    }
    return expandedAddress;
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
