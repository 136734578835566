import { WorldLayer } from "../world_layer"

export class GridBackground extends WorldLayer {
  init() {
    this.mode = "bg"
    this.enableInitially = true
    this.mayRenderAsync = true
    this.gwidth = this.world.width / 26
    this.gheight = this.world.height / 26
    this.renderedLinesX = []
    this.renderedLinesY = []

    this.initAssets()

    this.optreg.add("int",  "gridThickness", 3)
    this.optreg.add("str",  "gridColor", '#3333')
    this.optreg.add("bool", "renderZeroX", false)
    this.optreg.add("bool", "renderGrid", true)
    this.optreg.add("bool", "renderGridX", false)
    this.optreg.add("bool", "renderMarkers", true)
    this.optreg.add("bool", "renderDetails", true)

    this.optreg.onValueChange(_ => this.fgLayer?.update())
  }

  get fgLayer() { return this.getLayer("GridForeground") }

  initAssets() {
    this.markers = this.assets.json("gridmarkers/data.json", this.world.world.data("fetchGridmarkers"), {
      mapValues: ["details", "seanames"],
    })

    this.markers.addDataProcessor(d => {
      d.pendingTextures = []

      if(d.details) d.details = d.details.map(md => {
        md.world_size = [md.world_size.x * 100, md.world_size.y * 100]
        md.locations = md.locations.map(loc => [loc.x * 100, loc.y * 100])
        d.pendingTextures.push(md)
        return md
      })

      if(d.seanames) d.seanames = d.seanames.map(md => {
        //md.world_size = [md.world_size.x * 100, md.world_size.y * 100]
        md.location = [md.location.x * 100, md.location.y * 100]
        d.pendingTextures.push(md)
        return md
      })

      return d
    })

    this.markers.textures = this.assets.group("gridmarkers/textures", "/assets/games/sot/icons/map").on("assetLoad", (img, asset) => {
      asset.opts.tex.blob = img
      if(!asset.opts.tex.world_size) { asset.opts.tex.image_size = [img.naturalWidth, img.naturalHeight] }
    }).on("load", _ => this.texturesLoaded())

    this.markers.on("load", data => {
      data.pendingTextures.forEach(tex => {
        this.markers.textures.image(tex.texture, `${tex.texture}.png`, { tex })
      })
      this.markers.textures.load()
    })
  }

  onEnable() {
    this.markers.load()
  }

  render() {
    if(!this.enabled) return
    this.clear()
    if(this.o("renderMarkers") && this.o("renderZeroX")) this.drawCenterCrosshair()
    if(this.markers?.loaded && this.o("renderMarkers") && this.o("renderDetails") && this.markers?.details) this._renderDetails()
    if(this.o("renderGrid")) this._renderGrid()
  }

  _renderGrid() {
    this.trx(ctx => {
      ctx.lineWidth = this.o("gridThickness")
      ctx.strokeStyle = this.o("gridColor")

      if(this.o("renderGridX")) {
        this.drawLine([-this.world.xZero, -this.world.yZero], [this.world.width - this.world.xZero, this.world.height - this.world.yZero])
        this.drawLine([-this.world.xZero, this.world.height - this.world.yZero], [this.world.width - this.world.xZero, -this.world.yZero])
      }

      for (var i = 0; i < 27; i++) {
        this.renderedLinesX[i] = this.drawLine([-this.world.xZero + (i * this.gwidth), -this.world.yZero], [-this.world.xZero + (i * this.gwidth), this.world.height - this.world.yZero])
        this.renderedLinesY[i] = this.drawLine([-this.world.xZero, -this.world.yZero + (i * this.gheight)], [this.world.width - this.world.xZero, -this.world.yZero + (i * this.gheight)])
      }
    })
  }

  _renderDetails() {
    this.markers.details.forEach(md => {
      if(!md.blob) return
      md.locations.forEach(loc => {
        const tloc = this.translateOnPlane(loc, md.world_size)
        this.drawImage(md.blob, tloc, md.world_size[0], md.world_size[1])
        // this.drawCircle(loc, md.world_size[0] / 2)
      })
    })
  }
}
