import Stats from "stats.js"
import {Logo} from "./gamesplanet_canvas_logo/logo"
import {RollingHue} from "./gamesplanet_canvas_logo/filters/rolling_hue"
import {Orbit} from "./gamesplanet_canvas_logo/orbit"

class GamesplanetCanvasLogo {
  constructor(canvas, opts = {}) {
    this.canvas = canvas
    this.ctx = canvas.getContext("2d")
    this.tick = 0
    this.opts = Object.assign({}, {
      fill: "magenta",
      padding: 50,
      // updateFps: 30,
      // renderFps: 15,
    }, opts)

    this.stats = new Stats()
    const panels = [0, 1]; // 0: fps, 1: ms, 2: mb
    Array.from(this.stats.dom.children).forEach((child, index) => {
      child.style.display = panels.includes(index) ? 'inline-block' : 'none';
    })
    document.body.appendChild(this.stats.dom)

    this.renderStats = new Stats()
    Array.from(this.renderStats.dom.children).forEach((child, index) => {
      child.style.display = panels.includes(index) ? 'inline-block' : 'none';
    })
    this.renderStats.dom.style.left = "auto"
    this.renderStats.dom.style.right = "0px"
    document.body.appendChild(this.renderStats.dom)

    // this.canvas.setAttribute("width", 1977 + this.opts.padding * 2)
    // this.canvas.setAttribute("height", 309 + this.opts.padding * 2)
    this.canvas.setAttribute("width", window.innerWidth)
    this.canvas.setAttribute("height", window.innerHeight)

    // canvas.width = window.innerWidth;
    // canvas.height = window.innerHeight;

    this.logo = new Logo({x: 0, y: 0}, { scale: 0.5 })
    this.orbit = new Orbit({x: 0, y: 0}, {
      width: this.canvas.width/2,
      height: this.canvas.height/2,
      radius: 63,
    })
  }

  animate() {
    requestAnimationFrame(time => {
      this.update(time)
      this.render(time)
      if(!window.stopRender) this.animate()
    })
  }

  update(time) {
    const delta = time - this.lastUpdate ?? 0
    if (this.opts.updateFps && delta < (1000 / this.opts.updateFps) - 0.01) {
      return
    }

    this.stats.begin()
    this.tick += 1
    this.lastUpdate = time

    this.logo.pos.x = 570-58-65 + (window.xx ?? 0)
    this.logo.pos.y = 562-50-65 + (window.yy ?? 0)

    // if(this.canvas.width < this.logo.width - 1) {
    this.logo.opts.scale = (this.canvas.width - 1) / this.logo.width
    // }
    this.logo.update(this.tick, time)
    this.orbit.update(this.tick, time)

    this.stats.end()
  }

  render(time) {
    const delta = time - this.lastRender ?? 0
    if (this.opts.renderFps && delta < (1000 / this.opts.renderFps) - 0.01) {
      return
    }

    this.renderStats.begin()
    this.lastRender = time
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    this.orbit.render(this.ctx)
    // this.logo.render(this.ctx)

    this.renderStats.end()
  }
}

export { GamesplanetCanvasLogo }
