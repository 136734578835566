import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Sot__SailRedesign"

  init() {
  }

  documentLoad() {
    this.keyboardControls()
  }

  pageLoad() {
    $(`div[data-appos-controller="SotSailRedesign"]`).each((i, el) => {
      this.metrics = new SailRedesign(this, el)
    })
  }

  keyboardControls() {
    document.addEventListener("keydown", ev => {
      if(!$(`div[data-appos-controller="SotSailRedesign"]`).length) return

      if(ev.target.tagName == "INPUT" && ev.target.type == "text") return

      if(ev.keyCode == 32) { // space = toggle horizon
        ev.preventDefault()
        this.metrics?.c_comp?.imageComparisonSlider?.swapMode()
      } else if(!ev.ctrlKey && !ev.metaKey && (ev.keyCode == 37 || ev.keyCode == 38)) { // arrow left / up = slider left
        ev.preventDefault()
        this.metrics?.c_comp?.imageComparisonSlider?.sliderStepLeft(ev.shiftKey ? 10 : 1)
      } else if(!ev.ctrlKey && !ev.metaKey && (ev.keyCode == 39 || ev.keyCode == 40)) { // arrow up / right = slider left
        ev.preventDefault()
        this.metrics?.c_comp?.imageComparisonSlider?.sliderStepRight(ev.shiftKey ? 10 : 1)
      } else if((ev.keyCode == 49 && ev.shiftKey) || ev.keyCode == 97) { // shift+1 || numpad1
        ev.preventDefault()
        this.metrics?.e_left?.toggle?.()
      } else if(ev.keyCode == 33 || ev.keyCode == 34) {
        // page-up = right-oneUp / page-down = right-oneDown
        ev.preventDefault()
        this.metrics?.selectSibling(ev.keyCode == 36 || ev.keyCode == 33 ? "previous" : "next")
      }
    })
  }
}

class SailRedesign {
  constructor(component, container) {
    this.component = component
    this.container = container
    let lastCannon = null

    this.c_comp = container.querySelector(`.image-comparison-slider`)
    this[`i_left`] = this.c_comp.querySelector(`.left-image`)
    this[`i_right`] = this.c_comp.querySelector(`.right-image`)
    this[`c_left`] = container.querySelector(`[data-sail-selector="left"]`)
    this[`d_left`] = this[`c_left`].querySelector(`[role="cannon-metrics-display"]`)
    this[`e_left`] = this[`c_left`].bootstrapElect

    this.loadingImage = this.i_left.src

    // update when elect updates select
    this[`e_left`].i_value.addEventListener("change", ev => {
      const opt = ev.target.selectedOptions[0]
      if(opt.dataset.imageOld) {
        this[`i_left`].src = ""
        this[`i_left`].src = this.loadingImage
        this[`i_left`].src = opt.dataset.imageOld
        this[`i_right`].src = ""
        this[`i_right`].src = this.loadingImage
        this[`i_right`].src = opt.dataset.imageNew
        this.updatePageUrl()
        this.updateDisplayElement(this[`d_left`], opt)
      }
    })

    // toggle elect
    this[`d_left`].addEventListener("click", ev => {
      if(!ev.target.closest(`a`)) {
        ev.preventDefault()
        this[`e_left`].toggle()
      }
    })

    // select selected or random (and prevent chosing same for left & right)
    let selectedOption = this[`e_left`].i_value.selectedOptions[0]
    let randomlySelected = false
    // if(!selectedOption.value) {
    //   randomlySelected = true
    //   const opts = Array.from(this[`e_left`].i_value.options).filter(el => el.value)
    //   let pick = lastCannon
    //   while((pick == null || pick == lastCannon) && opts.length > 1) {
    //     pick = opts[Math.floor(Math.random() * opts.length)]
    //   }
    //   selectedOption = pick
    // }
    lastCannon ??= selectedOption

    // next / prev button
    container.querySelector(`[data-action="nextSail"]`).addEventListener("click", ev => {
      ev.preventDefault()
      ev.target.blur()
      this.selectSibling("next", null, ev.target)
    })

    container.querySelector(`[data-action="previousSail"]`).addEventListener("click", ev => {
      ev.preventDefault()
      ev.target.blur()
      this.selectSibling("previous", null, ev.target)
    })

    // initially set display
    if(selectedOption.dataset.imageOld) {
      this[`i_left`].src = selectedOption.dataset.imageOld
      this[`i_right`].src = selectedOption.dataset.imageNew
    }
    this.updateDisplayElement(this[`d_left`], selectedOption, randomlySelected)
  }

  selectSibling(direction = "next", select, flashTarget) {
    select ??= this[`e_left`]?.i_value
    if(!select) return false
    const sopt = select.selectedOptions[0]
    const sibling = sopt[`${direction}ElementSibling`]
    if(sibling?.value) {
      select.value = sibling.value
      select.dispatchEvent(new Event("change", {"view": window, "bubbles": true }))
    } else {
      const outer = this[`d_left`]
      outer.classList.remove("flash-danger")
      outer.offsetWidth
      outer.classList.add("flash-danger")
      if(flashTarget) {
        flashTarget.classList.remove("highlight-flash-danger")
        flashTarget.offsetWidth
        flashTarget.classList.add("highlight-flash-danger")
      }
    }
  }

  updateDisplayElement(del, opt, randomlySelected = false) {
    const img = del.querySelector(`[data-v="image"]`)
    const name = del.querySelector(`[data-v="name"]`)
    const item_db = del.querySelector(`[data-l="item-db"]`)
    const sot_wiki = del.querySelector(`[data-l="sot-wiki"]`)
    img.src = ""
    img.src = this.loadingImage
    img.src = `${opt.dataset.imageOld}`
    name.textContent = name.title = img.title = opt.textContent
    if(randomlySelected) {
      const span = document.createElement("span")
      span.textContent = " (randomly selected)"
      span.classList.add("text-muted", "text-smol")
      name.append(span)
    }
    item_db.href = `/games/sot/items/${opt.value}`
    sot_wiki.href = item_db.href + `/wiki`
  }

  updatePageUrl(left, right) {
    left ??= this.e_left.i_value.value
    let url = window.location.pathname
    if(left) url += `?left=${left}`
    window.history.replaceState({ left }, "", url)
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
