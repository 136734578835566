class DataFilter {
  constructor(elect) {
    this.elect = elect
    this.init?.()
  }

  filter(elements) { throw(`must implement #filter(elements, query) that returns filtered elements array`) }
}


class VoidFilter extends DataFilter {
  filter(elements) { return elements }
}


class StartWithFilter extends DataFilter {
  filter(elements, query) {
    const q = query.toLowerCase()
    return elements.filter(el => el.name?.toLowerCase?.().startsWith?.(q))
  }
}

class RegexpFilter extends DataFilter {
  stringToRegexp(q) {
    if(q == "") return null

    try {
      this.elect.i_query.classList.remove("border", "border-danger")
      bootstrap.Tooltip.getInstance(this.elect.i_query)?.dispose()
      return new RegExp(q, 'gi')
    } catch(e) {
      this.elect.i_query.classList.add("border", "border-danger")

      // if(this.opts.showErrors) {
        bootstrap.Tooltip.getInstance(this.elect.i_query)?.dispose()
        const tooltip = new bootstrap.Tooltip(this.elect.i_query, { trigger: "manual", title: e.message })
        tooltip.show()
      // }

      throw(e)
    }
  }

  filter(elements, query) {
    const r = this.stringToRegexp(query.toLowerCase())
    return elements.filter(el => el.name?.match?.(r))
  }
}

import {TrigramIndex} from "../../../lib/trigram_index"

class TrigramFilter extends DataFilter {
  init() {
    if(!this.elect.provider.subtractive) {
      throw "trigram filter can only be used on subtractive data providers"
    }

    this.indexer = new TrigramIndex()
    this.elect.provider.all(items => {
      this.all = items
      items.forEach(i => { this.indexer.index(i.name, i.value) })
    })
  }

  filter(elements, query) {
    const ids = this.indexer.find(query).map(r => r.id)
    return elements
      .filter(el => ids.includes(el.value))
      .sort((a, b) => {
        return ids.indexOf(b.value) - ids.indexOf(a.value)
      })
  }
}



export {
  DataFilter,
  VoidFilter,
  StartWithFilter,
  RegexpFilter,
  TrigramFilter,
}
