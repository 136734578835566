import { LogoGlyph } from "./logo"

class Orbit {
  constructor(pos, opts = {}) {
    this.pos = pos
    this.opts = Object.assign({}, {
      width: 200,
      height: 200,
      radius: 200/16,
      count: 1000,
    }, opts)
    console.log(this.pos, this.opts)

    this.emitter = new Emitter(this.opts.radius, this.opts.count)
    this.logo = new LogoGlyph
    this.logo.fill = "white"
    this.logo.stroke = null
    this.logo.scale = 0.5
  }

  update(tick, time) {
    const npos = { x: this.pos.x, y: this.pos.y }
    npos.x += this.opts.radius * 4
    npos.y += this.opts.radius * 4
    // this.logo.fill = `rgba(255, 255, 255, ${getRandomIntInclusive(3, 10) / 10})`
    this.emitter.update(tick, time, npos)
  }

  render(ctx) {
    this.emitter.render(ctx)
    this.logo.render(ctx, this.pos.x + this.opts.radius * 8 - (this.logo.width * this.logo.scale), this.pos.y + this.opts.radius * 8 - (this.logo.height * this.logo.scale))
  }
}

class Emitter {
  constructor(r = 30, c = 1000) {
    this.radius = r
    this.count = c
    this.particles = []
    this.pos = { x: 0, y: 0 }

    for (var i=0; i< this.count; i++ ) {
      this.particles.push(new Particle(this.radius))
    }
  }

  update(tick, time, pos) {
    this.pos = pos
    for(var i=0; i< this.count; i++) {
      this.particles[i].visible = tick > i / 8 //* 8
      this.particles[i].update(tick, time, pos)
    }
  }

  render(ctx) {
    // ctx.fillStyle = "rgba(0,0,0,.61)"
    // ctx.beginPath()
    // ctx.arc(this.pos.x + this.radius * 0, this.pos.y + this.radius * 0, this.radius, 0, Math.PI*2, false)
    // ctx.fill()
    // ctx.closePath()
    for(var i=0; i< this.count; i++) {
      this.particles[i].render(ctx)
    }
  }
}

class Particle {
  constructor(distance) {
    this.angle = Math.random() * 2 * Math.PI
    this.radius = Math.random()
    this.opacity =  (Math.random()*5 + 3) / 10
    this.distance = (1 / this.opacity) * distance
    this.speed = this.distance * 0.00003
    this.position = { x: 0, y: 0 }
    this.visible = true

    // this.position = {
    //   x: x + this.distance * Math.cos(this.angle),
    //   y: y + this.distance * Math.sin(this.angle)
    // }
  }

  update(tick, time, pos) {
    this.angle += this.speed

    let tdelta = tick % 3000
    let rdelta = tdelta
    if(tdelta > 2500) {
      // rdelta = 3000 - tdelta
      rdelta = tdelta * (0.01 * (tdelta - 2500))
    } else if (tdelta > 500) {
      rdelta = 500
    }

    this.position = {
      x: pos.x + (this.distance * (rdelta/500)) * Math.cos(this.angle),
      y: pos.y + (this.distance * (rdelta/500)) * Math.sin(this.angle)
    }
  }

  render(ctx) {
    ctx.save()
    ctx.fillStyle = `rgba(255, 255, 255, ${this.visible ? (this.opacity * getRandomIntInclusive(3, 10) / 10) : 0})`
    ctx.beginPath()
    ctx.arc(this.position.x, this.position.y, this.radius, 0, Math.PI*2, false)
    ctx.fill()
    ctx.closePath()
    ctx.restore()
  }
}

export { Orbit }

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}
