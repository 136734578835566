import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "ImagePerformance"

  boot() {
    this.opts = {}
  }

  init() {
    this.performance = new ImagePerformance(this.opts)
  }

  pageLoad() {
    this.performance.setup()
  }
}

class ImagePerformance {
  constructor(opts = {}) {
    this.opts = Object.assign({}, {
      observer: {
        rootMargin: '0px',
        threshold: 0,
      },
      debounceThreshold: 200,
      debounceMethod: "throttle_debounce",
      containerSelector: '.contains-pimage',
      pendingClass: 'update-pending',
      loadedClass: 'loaded',
    }, opts)
    this.throttledPerformIntersectionChange = AppOS.Util[this.opts.debounceMethod](_ => this.performIntersectionChange(), this.opts.debounceThreshold)
  }

  async setup(opts = {}) {
    this.imagePerformanceObserver?.disconnect()
    let options = Object.assign({}, this.opts.observer ?? {}, opts)
    this.imagePerformanceObserver = new IntersectionObserver((e, o) => this.handleImagePerfIntersectionChange(e, o), options)

    document.querySelectorAll(this.opts.containerSelector).forEach(el => {
      this.imagePerformanceObserver.observe(el)
    })
  }

  async handleImagePerfIntersectionChange(entries, observer) {
    entries.forEach(e => {
      if(e.isIntersecting) {
        delete e.target.isNotIntersectingTemp
        e.target.isIntersectingTemp = true
      } else {
        delete e.target.isIntersectingTemp
        e.target.isNotIntersectingTemp = true
      }
      e.target.classList.add(this.opts.pendingClass)
    })

    this.throttledPerformIntersectionChange()
  }

  async performIntersectionChange() {
    document.querySelectorAll(`${this.opts.containerSelector}.${this.opts.pendingClass}`).forEach(el => {
      el.classList.remove(this.opts.pendingClass)

      if(el.isIntersectingTemp) {
        delete el.isIntersectingTemp
        if(!el.lazysrcLoaded) {
          el.querySelectorAll("img[data-src]").forEach(img => {
            if(img.classList.contains(this.opts.loadedClass)) return
            img.onload = _ => { img.classList.add(this.opts.loadedClass) }
            img.src = img.dataset.src
          })
          el.lazysrcLoaded = true
        }
      }
      if(el.isNotIntersectingTemp) {
        delete el.isNotIntersectingTemp
        if(el.lazysrcLoaded) {
          el.querySelectorAll("img[data-src]").forEach(img => {
            if(el.dataset.keepImagesLoaded && img.classList.contains(this.opts.loadedClass)) return
            img.classList.remove(this.opts.loadedClass)
            img.onload = null
            img.src = ""
          })
          delete el.lazysrcLoaded
        }
      }
    })
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component, ImagePerformance }
