import * as AppOS from "../../appos"
import * as ElectProviders from "./elect/data_provider"
import * as ElectFilters from "./elect/data_filter"
import { KeyboardControls } from "./elect/keyboard_controls"

const Component = class extends AppOS.Component {
  static name = "Bootstrap.Elect"

  init() {
    this.opts.hookDocumentClicks ??= true
  }

  documentLoad() {
    if(this.opts.hookDocumentClicks) BootstrapElect.hookClicks()
  }

  pageLoad() {
    document.querySelectorAll(`[data-elect]`).forEach(el => {
      if(!el.bootstrapElect) {
        el.bootstrapElect = new BootstrapElect(el, this, this.opts)
      }
    })
  }
}


class BootstrapElectCore {
  static hookClicks() {
    document.addEventListener("click", async ev => {
      const activeElect = ev.target.closest(`[data-elect]`)
      document.querySelectorAll(`[data-elect].open`).forEach(el => {
        if(el != activeElect) el?.bootstrapElect?.hide?.()
      })
    }, { passive: true })
  }

  static DEFAULT_OPTIONS = {
    debug: false,

    // enable keyboard controls (arrows/enter/escape)
    keyboard: true,

    // debounce filtering (number or false)
    debounce: false,

    // limit results (number or false)
    limit: false,

    // min amount of query before searching (number or false)
    minLength: false,

    // exclude items that have no value (i.e. prompt), only applies to some data providers
    excludeEmptyValues: true,

    // when elect is opened start with
    //   "empty" (no results until filtering)
    //   "full" (all results until filtering, if supported by data provider)
    //   "limit" (limit results to limit until filtering)
    start: "empty",

    // data provider
    provider: "auto",

    // data filter
    filter: "StartWithFilter",
  }

  static DEFAULT_EVENTS = [
    "show", "shown",
    "hide", "hidden",
    "item:created",
    "item:shown",
    "item:selected",
    "item:deselected",
    "item:hidden",
    "item:destroyed",
    "item:confirmed",
  ]

  constructor(ctn, component, opts = {}) {
    this.ctn = ctn
    this.opts = Object.assign({}, this.constructor.DEFAULT_OPTIONS, opts)
    AppOS.NamespacedConsole.applyOn(this, { base: component })
    AppOS.SimpleEvents.applyOn(this, this.constructor.DEFAULT_EVENTS)
    this.applyDataOptions()

    this.b_toggle = this.ctn.querySelector(`[data-action="toggle"]`)
    this.i_query = this.ctn.querySelector(`[data-role="query"]`)
    this.i_value = this.ctn.querySelector(`[data-role="value"]`)
    this.c_wrapper = this.ctn.querySelector(`[data-role="floating-container"]`)
    this.c_results = this.ctn.querySelector(`[data-role="results"]`)

    this.s_loading = this.ctn.querySelector(`[data-elect-status="loading"]`)
    this.s_error = this.ctn.querySelector(`[data-elect-status="error"]`)
    this.s_empty = this.ctn.querySelector(`[data-elect-status="emptySet"]`)
    this.s_noquery = this.ctn.querySelector(`[data-elect-status="emptyQuery"]`)

    this.t_item = this.ctn.querySelector(`template[data-role="item-template"]`)

    // state
    this.lastQuery = ""

    this.debug(this.opts)
    this.keyboard = this.opts.keyboard && new KeyboardControls(this)
    this.hook()
  }

  applyDataOptions() {
    if(!this.ctn.dataset.elect) return
    const json = JSON.parse(this.ctn.dataset.elect)
    Object.entries(json).forEach(([key, value]) => {
      this.debug("setting", key, "to", value, "was", this.opts[key])
      this.opts[key] = value
    })
  }

  hook() {
    this.b_toggle.addEventListener("click", ev => {
      ev.preventDefault()
      ev.target.blur()
      this.toggle()
    })

    this.c_results.addEventListener("click", ev => {
      let t = ev.target
      if(t.dataset.role != "result") t = t.closest(`[data-role="result"]`)
      if(t) {
        ev.preventDefault()
        this.deselectAllResults().selectResult(t, true).confirmSelection()
      }
    })

    if(this.b_toggle.tagName == "SELECT") {
      this.b_toggle.addEventListener("mousedown", ev => ev.preventDefault() )
    }

    this.i_query.addEventListener("keyup", ev => this.applySearch())

    this.keyboard?.hook?.()
  }
}

class BootstrapElectResults extends BootstrapElectCore {
  get selectedResults() {
    return this.getResults(".elect-selected")
  }

  get resultElements() {
    return this.getResults(".elect-visible")
  }

  get keyboardSelected() {
    return this.c_results.querySelector(`.elect-kb-active`)
  }

  getResults(sel) {
    return this.c_results.querySelectorAll(`[data-role="result"]${sel ?? ""}`)
  }

  getResultElement(value, sel) {
    return this.c_results.querySelector(`[data-role="result"][data-elect-value="${value}"]${sel ?? ""}`)
  }

  nextVisibleResult(el) {
    let next = el.nextElementSibling
    while (next && next.classList.contains("elect-invisible")) {
      next = next.nextElementSibling
      if(!next) return next
    }
    return next
  }

  previousVisibleResult(el) {
    let prev = el.previousElementSibling
    while (prev && prev.classList.contains("elect-invisible")) {
      prev = prev.previousElementSibling
      if(!prev) return prev
    }
    return prev
  }

  getItemTemplate() {
    let item

    if(this.t_item) {
      const content = this.t_item.content.cloneNode(true)
      item = content.children[0]
    } else {
      item = document.createElement("a")
      item.classList.add("list-group-item", "list-group-item-action", "d-flex", "justify-content-between", "align-items-center", "text-nowrap")
      item.href = "#elect-confirm"
      item.dataset.role = "result"
      item.dataset.v = "name"
    }

    return item
  }

  addResult(data) {
    const item = this.getResultElement(data.value)
    if(item) {
      delete item.electSweep
      if(!item.classList.contains("elect-visible")) {
        item.classList.add("elect-visible")
        item.classList.remove("elect-invisible")
        this.fire("item:shown", item)
      }

      return
    }

    const tpl = this.getItemTemplate()
    tpl.classList.add("elect-visible")
    tpl.dataset.electValue = data.value
    tpl.electData = data

    Object.entries(data).forEach(([prop, value]) => {
      if(tpl.dataset.v == prop) {
        tpl.textContent = value
      } else {
        tpl.querySelectorAll(`[data-v="${prop}"]`).forEach(el => {
          el.textContent = value
        })
      }
    })

    this.fire("item:created", tpl)
    this.c_results.append(tpl)
    this.fire("item:shown", tpl)
  }

  removeResult(item, force = false) {
    if(this.provider.subtractive) {
      if(item.classList.contains("elect-visible")) {
        this.deselectResult(item)
        item.classList.remove("elect-visible")
        item.classList.add("elect-invisible")
      }
      this.fire("item:hidden", item)
    } else {
      this.fire("item:hidden", item)
      item.remove()
      this.fire("item:destroyed", item)
    }

    if(this.resultElements.length) {
      this.setStatus()
    } else {
      this.setStatus(this.query ? this.s_empty : this.s_noquery)
    }
  }

  clearResults(forceEmpty = false) {
    this.resultElements.forEach(el => this.removeResult(el))

    if(!forceEmpty && !this.query && this.opts.start == "full" && this.provider.subtractive) {
      this.provider.allEach(i => this.addResult(i)).then(_ => this.selectFirstResultOrSelected())
    }
  }

  selectResult(el, kb = false) {
    if(!el) return this
    if(kb) el.classList.add("elect-kb-active")
    if(el.classList.contains("elect-selected")) return this
    el.classList.add("active", "elect-selected")
    this.scrollResultIntoViewIfNeeded(el)
    this.fire("item:selected", el)
    return this
  }

  deselectResult(el) {
    if(!el) return this
    if(!el.classList.contains("elect-selected")) return this
    el.classList.remove("active", "elect-kb-active", "elect-selected")
    this.fire("item:deselected", el)
    return this
  }

  deselectAllResults() {
    this.selectedResults.forEach(el => this.deselectResult(el))
    return this
  }

  deselectCurrentResult() {
    const current = this.keyboardSelected
    return current ? this.deselectResult(current) : this
  }

  selectFirstResult(deselectCurrent = true, kb = true) {
    if(deselectCurrent) this.deselectCurrentResult()
    return this.selectResult(this.resultElements[0], kb)
  }

  selectFirstResultOrSelected(deselectCurrent = true, kb = true) {
    if(deselectCurrent) this.deselectCurrentResult()
    let element = this.i_value.value && this.getResultElement(this.i_value.value, ".elect-visible")
    element ||= this.resultElements[0]
    return this.selectResult(element, kb)
  }

  selectLastResult(deselectCurrent = true, kb = true) {
    if(deselectCurrent) this.deselectCurrentResult()
    const all = this.resultElements
    return this.selectResult(all[all.length - 1], kb)
  }

  selectPreviousResult(kb = true) {
    const current = this.keyboardSelected
    if(!current) return this

    const prev = this.previousVisibleResult(current)
    if(!prev) return this

    this.deselectResult(current)
    this.selectResult(prev, kb)
  }

  selectNextResult(kb = true) {
    const current = this.keyboardSelected
    if(!current) return this

    const next = this.nextVisibleResult(current)
    if(!next) return this

    this.deselectResult(current)
    this.selectResult(next, kb)
  }

  scrollResultIntoViewIfNeeded(el) {
    const p_rect = this.c_results.getBoundingClientRect()
    const el_rect = el.getBoundingClientRect()
    const offset = el_rect.top - p_rect.top

    if(offset < 0) {
      el.scrollIntoView({ behavior: "instant", block: "nearest" })
    } else if (offset + el_rect.height > this.c_results.offsetHeight) {
      el.scrollIntoView({ behavior: "instant", block: "nearest" })
    }
  }

  confirmSelection() {
    const res = Array.from(this.selectedResults)
    this.fire("item:confirmed", res)
    this.i_value.value = res.map(i => i.dataset.electValue)
    this.i_value.dispatchEvent(new Event("change", {"view": window, "bubbles": true }))
    this.hide()
  }
}

class BootstrapElectSearch extends BootstrapElectResults {
  get query() {
    return this.i_query.value
  }

  set query(q) {
    this.i_query.value = q
  }

  get provider() {
    if(!this._provider) {
      let pref = this.opts.provider ?? "NoDataProvider"
      if(pref == "auto") {
        if (this.ctn.dataset.options) {
          pref = "DataAttributeProvider"
        } else if (this.ctn.dataset.url) {
          pref = "FetchProvider"
        } else if (this.ctn.dataset.staticUrl) {
          pref = "FetchStaticProvider"
        } else if (this.i_value?.tagName == "SELECT") {
          pref = "SelectElementProvider"
        } else {
          pref = "NoDataProvider"
        }
      }
      this.debug("using provider", pref)
      this._provider = new ElectProviders[pref](this)
    }
    return this._provider
  }

  get filter() {
    if(!this._filter) {
      let fref = this.opts.filter
      this.debug("using filter", fref)
      this._filter = new ElectFilters[fref](this)
    }
    return this._filter
  }

  applySearch() {
    if(this.opts.debounce) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = window.setTimeout(_ => {
        if(this.open) this._applySearch()
      }, this.opts.debounce)
    } else {
      if(this.open) this._applySearch()
    }
  }

  _applySearch() {
    const query = this.query
    if(query == this.lastQuery) {
      // probably meta key or rapid undo
      return
    }
    this.lastQuery = query

    // no query
    if(!query) {
      this.clearResults()
      return
    }

    this.setStatus(this.s_loading)
    this.provider.search(query, this.filter, (results) => {
      if(results.length) {
        this.setStatus(null)
        let changes = 0

        // marker all items for sweep
        this.getResults().forEach(el => {
          el.electSweep = true
        })

        results.forEach(el => {
          this.addResult(el)
          changes += 1
        })

        // sweep markered
        this.getResults().forEach(el => {
          if(!el.electSweep) return
          delete el.electSweep
          this.removeResult(el)
          changes += 1
        })

        // sort elements by result set
        const ids = results.map(i => i.value)
        const ary = Array.from(this.resultElements)
        ary
          .sort((a, b) => { return ids.indexOf(b.dataset.electValue) - ids.indexOf(a.dataset.electValue) })
          .forEach(el => this.c_results.append(el))

        // hide excess
        if(this.opts.limit && ary.length > this.opts.limit) {
          ary.slice(this.opts.limit).forEach(el => this.removeResult(el))
        }

        // focus first
        if(this.keyboard && changes > 0) this.selectFirstResult()
      } else {
        this.clearResults()
      }
    })
  }
}

class BootstrapElect extends BootstrapElectSearch {
  get open() {
    return this.ctn.classList.contains("open")
  }

  setStatus(stat) {
    delete this.s_loading.dataset.currentStatus
    delete this.s_error.dataset.currentStatus
    delete this.s_empty.dataset.currentStatus
    delete this.s_noquery.dataset.currentStatus
    if(stat) stat.dataset.currentStatus = true
  }

  toggle() {
    return this.open ? this.hide() : this.show()
  }

  show() {
    if (this.open) return
    this.fire("show")
    this.ctn.classList.add("open")
    this.setStatus(this.s_noquery)
    const sticky = this.ctn.closest(".is-sticky")

    if(sticky) sticky.position = "static"
    const rect = this.b_toggle.getBoundingClientRect()
    const offset = rect.top + window.scrollY
    if(sticky) sticky.position = null

    this.i_query.focus()
    if(offset < window.pageYOffset) {
      window.scrollTo({ top: offset, behavior: "smooth" })
    }

    if(this.opts.start == "full") {
      if(this.provider.subtractive) {
        this.clearResults()
      } else {
        this.warn("provider must be subtractive to be able to start full")
      }
    }

    this.fire("shown")
  }

  hide() {
    if (!this.open) return
    this.fire("hide")
    // this.clearResults()
    this.setStatus()
    this.query = this.lastQuery = ""
    this.ctn.classList.remove("open")
    this.fire("hidden")
  }
}


AppOS.Application?.availableComponents?.push?.(Component)
export {
  Component,
  BootstrapElect,
  ElectProviders,
  ElectFilters,
}
